import { getClient, getResponseData } from '@/core/axios'

const client = getClient('/users')

export async function getOauthState() {
  const response = await client.get('/oauth_state/')
  return getResponseData(response)
}

export async function authenticateSSO({ provider, state, code }) {
  const res = await client.post('/oauth_login/', {
    provider,
    state,
    code
  })

  const data = getResponseData(res)

  return data
}

export async function refreshTokens({ refresh }) {
  const res = await client.post('/token/refresh/', {
    refresh
  })
  return getResponseData(res)
}
