<template>
  <div class="uprogress" :class="{ [size]: true, rounded }">
    <div
      class="uprogress-bar"
      :style="{ width: `${progress}%`, background: barColor }"
    ></div>
  </div>
</template>

<script setup>
import { mapColor } from '@/core/colors'
import { computed } from 'vue'

const props = defineProps({
  progress: {
    type: Number,
    required: true,
    validator: (value) => value >= 0 && value <= 100
  },
  size: {
    type: String,
    default: 's',
    validator: (value) => ['xs', 's', 'm', 'l', 'xl'].includes(value)
  },
  color: {
    type: String,
    default: 'primary'
  },
  rounded: {
    type: Boolean,
    default: true
  }
})

const barColor = computed(() => mapColor(props.color))
</script>

<style lang="scss" scoped>
.uprogress {
  padding: 1px;
  background: $grey-200;
  border-radius: 2px;
  border: 1px solid $border-color;
  width: 100%;

  &-bar {
    height: 100%;
    transition: width 0.3s ease;
    border-radius: 2px;
  }

  &.rounded {
    border-radius: 999rem;

    & > .uprogress-bar {
      border-radius: 999rem;
    }
  }

  &.xs {
    height: 0.5rem;
  }

  &.s {
    height: 1rem;
  }

  &.m {
    height: 1.5rem;
  }

  &.l {
    height: 2rem;
  }

  &.xl {
    height: 2.75rem;
  }
}
</style>
