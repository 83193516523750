import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { durationToString, parseJWT } from '@/core/util'
import { refreshTokens } from '@/services/auth'
import Console from '@/core/console'

export const useAuthStore = defineStore('auth', () => {
  const accessToken = ref(null)
  const refreshToken = ref(null)
  const refreshTimeout = ref(null)
  const refreshMargin = ref(60e3)

  const accessTokenContents = computed(() =>
    accessToken.value ? parseJWT(accessToken.value) : null
  )
  const accessTokenExpiry = computed(() =>
    accessTokenContents.value ? accessTokenContents.value.exp * 1e3 : null
  )
  const accessTokenExpired = computed(() =>
    accessTokenExpiry.value ? Date.now() > accessTokenExpiry.value : true
  )

  const refreshTokenContents = computed(() =>
    refreshToken.value ? parseJWT(refreshToken.value) : null
  )
  const refreshTokenExpiry = computed(() =>
    refreshTokenContents.value ? refreshTokenContents.value.exp * 1e3 : null
  )
  const refreshTokenExpired = computed(() =>
    refreshTokenExpiry.value ? Date.now() > refreshTokenExpiry.value : true
  )

  const authenticated = computed(
    () => !accessTokenExpired.value && !refreshTokenExpired.value
  )

  function setAccessToken(token) {
    accessToken.value = token
  }

  function setRefreshToken(token) {
    refreshToken.value = token
    if (token) {
      localStorage.setItem('refreshToken', token)
    } else {
      localStorage.removeItem('refreshToken')
    }
  }

  function stopRefreshTimer() {
    if (refreshTimeout.value) {
      clearTimeout(refreshTimeout.value)
      refreshTimeout.value = null
    }
  }

  function logout() {
    setAccessToken(null)
    setRefreshToken(null)
    stopRefreshTimer()
  }

  async function refresh() {
    if (!refreshToken.value) throw Error('Cannot refresh without refresh token')
    let res = {}
    try {
      res = await refreshTokens({ refresh: refreshToken.value })
      setAccessToken(res.access)
      setRefreshToken(res.refresh)
    } catch (e) {
      Console.error('Error refreshing tokens', e)
      setAccessToken(null)
      setRefreshToken(null)
      logout()
      throw e
    }
    return res
  }

  async function startRefreshTimer() {
    stopRefreshTimer()

    const getTimeout = () =>
      accessTokenExpiry.value - Date.now() - refreshMargin.value

    /** newTimeout schedules a new timeout and automatically schedules the following ones */
    const newTimeout = (
      timeout,
      first = false,
      resolve = () => {},
      reject = () => {}
    ) => {
      Console.info('Refreshing in', durationToString(timeout))
      if (refreshTimeout.value) stopRefreshTimer()

      refreshTimeout.value = setTimeout(async function () {
        try {
          Console.debug('Refreshing')
          await refresh()
          if (first) resolve()
          newTimeout(Math.max(getTimeout(), 10e3)) // minimum 10 seconds between refresh calls
        } catch (err) {
          Console.debug('Error during refresh cycle', err)
          reject(err)
        }
      }, timeout)
    }

    // get the initial time until refresh, minimum 0 ms
    const timeout = Math.max(getTimeout(), 0)

    return new Promise((res, rej) => {
      newTimeout(timeout, true, res, rej)
    })
  }

  return {
    accessToken,
    refreshToken,
    refreshTimeout,
    refreshMargin,
    accessTokenContents,
    accessTokenExpiry,
    accessTokenExpired,
    refreshTokenContents,
    refreshTokenExpiry,
    refreshTokenExpired,
    authenticated,
    setAccessToken,
    setRefreshToken,
    refresh,
    stopRefreshTimer,
    startRefreshTimer,
    logout
  }
})
