import { inject, isRef } from 'vue'

export function useTryCatch(
  forcedConsole = undefined,
  forcedToast = undefined
) {
  const console = forcedConsole || inject('$console')
  const toast = forcedToast || inject('$toast')

  async function trycatch(fn, context, options = {}) {
    const validRef = (r) => !!r && isRef(r)
    const validFunction = (f) => !!f && typeof f === 'function'
    const validToast = (t) =>
      !!t && (typeof t === 'string' || typeof t === 'boolean')

    const { loading, error, onResult, onCatch, onFinally, success } = options

    if (validRef(loading)) loading.value = true
    let res = undefined
    try {
      if (validRef(error)) error.value = undefined
      res = await fn
      if (validFunction(onResult)) onResult(res)
      if (validToast(success))
        toast.success(
          typeof success === 'string'
            ? success
            : `Successfully finished ${context}`
        )
    } catch (e) {
      console.debug(`Error ${context}`, e)
      toast.error(e, context)
      if (validRef(error)) error.value = e
      if (validFunction(onCatch)) onCatch(e)
    } finally {
      if (validRef(loading)) loading.value = false
      if (validFunction(onFinally)) onFinally(res)
    }
    return res
  }

  return { trycatch }
}
