<template>
  <Vue3Lottie
    :animationLink="imgUrl"
    :background-color="background"
    :loop="loop"
    :autoplay="autoplay"
  />
</template>

<script>
import { mapColor } from '@/core/colors'
import { animationURL } from '@/core/util'

export default {
  name: 'Animation',
  props: {
    name: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      default: 'dark'
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imgUrl() {
      return animationURL(this.name)
    },
    background() {
      return mapColor(this.backgroundColor)
    }
  }
}
</script>

<style lang="scss" scoped></style>
