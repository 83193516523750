<template>
  <div>
    <UploadDownload v-if="uploadProvider" @connected="showModal">
      <template #default="{ connect }">
        <slot name="trigger" :select="() => startFileSelect(connect)">
          <Button
            :text="triggerText"
            :disabled="disabled"
            type="white"
            @click="() => startFileSelect(connect)"
          >
            <template #left>
              <img
                :src="providerObject.image"
                alt=""
                class="ifile-search-trigger-icon"
              />
            </template>
          </Button>
        </slot>
      </template>
    </UploadDownload>
    <Modal
      :visible="visible"
      :title="`Select files on ${providerObject ? providerObject.title : ''}`"
      :disabled="!selected.length"
      :padded="false"
      width="65rem"
      submit-text="Select"
      content-id="ifilesearchwrapper"
      @close="close"
      @submit="submit"
    >
      <TextInput
        v-model="query"
        :placeholder="`Search in ${providerObject ? providerObject.title : ''}`"
        left-icon="search"
        :disabled="consumptionLoading"
        class="ifile-search-content-search"
        @submit="resetPaginator"
      />
      <div class="ifile-search-content-list">
        <div
          v-for="file in paginatorData"
          :key="file.uuid"
          class="ifile-search-content-list-item"
          :class="{ selected: selectedIds.includes(file.uuid) }"
          @click="(e) => select(file, e)"
        >
          <DocumentTag
            :mimetype="file.mimetype"
            class="ifile-search-content-list-item-tag"
          />
          <p class="ifile-search-content-list-item-name">{{ file.name }}</p>
          <a :href="file.web_path" target="_blank" @click.stop>
            <Icon
              name="external-bold"
              class="ifile-search-content-list-item-link"
              fill="primary"
            />
          </a>
          <p class="ifile-search-content-list-item-date">
            {{ lastEdit(file) }}
          </p>
        </div>
        <Loader v-if="consumptionLoading" :full-width="true" height="5rem" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { relativeTime } from '@/core/date'
import { usePaginator } from '@/core/paginator'
import { getUserIntegrationFilesPaginator } from '@/services/integrations'
import { userFileStorageIntegrations } from '@/core/integrations'
import { useIntegrationStore } from '@/stores/integrations'
import { useWorkspaceStore } from '@/stores/workspace'
import { ref, computed, inject, watch } from 'vue'

const defaultState = {
  visible: false,
  selected: [],
  query: '',
  doubleClick: ''
}

export default {
  name: 'IntegrationFileSearchModal',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const visible = ref(false)
    const selected = ref([])
    const query = ref('')
    const doubleClick = ref('')

    const workspaceStore = useWorkspaceStore()
    const integrationStore = useIntegrationStore()

    const selectedIds = computed(() => selected.value.map((s) => s.uuid))
    const uploadProvider = computed(() => {
      const providers = {
        google_drive: 'google_drive',
        sharepoint: 'onedrive'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (workspaceStore.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    })
    const providerObject = computed(
      () => userFileStorageIntegrations[uploadProvider.value]
    )
    const triggerText = computed(
      () => `Select on ${providerObject.value?.title || ''}`
    )
    const userIntegration = computed(() =>
      integrationStore.userIntegrations.find(
        (ui) => ui.type === uploadProvider.value
      )
    )

    const getScrollElement = () => document.querySelector('#ifilesearchwrapper')

    const {
      consumptionLoading,
      paginatorData,
      resetPaginator,
      clearPaginator,
      setupPaginator,
      paginatorError
    } = usePaginator(
      async () =>
        getUserIntegrationFilesPaginator({
          integration_id: userIntegration.value.id,
          search: query.value,
          page_size: 50
        }),
      { delaySetup: true, getScrollElement }
    )

    const console = inject('$console')
    const toast = inject('$toast')

    watch(paginatorError, (val) => {
      if (val) {
        console.debug('Error loading integration files', val)
        toast.error(val, `loading files from ${providerObject.value.title}`)
      }
    })

    return {
      visible,
      selected,
      query,
      doubleClick,
      selectedIds,
      uploadProvider,
      providerObject,
      triggerText,
      userIntegration,
      consumptionLoading,
      paginatorData,
      resetPaginator,
      clearPaginator,
      setupPaginator
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$nextTick(() => {
        Object.assign(this, defaultState)
        this.clearPaginator()
      })
    },
    lastEdit(file) {
      return file.external_modified_date
        ? `Updated ${relativeTime(file.external_modified_date)}`
        : file.external_created_date
          ? `Created ${relativeTime(file.external_created_date)}`
          : ''
    },
    startFileSelect(connect) {
      if (this.userIntegration) this.showModal()
      else connect(true)
    },
    showModal() {
      this.visible = true
      this.setupPaginator()
    },
    submit() {
      this.$emit('submit', this.selected)
      this.close()
    },
    getPaginator() {
      return this.getUserIntegrationFilesPaginator({
        integration_id: this.userIntegration.id,
        search: this.query,
        page_size: 50
      })
    },
    select(file, event) {
      if (event.defaultPrevented) return
      if (this.doubleClick === file.uuid) {
        this.selected = [file]
        this.submit()
      } else {
        this.selected = this.selectedIds.includes(file.uuid)
          ? this.selected.filter((s) => s.uuid !== file.uuid)
          : [...this.selected, file]
        this.doubleClick = file.uuid
        setTimeout(() => (this.doubleClick = ''), 300)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ifile-search {
  &-trigger {
    &-icon {
      height: 1.2rem;
    }
  }

  background: $white;
  width: min(60rem, calc(100vw - 2rem));
  border-radius: 8px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid $border-color;

    &-text {
      font-weight: 600;
      font-size: 1.25rem;
      margin-right: auto;
    }
  }
  &-content {
    display: flex;
    flex-flow: column nowrap;

    &-search {
      width: 50%;
      margin: 1.5rem 1.5rem 1rem;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      padding: 0 1.5rem;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1rem;
        cursor: pointer;
        border-bottom: 1px solid $border-color;

        &:last-child {
          border-bottom: none;
          margin-bottom: 2rem;
        }

        &:hover {
          background: rgba($black, 0.04);
        }

        &.selected {
          background: rgba($primary, 0.08);
        }

        &-tag {
          height: 1.2rem;
          width: 1.2rem;
        }

        &-name {
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
        }

        &-link {
          cursor: pointer;
          height: 0.8rem;

          &:hover {
            transform: scale(1.1);
          }
        }

        &-date {
          color: $grey;
          margin-left: auto;
        }
      }
    }
  }
  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 1.5rem;
    border-top: 1px solid $border-color;
  }
}
</style>
