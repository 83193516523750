<template>
  <div class="accordion">
    <div
      v-for="item in items"
      :key="`accordion-${item.id}`"
      class="accordion-item"
      :class="{ bordered }"
    >
      <div
        class="accordion-item-header"
        :class="{
          opened: openedItem === item.id || openedItems.includes(item.id)
        }"
        :style="headerStyle"
        @click="() => toggleOpen(item)"
      >
        <Icon name="chevron-down-medium" class="accordion-item-header-icon" />
        <slot name="header" :item="item">
          {{ item.name }}
        </slot>
        <div class="accordion-item-header-buttons">
          <slot name="buttons" :item="item"></slot>
        </div>
      </div>
      <div
        class="accordion-item-content"
        :class="{
          opened: openedItem === item.id || openedItems.includes(item.id)
        }"
      >
        <slot name="content" :item="item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    bordered: {
      type: Boolean,
      default: false
    },
    headerStyle: {
      type: Object,
      default: () => ({})
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    opened: {
      type: Boolean,
      default: false
    }
  },
  emits: ['open'],
  data: () => ({
    openedItem: '',
    openedItems: []
  }),
  mounted() {
    if (this.opened) {
      if (this.multiSelect) this.openedItems = this.items.map((i) => i.id)
      else this.openedItem = this.items[0].id
    }
  },
  methods: {
    toggleOpen(item) {
      if (this.multiSelect)
        this.openedItems = this.openedItems.includes(item.id)
          ? this.openedItems.filter((i) => i !== item.id)
          : [...this.openedItems, item.id]
      else this.openedItem = this.openedItem === item.id ? '' : item.id
      this.$emit('open', this.multiSelect ? this.openedItems : this.openedItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-item {
    border-radius: 4px;

    &.bordered {
      border: 1px solid rgba(#000, 0.12);
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      cursor: pointer;

      &.opened {
        & > .accordion-item-header-icon {
          transform: rotate(180deg);
        }
      }

      &-icon {
        height: 1.2rem;
        transition: transform 0.3s;
      }

      &-buttons {
        margin-left: auto;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
        align-items: center;
      }
    }

    &-content {
      height: 0;
      overflow: hidden;

      &.opened {
        height: unset;
      }
    }
  }
}
</style>
