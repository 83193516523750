<template>
  <div
    class="uskeleton"
    :class="{ rounded }"
    :style="`--uskeleton-bg: ${backgroundColor}; --uskeleton-height: ${height}; --uskeleton-width: ${width}`"
  ></div>
</template>

<script setup>
import { mapColor } from '@/core/colors'
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    default: 'grey-400'
  },
  rounded: {
    type: Boolean,
    default: false
  },
  height: {
    type: String,
    default: '1rem'
  },
  width: {
    type: String,
    default: '100%'
  }
})

const backgroundColor = computed(() => mapColor(props.color))
</script>

<style lang="scss" scoped>
.uskeleton {
  background: var(--uskeleton-bg);
  height: var(--uskeleton-height);
  width: var(--uskeleton-width);

  display: block;
  mask: linear-gradient(-60deg, $black 30%, rgba($black, 0.33), $black 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg, $black 30%, rgba($black, 0.33), $black 70%)
    right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2s infinite;
  border-radius: 2px;

  &.rounded {
    border-radius: 999rem;
  }
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
</style>
