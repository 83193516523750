<template>
  <div class="draggable-grid" :style="gridStyle">
    <div
      v-for="(item, idx) in gridItems"
      :key="itemId(item)"
      class="draggable-grid-item"
      :class="{ dragged: dragging && idx === 0 }"
    >
      <div v-if="item.dragTarget" class="draggable-grid-item-target"></div>
      <div
        v-else
        :id="`grid-item-${idx}`"
        class="draggable-grid-item-wrapper"
        :class="{ dragged: dragging && idx === 0 }"
        :style="{
          ...(dragging && idx === 0
            ? {
                width: itemDimensions.width + 'px',
                height: itemDimensions.height + 'px',
                ...draggedItemOffset
              }
            : {}),
          ...(minItemHeight ? { minHeight: minItemHeight } : {})
        }"
      >
        <slot :item="item" :index="idx"></slot>
        <div
          v-if="dragging && !item.dragTarget && idx !== 0"
          :id="`grid-dropzone-${idx}`"
          class="draggable-grid-item-dropzone"
        ></div>
        <div
          v-if="
            dragging && !item.dragTarget && idx === displacedItems.length - 1
          "
          :id="`grid-dropzone-${idx + 1}`"
          class="draggable-grid-item-dropzone last"
        ></div>
        <div
          v-if="draggable && dragging && idx === 0"
          class="draggable-grid-item-handle dragged"
          @mouseup="endDrag"
          @mousemove="handleMouseMove"
          @dragstart.prevent
        >
          <Icon name="drag" class="draggable-grid-item-handle-icon" />
        </div>
        <div
          v-else-if="draggable"
          class="draggable-grid-item-handle"
          @mousedown="(e) => startDrag(e, idx)"
          @click="() => endDrag(false)"
          @dragstart.prevent
        >
          <Icon name="drag" class="draggable-grid-item-handle-icon" />
        </div>
      </div>
    </div>
    <slot name="last"></slot>
  </div>
</template>

<script>
import { contains2D } from '@/core/geometry'

export default {
  name: 'DraggableGrid',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Number,
      default: 3
    },
    draggable: {
      type: Boolean,
      default: true
    },
    minItemHeight: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dragging: false,
    dragIdx: -1,
    dragOverIdx: -1,
    itemDimensions: {},
    draggedItemOffset: {},
    scrollInterval: undefined
  }),
  emits: ['input', 'click'],
  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.columns}, 1fr)`
      }
    },
    displacedItems() {
      return this.dragging
        ? [
            this.items[this.dragIdx],
            ...this.items.filter((_, idx) => idx !== this.dragIdx)
          ]
        : this.items
    },
    gridItems() {
      return this.displacedItems.reduce((acc, curr, idx) => {
        if (idx === this.dragOverIdx) {
          acc.push({ dragTarget: true })
          acc.push(curr)
        } else {
          acc.push(curr)
          if (
            idx === this.displacedItems.length - 1 &&
            this.dragOverIdx === this.displacedItems.length
          ) {
            acc.push({ dragTarget: true })
          }
        }
        return acc
      }, [])
    }
  },
  mounted() {
    this.checkItemDimensions()
    window.addEventListener('resize', this.checkItemDimensions)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkItemDimensions)
  },
  methods: {
    itemId(item) {
      return (
        item.uuid ||
        item.title ||
        item.name ||
        Object.keys(item)
          .filter((key) => typeof item[key] === 'string')
          .slice(0, 3)
          .map((key) => item[key])
          .join('-')
      )
    },

    checkItemDimensions() {
      const firstItem = document
        .getElementById('grid-item-0')
        ?.getBoundingClientRect()
      if (!firstItem) return
      this.itemDimensions = {
        width: firstItem.width,
        height: firstItem.height
      }
    },

    startDrag(e, index) {
      if (!this.draggable) return
      this.dragging = true
      this.dragIdx = index
      this.dragOverIdx = index + 1

      const draggedItem = document
        .getElementById(`grid-item-${index}`)
        .getBoundingClientRect()

      this.dragOffset = {
        x: e.pageX - draggedItem.x,
        y: e.pageY - draggedItem.y
      }

      document.addEventListener('mousemove', this.handleDraggedItemMove)
      return
    },

    endDrag(emit = true) {
      this.dragging = false
      const dragOverIdx = this.dragOverIdx === -1 ? -1 : this.dragOverIdx - 1
      if (
        emit &&
        this.dragIdx !== -1 &&
        dragOverIdx !== -1 &&
        dragOverIdx !== this.dragIdx
      ) {
        this.$emit('input', this.items[this.dragIdx], dragOverIdx)
      } else {
        this.$emit('click', this.dragIdx)
      }
      this.dragIdx = -1
      this.dragOverIdx = -1
      document.removeEventListener('mousemove', this.handleDraggedItemMove)
      this.clearScrollInterval()
      this.dragOffset = undefined
    },

    handleDraggedItemMove(e) {
      this.draggedItemOffset = {
        left: `${e.pageX - this.dragOffset.x}px`,
        top: `${e.pageY - this.dragOffset.y}px`
      }
    },

    handleMouseMove(event) {
      const coords = {
        x: event.pageX,
        y: event.pageY
      }

      for (let index = 0; index < this.gridItems.length + 1; index++) {
        const dropzone = document
          .getElementById(`grid-dropzone-${index}`)
          ?.getBoundingClientRect()
        if (dropzone && contains2D(coords, dropzone)) {
          this.dragOverIdx = index
          break
        }
      }
      const scrollTrigger = window.innerHeight / 5
      if (
        event.pageY < scrollTrigger ||
        event.pageY > window.innerHeight - scrollTrigger
      ) {
        if (!this.scrollInterval)
          this.scrollInterval = setInterval(() => {
            this.scroll(event.pageY < scrollTrigger ? -1 : 1)
          }, 100)
      } else {
        this.clearScrollInterval()
      }
    },
    scroll(direction) {
      document.getElementById('scroll-container').scrollBy({
        top: direction * 50,
        behavior: 'smooth'
      })
    },
    clearScrollInterval() {
      clearInterval(this.scrollInterval)
      this.scrollInterval = undefined
    }
  }
}
</script>

<style scoped lang="scss">
.draggable-grid {
  display: grid;
  gap: 1rem;

  &-item {
    position: relative;

    &.dragged {
      position: absolute;
    }

    &:hover {
      & .draggable-grid-item-handle {
        opacity: 1;
      }
    }

    &-target {
      height: 100%;
      min-height: 10rem;
      width: 100%;
      border-radius: 6px;
      border: 1px dashed $border-color;
      background: $white;
    }

    &-wrapper {
      position: relative;
      height: 100%;
      width: 100%;

      &.dragged {
        position: fixed;
        z-index: 999;
      }
    }

    &-dropzone {
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc(50% + 5rem);
      &.last {
        right: -5rem;
      }
      &:not(.last) {
        left: -5rem;
      }
    }

    &-handle {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      opacity: 0;
      transition: opacity 0.2s ease;
      width: 2rem;
      height: 2rem;
      border-radius: 999rem;
      background: $white;
      border: 1px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: grab;

      &.dragged {
        cursor: grabbing;
      }

      &-icon {
        height: 1.2rem;
      }
    }
  }
}
</style>
