<template>
  <div
    class="avatar"
    :class="{ [size]: true, resizable }"
    :style="`--fallback-bg: ${fallbackBg};`"
  >
    <img
      v-if="avatar(user) && !error"
      :src="avatar(user)"
      class="avatar-img"
      @error="() => (error = true)"
    />
    <div v-else-if="fallbackIcon" class="avatar-img">
      <Icon :name="fallbackIcon" class="avatar-img-icon" />
    </div>
    <div v-else class="avatar-img" :style="{ background: user_color(user) }">
      {{ initials(user) }}
    </div>
  </div>
</template>

<script>
import { colorCodes } from '@/core/colors'
import { avatar, initials, user_color } from '@/core/user'

export default {
  name: 'Avatar',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    fallbackIcon: {
      type: String,
      default: ''
    },
    fallbackBackground: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xxs', 'xs', 's', 'm', 'l', 'xl'].includes(s)
    },
    resizable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    error: false
  }),
  computed: {
    fallbackBg() {
      return (
        this.fallbackBackground ||
        (this.fallbackIcon ? colorCodes['grey-200'] : user_color(this.user))
      )
    }
  },
  methods: {
    avatar,
    user_color,
    initials
  }
}
</script>

<style scoped lang="scss">
.avatar {
  &:not(.resizable) {
    &.xxs {
      width: 1.2rem;
      height: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      font-size: 0.75rem;
    }

    &.xs {
      width: 1.85rem;
      height: 1.85rem;
      min-width: 1.85rem;
      min-height: 1.85rem;
      font-size: 0.75rem;
    }

    &.s {
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
      font-size: 0.875rem;
    }

    &.m {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
      font-size: 1.125rem;
    }

    &.l {
      width: 3.45rem;
      height: 3.45rem;
      min-width: 3.45rem;
      min-height: 3.45rem;
      font-size: 1.375rem;
    }

    &.xl {
      width: 5rem;
      height: 5rem;
      min-width: 5rem;
      min-height: 5rem;
      font-size: 1.75rem;
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    border-radius: 999rem;
    object-fit: cover;
    color: $white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;

    &:has(svg) {
      background: var(--fallback-bg);
    }

    &-icon {
      height: 50%;
      width: 50%;
      opacity: 0.5;
    }
  }
}
</style>
