<template>
  <label class="ucheckbox-wrapper" :class="{ [size]: true, disabled }">
    <input
      ref="checkboxinput"
      type="checkbox"
      class="ucheckbox"
      :checked="value || indeterminate"
      :disabled="disabled"
      @input="$emit('input', $event.target.checked)"
    />
    <span class="ucheckbox-overlay" :class="{ 'is-radio': type === 'radio' }">
      <span class="ucheckbox-overlay-check" :class="{ indeterminate }">
        <span
          class="ucheckbox-overlay-check-mark small"
          :class="{ indeterminate }"
        ></span>
        <span
          v-if="!indeterminate"
          class="ucheckbox-overlay-check-mark large"
        ></span>
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xs', 's', 'm', 'l'].includes(s)
    },
    type: {
      type: String,
      default: 'checkbox',
      validator: (t) => ['checkbox', 'radio'].includes(t)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ucheckbox {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ .ucheckbox-overlay {
    border: 2px solid $primary;

    &:not(.is-radio) {
      background: $primary;
    }

    & .ucheckbox-overlay-check {
      opacity: 1;
    }
  }

  &-wrapper {
    display: block;
    position: relative;
    cursor: pointer;

    &.xs {
      height: 1rem;
      width: 1rem;
      min-width: 1rem;
    }

    &.s {
      height: 1.25rem;
      width: 1.25rem;
      min-width: 1.25rem;
    }

    &.m {
      height: 1.5rem;
      width: 1.5rem;
      min-width: 1.5rem;
    }

    &.l {
      height: 1.75rem;
      width: 1.75rem;
      min-width: 1.75rem;
    }

    &.disabled {
      cursor: not-allowed;

      & .ucheckbox-overlay {
        border: 2px solid $grey-400;
        background: $grey-200;
      }
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $white;
    border: 2px solid $grey-400;
    border-radius: 4px;
    transition:
      background 0.2s ease,
      border 0.2s ease;

    &:hover {
      background: rgba($black, 0.04);
    }

    &.is-radio {
      border-radius: 999rem;
      display: flex;
      align-items: center;
      justify-content: center;

      & .ucheckbox-overlay-check {
        border-radius: 999rem;
        background: $primary;
        height: calc(100% - 6px);
        min-height: calc(100% - 6px);
        width: calc(100% - 6px);
        min-width: calc(100% - 6px);
        transform: none;

        &-mark {
          display: none;
        }
      }
    }

    &-check {
      display: block;
      width: 70%;
      height: 70%;
      transform: translate(15%, -5%) rotate(45deg);
      position: relative;
      opacity: 0;
      transition: opacity 0.2s ease;

      &.indeterminate {
        width: 100%;
        height: 100%;
        transform: none;
        position: relative;
      }

      &-mark {
        background: $white;
        border-radius: 999rem;
        position: absolute;

        &.small {
          width: 70%;
          height: 2px;
          bottom: 0;
          right: 0;

          &.indeterminate {
            width: 70%;
            height: 2px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &.large {
          height: 100%;
          width: 2px;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
