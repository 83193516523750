import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useTeamStore = defineStore('teams', () => {
  const teams = ref([])

  function setTeams(t) {
    teams.value = t
  }

  function addTeam(team) {
    teams.value.push(team)
  }

  function editTeam(team) {
    teams.value = teams.value.map((t) => (t.uuid === team.uuid ? team : t))
  }

  function removeTeam(team) {
    teams.value = teams.value.filter((t) => t.uuid !== team.uuid)
  }

  return {
    teams,
    setTeams,
    addTeam,
    editTeam,
    removeTeam
  }
})
