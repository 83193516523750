<template>
  <div class="user-menu-wrapper" ref="usermenu">
    <div class="user-menu-trigger" @click="() => (showMenu = !showMenu)">
      <Avatar :user="currentUser" fallback-icon="user" />
    </div>
    <div class="user-menu" :class="{ visible: showMenu }">
      <div class="user-menu-items">
        <div class="user-menu-items-item" @click="openSettings">
          <Icon name="settings" class="user-menu-items-item-icon" />
          <span>My settings</span>
        </div>
        <div class="user-menu-items-item" @click="logout">
          <Icon name="logout" class="user-menu-items-item-icon" />
          <span>Log out</span>
        </div>
      </div>
      <div class="user-menu-info">
        <p class="user-menu-info-text">
          Logged in as
          <span class="user-menu-info-name">{{ full_name(currentUser) }}</span>
        </p>
        <p class="user-menu-info-text">
          {{ email(currentUser) }}
        </p>
      </div>
    </div>
  </div>
  <UserSettingsModal v-if="showSettings" :visible="true" @close="closeModal" />
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useClickaway } from '@/core/clickaway'
import { email, full_name } from '@/core/user'

import UserSettingsModal from '@/components/resources/UserSettingsModal.vue'

const store = useUserStore()
const { currentUser } = storeToRefs(store)

const showMenu = ref(false)

const usermenu = ref(null)
const getElement = () => usermenu.value

function close() {
  showMenu.value = false
}

useClickaway(getElement, !showMenu, close)

const showSettings = ref(false)

function openSettings() {
  showSettings.value = true
  showMenu.value = false
}

function closeModal() {
  showSettings.value = false
}

const router = useRouter()

function logout() {
  const auth = useAuthStore()
  auth.logout()
  router.push({ name: 'login' })
}
</script>

<style lang="scss" scoped>
.user-menu {
  &-wrapper {
    position: relative;
    padding: 0.5rem;
    border-radius: 999rem;
    transition: background 0.2s ease;
    cursor: pointer;

    &:hover {
      background: $border-color;
    }
  }

  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  z-index: 10;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba($black, 0.16);
  opacity: 0;
  display: none;
  transition: opacity 0.2s ease;

  &.visible {
    display: block;
    opacity: 1;
  }

  &-items {
    padding: 0.5rem 0;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: background 0.2s ease;
      gap: 0.5rem;
      white-space: nowrap;

      &:hover {
        background: $border-color;
      }

      &-icon {
        height: 1.2rem;
      }
    }
  }

  &-info {
    padding: 1rem;
    border-top: 1px solid $grey-200;

    &-text {
      color: $grey;
      white-space: nowrap;
    }

    &-name {
      color: $dark;
      font-weight: 700;
    }
  }
}
</style>
