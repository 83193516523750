<template>
  <Modal
    title="User settings"
    width="50rem"
    :visible="visible"
    :disabled="!infoChanged"
    :loading="settingsSaveLoading"
    submit-text="Save"
    @close="close"
    @submit="handleSettingsSave"
  >
    <div class="user-settings">
      <div class="user-settings-row">
        <AvatarUpload
          :image="imageUrl"
          :width="512"
          :max-size="5"
          @input="
            (url, img) => {
              imageUrl = url
              image = img
            }
          "
        />
      </div>
      <div class="user-settings-row">
        <TextInput
          v-model="firstName"
          title="First name"
          :min-length="1"
          :validate="true"
          placeholder="First name can't be empty"
          class="user-settings-row-input"
        />
        <TextInput
          v-model="lastName"
          title="Last name"
          :min-length="1"
          :validate="true"
          placeholder="Last name can't be empty"
          class="user-settings-row-input"
        />
      </div>
      <div class="user-settings-row">
        <TextInput
          v-model="mail"
          title="Email"
          :disabled="true"
          class="user-settings-row-input"
        />
      </div>
      <div class="user-settings-row">
        <TextInput
          v-model="memberRole"
          title="Role"
          placeholder="Your role in the company"
          class="user-settings-row-input"
        />
      </div>
      <div class="user-settings-row">
        <LanguageSelect v-model="language" title="Language" />
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { patchWorkspaceMember } from '@/services/workspace'
import { computed, inject, ref } from 'vue'
import { editCurrentUser, editCurrentUserAvatar } from '@/services/user'
import { avatar, email, first_name, last_name, role } from '@/core/user'

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['close'])
const visible = computed(() => props.visible)

const store = useUserStore()
const { currentWorkspaceMember, currentUser } = storeToRefs(store)

const image = ref(undefined)
const imageUrl = ref('')
const firstName = ref('')
const lastName = ref('')
const mail = ref('')
const memberRole = ref('')
const language = ref('')

const route = useRoute()
const console = inject('$console')
const toast = inject('$toast')

function initSettings() {
  imageUrl.value = avatar(currentUser.value)
  firstName.value = first_name(currentUser.value)
  lastName.value = last_name(currentUser.value)
  mail.value = email(currentUser.value)
  memberRole.value = role(currentWorkspaceMember.value)
  language.value = currentUser.value.language || ''
}

initSettings()

function clearSettings() {
  imageUrl.value = ''
  firstName.value = ''
  lastName.value = ''
  mail.value = ''
  memberRole.value = ''
  language.value = ''
}

function close() {
  emit('close')
  clearSettings()
}

const infoChanged = computed(() => {
  return (
    imageUrl.value !== avatar(currentUser.value) ||
    firstName.value !== first_name(currentUser.value) ||
    lastName.value !== last_name(currentUser.value) ||
    memberRole.value !== role(currentWorkspaceMember.value) ||
    language.value !== currentUser.value.language
  )
})

const settingsSaveLoading = ref(false)

async function handleSettingsSave() {
  settingsSaveLoading.value = true

  try {
    // Update user settings
    if (
      firstName.value !== first_name(currentUser.value) ||
      lastName.value !== last_name(currentUser.value) ||
      language.value !== currentUser.value.language
    ) {
      await editCurrentUser({
        first_name: firstName.value,
        last_name: lastName.value,
        language: language.value
      })
    }

    // Update user avatar
    if (imageUrl.value !== avatar(currentUser.value)) {
      await editCurrentUserAvatar(image.value)
    }

    // Update member role
    if (memberRole.value !== role(currentWorkspaceMember.value)) {
      await patchWorkspaceMember({
        workspace_id: route.params.workspace_id,
        member_id: currentWorkspaceMember.value.uuid,
        data: {
          role: memberRole.value
        }
      })
    }
    toast.success('User settings saved')
  } catch (e) {
    console.debug('Error saving settings', e)
    toast.error(e, 'saving settings')
  } finally {
    settingsSaveLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.user-settings {
  &-row {
    padding: 0.5rem 0;
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    width: 100%;

    &-input {
      flex: 1;
    }
  }
}
</style>
