<template>
  <div class="text-input" :style="inputPadding">
    <p v-if="title" class="text-input-title">{{ title }}</p>
    <div class="text-input-wrapper">
      <div class="text-input-left">
        <div ref="leftgroupicon">
          <Icon
            v-if="iconLeft"
            :name="iconLeft"
            :fill="valid ? 'green' : invalid ? 'red' : 'grey'"
            class="text-input-left-icon"
            @load="handleIconLoad"
          />
        </div>
      </div>
      <input
        id=""
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled || loading"
        type="text"
        name=""
        class="text-input-input"
        :class="{ rounded, [size]: true, disabled, valid, invalid }"
        v-bind="{
          ...(maxLength && { maxlength: maxLength }),
          ...(minLength && { minlength: minLength })
        }"
        @keydown.enter="handleSubmit"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
      <div class="text-input-right">
        <div v-if="maxLength" class="text-input-right-count">
          <span>{{ modelValue.length }}</span>
          <span ref="rightgroupcount"> / {{ maxLength }}</span>
        </div>
        <div v-if="buttonText || buttonIcon" ref="rightgroupbutton">
          <Button
            :icon="buttonIcon"
            :text="buttonText"
            size="xs"
            type="light-solid"
            class="text-input-right-btn"
            :disabled="!modelValue || invalid || disabled"
            :loading="loading"
            :class="{ visible: modelValue || maxLength }"
            @click="handleButtonClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 's',
      validator: (value) => ['s', 'm', 'l'].includes(value)
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    rounded: {
      type: Boolean,
      default: false
    },
    leftIcon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    minLength: {
      type: Number,
      default: undefined
    },
    maxLength: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitWithButton: {
      type: Boolean,
      default: true
    },
    validate: {
      type: Boolean,
      default: false
    },
    validationFunction: {
      type: Function,
      default: undefined
    }
  },
  data: () => ({
    inputPaddingLeft: 0,
    inputPaddingRight: 0
  }),
  emits: ['update:modelValue', 'focus', 'blur', 'submit', 'button-click'],
  computed: {
    iconLeft() {
      return (
        this.leftIcon ||
        (this.validate
          ? this.valid
            ? 'check-circle-filled'
            : this.invalid
              ? 'close-circle-filled'
              : ''
          : '')
      )
    },
    rem() {
      return parseFloat(getComputedStyle(document.documentElement).fontSize)
    },
    inputPadding() {
      return {
        '--input-padding-left': `${this.inputPaddingLeft}px`,
        '--input-padding-right': `${this.inputPaddingRight}px`
      }
    },
    neutral() {
      return !this.valid && !this.invalid
    },
    valid() {
      return (
        this.validate &&
        (this.maxLength === undefined ||
          this.modelValue.length <= this.maxLength) &&
        (this.minLength === undefined ||
          this.modelValue.length >= this.minLength) &&
        (this.validationFunction === undefined ||
          this.validationFunction(this.modelValue) === 1)
      )
    },
    invalid() {
      return (
        this.validate &&
        ((this.maxLength !== undefined &&
          this.modelValue.length > this.maxLength) ||
          (this.minLength !== undefined &&
            this.modelValue.length < this.minLength) ||
          (this.validationFunction !== undefined &&
            this.validationFunction(this.modelValue) === 0))
      )
    }
  },
  watch: {
    iconLeft(val) {
      if (!val) this.$nextTick(this.calculatePadding)
    },
    buttonIcon(val) {
      if (!val) this.$nextTick(this.calculatePadding)
    },
    buttonText() {
      this.$nextTick(this.calculatePadding)
    },
    maxLength() {
      this.$nextTick(this.calculatePadding)
    }
  },
  mounted() {
    if (!this.iconLeft) this.calculatePadding()
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    handleSubmit() {
      this.$emit('submit', this.modelValue)
    },
    handleButtonClick() {
      this.$emit(
        this.submitWithButton ? 'submit' : 'button-click',
        this.modelValue
      )
    },
    handleIconLoad() {
      this.calculatePadding()
    },
    calculatePadding() {
      const rightButtonWidth =
        this.buttonText || this.buttonIcon
          ? this.$refs.rightgroupbutton?.getBoundingClientRect()?.width
          : 0
      const rightCountWidth = this.maxLength
        ? this.$refs.rightgroupcount?.getBoundingClientRect()?.width
        : 0
      const leftIconWidth = this.iconLeft
        ? this.$refs.leftgroupicon?.getBoundingClientRect()?.width
        : 0
      const defaultPadding = 0.5 * this.rem
      this.inputPaddingLeft = leftIconWidth ? leftIconWidth + defaultPadding : 0
      this.inputPaddingRight =
        rightButtonWidth || rightCountWidth
          ? rightButtonWidth + rightCountWidth * 2 + defaultPadding
          : 0
    }
  }
}
</script>

<style scoped lang="scss">
.text-input {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &-input {
    width: 100%;
    background: $grey-200;
    border: 1px solid $grey-300;
    outline: none;
    border-radius: 0.5rem;
    border-color: $grey-300;

    &.rounded {
      border-radius: 999rem;
    }

    &.disabled {
      background: $grey-100;
      border: 1px solid $grey-100;
      color: $grey-400;
      cursor: not-allowed;
    }

    &.valid {
      border-color: $green !important;
    }

    &.invalid {
      border-color: $red !important;
    }

    &.s {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: calc(0.85rem + var(--input-padding-left));
      padding-right: calc(0.85rem + var(--input-padding-right));
    }

    &.m {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: calc(0.85rem + var(--input-padding-left));
      padding-right: calc(0.85rem + var(--input-padding-right));
    }

    &.l {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: calc(1rem + var(--input-padding-left));
      padding-right: calc(1rem + var(--input-padding-right));
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 1px solid $primary;
      background: $white;
    }

    &::placeholder {
      color: $grey-500;
    }
  }

  &-wrapper {
    position: relative;
  }

  &-title {
    font-weight: 600;
    color: $dark;
  }

  &-left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;

    &-icon {
      height: 1.5rem;
    }
  }

  &-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;

    &-count {
      color: rgba($black, 32%);
      font-size: 0.8rem;
      user-select: none;
    }

    &-btn {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;

      &.text-input-right-btn {
        opacity: 0;
        pointer-events: none;

        &.visible {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}
</style>
