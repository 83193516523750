<template>
  <div class="scorecard">
    <div v-if="type === 'circle'" class="scorecard-items">
      <div
        v-for="s in maxScore"
        :key="s"
        :class="{
          [scoreColor]: s <= score && !loading,
          'scorecard-items-item': !loading
        }"
        :style="sizeStyle"
      >
        <Skeleton
          v-if="loading"
          :rounded="true"
          :height="sizeStyle.height"
          :width="sizeStyle.width"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Score',
  props: {
    score: {
      type: Number,
      default: 0
    },
    maxScore: {
      type: Number,
      default: 5
    },
    notRelevantScore: {
      type: Number,
      default: 1
    },
    semiRelevantScore: {
      type: Number,
      default: 3
    },
    relevantScore: {
      type: Number,
      default: 5
    },
    size: {
      type: String,
      default: 's',
      validator: (value) => ['xs', 's', 'm', 'l'].includes(value)
    },
    type: {
      type: String,
      default: 'circle',
      validator: (value) => {
        return ['circle'].includes(value)
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    scoreColor() {
      if (
        this.score > this.semiRelevantScore &&
        this.score <= this.relevantScore
      ) {
        return 'relevant'
      } else if (
        this.score > this.notRelevantScore &&
        this.score <= this.semiRelevantScore
      ) {
        return 'semi-relevant'
      } else {
        return 'not-relevant'
      }
    },
    sizeStyle() {
      const size = {
        xs: 0.5,
        s: 0.75,
        m: 1,
        l: 1.5
      }[this.size]
      return {
        width: `${size}rem`,
        height: `${size}rem`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.scorecard {
  &-items {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.25rem;

    &-item {
      border-radius: 999rem;
      background: $white;
      border: 1px solid rgba($black, 50%);

      &.not-relevant {
        background: $red;
        border: 1px solid $red;
      }

      &.semi-relevant {
        background: $orange;
        border: 1px solid $orange;
      }

      &.relevant {
        background: $green;
        border: 1px solid $green;
      }
    }
  }
}
</style>
