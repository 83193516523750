<template>
  <div ref="uiconContainer">
    <div v-if="svgContent" v-html="svgContent" class="uicon"></div>
  </div>
</template>

<script setup>
import { typeToMimetype } from '@/core/mimetypes'
import { ref, nextTick, computed, watch, onMounted } from 'vue'

const props = defineProps({
  mimetype: {
    type: String,
    required: true
  },
  large: {
    type: Boolean,
    default: false
  }
})

const mimetype = computed(() => props.mimetype)
const large = computed(() => props.large)

const svgContent = ref(null)
const uiconContainer = ref(null)

const typeToImage = {
  [typeToMimetype.pdf]: 'pdf',
  [typeToMimetype.pptx]: 'pptx',
  [typeToMimetype.ppt]: 'pptx',
  [typeToMimetype.google_slides]: 'gslides',
  [typeToMimetype.google_sheets]: 'gsheets',
  [typeToMimetype.google_docs]: 'gdocs',
  [typeToMimetype.doc]: 'docx',
  [typeToMimetype.xls]: 'xlsx',
  [typeToMimetype.xlsx]: 'xlsx',
  [typeToMimetype.ods]: 'xlsx',
  [typeToMimetype.png]: 'img',
  [typeToMimetype.jpeg]: 'img',
  [typeToMimetype.svg]: 'img',
  [typeToMimetype.video]: 'video'
}

const typeToLargeImage = {
  [typeToMimetype.pdf]: 'pdf',
  [typeToMimetype.pptx]: 'pptx',
  [typeToMimetype.ppt]: 'pptx',
  [typeToMimetype.doc]: 'docx',
  [typeToMimetype.xls]: 'xlsx',
  [typeToMimetype.xlsx]: 'xlsx',
  [typeToMimetype.ods]: 'xlsx',
  [typeToMimetype.png]: 'img',
  [typeToMimetype.jpeg]: 'img',
  [typeToMimetype.svg]: 'img',
  [typeToMimetype.video]: 'video'
}

// Don't try doing this dynamically for all possible paths
// glob() only takes string literals, no variables
const svgModules = {
  small: import.meta.glob('/src/assets/file_type/small/*.svg', {
    query: '?raw',
    import: 'default'
  }),
  large: import.meta.glob('/src/assets/file_type/large/*.svg', {
    query: '?raw',
    import: 'default'
  })
}

async function initIcon() {
  try {
    const largeIcon = props.large && !!typeToLargeImage[mimetype.value]
    const size = largeIcon ? 'large' : 'small'
    const imgName = largeIcon
      ? typeToLargeImage[mimetype.value]
      : typeToImage[mimetype.value]
    if (!(size && imgName)) return
    const svgPath = `/src/assets/file_type/${size}/${imgName}-${size}.svg`
    const svgModule = await svgModules[size][svgPath]()
    svgContent.value = svgModule
    await nextTick()
    findSVG()
  } catch (error) {
    svgContent.value = null
  }
}

const isMounted = ref(false)

const canMount = computed(
  () => !!mimetype.value && isMounted.value && !!uiconContainer.value
)

function init() {
  if (canMount.value) initIcon()
}

watch(canMount, init)
watch(mimetype, init)
watch(large, init)

onMounted(() => {
  isMounted.value = true
  init()
})

const svg = ref(null)
const emit = defineEmits(['load', 'error'])

function svgLoaded() {}

const findSVG = () => {
  if (svg.value) svgLoaded()
  const el = uiconContainer.value.querySelector('svg')
  if (el) svg.value = el
  else return
  svg.value.addEventListener('error', () => emit('error'))
  svg.value.addEventListener('load', () => emit('load'))
  if (svg.value?.children?.length) emit('load')
}
</script>

<style scoped lang="scss">
.uicon {
  display: contents;
}

:deep(svg) {
  height: 100%;
  width: 100%;
  display: block;
}
</style>
