import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', () => {
  const currentUser = ref(null)
  const currentWorkspaceMember = ref(null)

  function setCurrentUser(user) {
    currentUser.value = user
  }

  function setCurrentWorkspaceMember(member) {
    currentWorkspaceMember.value = member
  }

  const isAdmin = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes('workspace.write')
  )
  const isUman = computed(() => currentUser.value?.is_internal)

  const onboardingRequired = computed(() => !currentUser.value?.is_setup)

  const canWriteIntegrations = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.integrations.write'
    )
  )
  const canWriteMembers = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.members.write'
    )
  )
  const canWriteTeams = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes('workspace.teams.write')
  )
  const canWriteOri = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes('workspace.ori.write')
  )
  const canReadOri = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes('workspace.ori.read')
  )
  const canWriteOriTemplates = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.templates.ori.write'
    )
  )
  const canWriteTools = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes('workspace.tools.write')
  )
  const canWriteLabels = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.labels.write'
    )
  )
  const canWriteStories = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.stories.write'
    )
  )
  const canSearchStories = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.stories.search'
    )
  )
  const canWriteChats = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes('workspace.chats.write')
  )
  const canWriteCalendars = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.calendars.write'
    )
  )
  const canUseSearch = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.resources.search'
    )
  )
  const canUseProspecting = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.prospects.read'
    )
  )
  const canWriteProspectingLists = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.prospect_lists.write'
    )
  )
  const canReadProspectingLists = computed(() =>
    currentWorkspaceMember.value?.permissions?.includes(
      'workspace.prospect_lists.read'
    )
  )

  return {
    currentUser,
    currentWorkspaceMember,
    setCurrentUser,
    setCurrentWorkspaceMember,
    isAdmin,
    isUman,
    onboardingRequired,
    canWriteIntegrations,
    canWriteMembers,
    canWriteTeams,
    canWriteOri,
    canReadOri,
    canWriteOriTemplates,
    canWriteTools,
    canWriteLabels,
    canWriteStories,
    canSearchStories,
    canWriteChats,
    canWriteCalendars,
    canUseSearch,
    canUseProspecting,
    canWriteProspectingLists,
    canReadProspectingLists
  }
})
