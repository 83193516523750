<template>
  <div
    class="udropdown"
    :class="{ 'full-width': fullWidth, 'set-content-width': setContentWidth }"
    :style="{ '--udropdown-trigger-offset': `${triggerOffset}px` }"
  >
    <div
      ref="dropdowntrigger"
      class="udropdown-trigger"
      :class="{
        'full-width': fullWidth,
        disabled
      }"
      @click="toggle"
    >
      <slot name="trigger">
        <Button icon="kebab" type="grey" size="xs" />
      </slot>
    </div>
    <Teleport to="body" :disabled="!(appendToBody && toggled)">
      <div
        ref="dropdownwrapper"
        class="udropdown-wrapper"
        :class="{
          visible: toggled,
          'position-fixed': positionFixed,
          leftaligned,
          topaligned
        }"
        :style="{ ...offset, ...contentStyle }"
      >
        <slot name="content" :hide="hideOptions">
          <div
            class="udropdown-content"
            :style="{ ...(maxHeight ? { maxHeight, overflowY: 'auto' } : {}) }"
          >
            <div
              v-for="item in items"
              :key="item.text"
              class="udropdown-content-item"
              :class="{ disabled: item.disabled }"
              :style="`--udropdown-item-color: ${fillColor(item)};`"
              @click="handleItemClick(item)"
            >
              <slot name="item" :item="item">
                <Icon
                  v-if="item.icon"
                  :name="item.icon"
                  :fill="fillColor(item)"
                  class="udropdown-content-item-icon"
                />
                {{ item.text }}
              </slot>
            </div>
          </div>
        </slot>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { mapColor } from '@/core/colors'

export default {
  name: 'Dropdown',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    alignLeft: {
      type: Boolean,
      default: false
    },
    alignTop: {
      type: Boolean,
      default: false
    },
    calculateAlignment: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    positionFixed: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: ''
    },
    selectCloses: {
      type: Boolean,
      default: true
    },
    contentStyle: {
      type: Object,
      default: () => ({})
    },
    setContentWidth: {
      type: Boolean,
      default: true
    },
    triggerOffset: {
      type: Number,
      default: 7
    }
  },
  emits: ['show', 'hide'],
  data: () => ({
    toggled: false,
    offset: {},
    leftaligned: false,
    topaligned: false
  }),
  watch: {
    positionFixed(val) {
      if (val) this.initFixed()
    },
    alignLeft(val) {
      if (!this.calculateAlignment) this.leftaligned = val
    },
    alignTop(val) {
      if (!this.calculateAlignment) this.topaligned = val
    }
  },
  created() {
    if (!this.calculateAlignment) {
      this.leftaligned = this.alignLeft
      this.topaligned = this.alignTop
    }
  },
  mounted() {
    document.addEventListener('click', this.checkClickOutside)
    window.addEventListener('resize', this.calculateOffset)
  },
  beforeUnmount() {
    if (this.toggled) this.hideOptions()
    document.removeEventListener('click', this.checkClickOutside)
    window.removeEventListener('resize', this.calculateOffset)
  },
  methods: {
    fillColor(item) {
      return mapColor(item.filter || 'dark')
    },
    toggle(e) {
      this.calculateAlignments(e)
      this.toggled = !this.toggled
      if (this.toggled && (this.positionFixed || this.appendToBody))
        this.calculateOffset()
      if (!this.toggled) this.hideOptions()
      else this.$emit('show')
    },
    calculateAlignments(e) {
      if (this.calculateAlignment) {
        this.leftaligned = e.clientX < window.innerWidth / 2
        this.topaligned = e.clientY > window.innerHeight / 2
      }
    },
    calculateOffset() {
      if (!(this.positionFixed || this.appendToBody)) return
      const { top, left, width, height } =
        this.$refs.dropdowntrigger.getBoundingClientRect()
      this.offset = {
        ...(this.leftaligned
          ? { left: `${left}px` }
          : { right: `${window.innerWidth - left - width}px` }),
        ...(this.topaligned
          ? { bottom: `${window.innerHeight - top + this.triggerOffset}px` }
          : { top: `${top + height + this.triggerOffset}px` }),
        ...(this.fullWidth && this.setContentWidth
          ? { minWidth: `${width}px`, width: `${width}px` }
          : {})
      }
    },
    hideOptions() {
      this.toggled = false
      this.$emit('hide')
    },
    handleItemClick(item) {
      if (item.disabled) return
      if (this.selectCloses) this.hideOptions()
      item.callback()
    },
    checkClickOutside(event) {
      let outside = true
      const bodyWrapper = document.querySelector('body > .udropdown-wrapper')
      if (
        bodyWrapper?.contains(event.target) ||
        this.$refs.dropdownwrapper?.contains(event.target) ||
        this.$refs.dropdowntrigger?.contains(event.target)
      ) {
        outside = false
      }
      if (outside) this.hideOptions()
    }
  }
}
</script>

<style scoped lang="scss">
.udropdown {
  position: relative;

  &.full-width {
    width: 100%;

    &.set-content-width .udropdown-wrapper {
      width: 100%;
    }

    & .udropdown-content-item {
      padding-right: 0;
    }
  }

  &-trigger {
    cursor: pointer;
    width: max-content;

    &.full-width {
      width: 100%;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &-wrapper {
    z-index: 99;
    opacity: 0;
    display: none;
    pointer-events: none;
    transition: opacity 0.2s ease;
    min-width: max-content;
    width: max-content;

    &.visible {
      opacity: 1;
      pointer-events: all;
      display: block;
    }

    &.position-fixed {
      position: fixed;
      width: max-content;
    }

    &:not(.position-fixed) {
      position: absolute;

      &.leftaligned {
        left: 0;
      }

      &.topaligned {
        bottom: calc(100% + var(--udropdown-trigger-offset));
      }

      &:not(.leftaligned) {
        right: 0;
      }

      &:not(.topaligned) {
        top: calc(100% + var(--udropdown-trigger-offset));
      }
    }
  }

  &-content {
    background: $white;
    border-radius: 4px;
    border: 1px solid $border-color;

    &-item {
      min-width: max-content;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: background 0.2s ease;
      white-space: nowrap;
      cursor: pointer;
      color: var(--udropdown-item-color);
      padding-right: 1.5rem;

      &:hover {
        background: color-mix(
          in srgb,
          var(--udropdown-item-color) 8%,
          transparent
        );
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }

      &-icon {
        width: 1rem;
        height: 1rem;
        min-width: 1rem;
      }
    }
  }
}
</style>
