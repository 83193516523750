export function downloadSignedUrl({ url, title, callback = null }) {
  let xhr = new XMLHttpRequest()
  const a = document.createElement('a')

  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    const file = new Blob([xhr.response], { type: 'application/octet-stream' })
    a.href = window.URL.createObjectURL(file)
    a.download = title
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    if (callback) callback()
  }
  xhr.send()
}
