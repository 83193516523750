<template>
  <div class="markdown-edit">
    <!-- eslint-disable -->
    <div class="markdown-edit-view">
      <div
        ref="markdowncontent"
        class="markdown-edit-view-content"
        v-html="html"
      ></div>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import DOMPurify from 'dompurify'
import { marked } from 'marked'

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener')
  }
})

export default {
  name: 'MarkdownEdit',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    preview: false,
    text: ''
  }),
  computed: {
    html() {
      return DOMPurify.sanitize(marked.parse(this.text))
    },
    htmlText() {
      return this.$refs.markdowncontent?.innerText
    }
  },
  watch: {
    editing(newVal) {
      if (newVal) {
        this.text = this.value
        this.preview = false
      }
    },
    value(newVal) {
      this.text = newVal
    }
  },
  mounted() {
    this.text = this.value
  }
}
</script>

<style scoped lang="scss">
.markdown-edit {
  height: 100%;
  width: 100%;

  &-view {
    &-content {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }
}

// Return default styles
.markdown-edit-view-content {
  &:deep(h1) {
    display: block;
    font-size: 2em;
    font-weight: bold;
    margin: 0.67em 0;
    line-height: unset;
  }

  &:deep(h2) {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.83em 0;
    line-height: unset;
  }

  &:deep(h3) {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
    line-height: unset;
  }

  &:deep(h4) {
    display: block;
    font-weight: bold;
    margin: 1.33em 0;
    line-height: unset;
  }

  &:deep(h5) {
    display: block;
    font-size: 0.83em;
    margin: 1.67em 0;
    line-height: unset;
  }

  &:deep(h6) {
    display: block;
    font-size: 0.83em;
    margin: 2.33em 0;
    line-height: unset;
  }

  &:deep(p) {
    display: block;
    margin: 1em 0;
    line-height: unset;
  }

  &:deep(ul) {
    display: block;
    list-style-type: disc;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  &:deep(ol) {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  &:deep(blockquote) {
    padding-left: 1rem;
    border-left: 2px solid $border-color;
  }

  &:deep(a) {
    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }
}
</style>
