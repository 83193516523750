<template>
  <div class="slide-deck">
    <div class="slide-deck-preview">
      <img
        v-show="firstImageLoaded"
        :src="image"
        alt=""
        class="slide-deck-preview-image"
        @load="firstImageLoaded = true"
      />
      <Skeleton
        v-if="!firstImageLoaded"
        height="100%"
        class="slide-deck-skeleton"
      />
      <div class="slide-deck-buttons">
        <slot name="buttons" :index="index"></slot>
      </div>
    </div>
    <div class="slide-deck-controls">
      <Button
        icon="arrow-left"
        type="grey"
        class="slide-deck-controls-arrow"
        :disabled="isStart"
        @click="() => changeIndex(-items.length)"
      />
      <Button
        icon="chevron-left"
        type="grey"
        class="slide-deck-controls-arrow"
        :disabled="isStart"
        @click="() => changeIndex(-1)"
      />
      <div class="slide-deck-controls-counter">
        <NumberInput
          v-model="page"
          class="slide-deck-controls-counter-input"
          :min="1"
          :max="items ? items.length : 0"
          @submit="changePage"
          @blur="changePage"
        />
        <span>/</span>
        <span>{{ itemCount }}</span>
      </div>
      <Button
        icon="chevron-left"
        type="grey"
        class="slide-deck-controls-arrow right-arrow"
        :disabled="isEnd"
        @click="() => changeIndex(1)"
      />
      <Button
        icon="arrow-left"
        type="grey"
        class="slide-deck-controls-arrow right-arrow"
        :disabled="isEnd"
        @click="() => changeIndex(items.length)"
      />
    </div>
  </div>
</template>

<script>
const clamp = (value, min, max) => Math.min(Math.max(value, min), max)

export default {
  name: 'SlideDeck',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    previewKey: {
      type: String,
      default: 'preview'
    },
    forceIndex: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    firstImageLoaded: false,
    index: 0,
    page: 1
  }),
  computed: {
    itemsEmpty() {
      return !this.items?.length
    },
    itemCount() {
      return this.items?.length || ''
    },
    image() {
      return this.itemsEmpty
        ? ''
        : this.items?.[this.index]?.[this.previewKey] || ''
    },
    isStart() {
      return this.index === 0
    },
    isEnd() {
      return this.index === (this.items?.length || 1) - 1
    }
  },
  watch: {
    forceIndex() {
      this.setIndex(this.forceIndex || 0)
    },
    page() {
      this.page = clamp(this.page || 0, 1, this.items.length)
      this.changePage()
    }
  },
  created() {
    this.setIndex(this.forceIndex || 0)
    window.addEventListener('keydown', this.handleKeyPressed)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyPressed)
  },
  methods: {
    setIndex(index) {
      this.index = clamp(index, 0, this.items.length - 1)
      this.page = this.index + 1
      this.$emit('changePage', this.page)
    },
    changeIndex(delta) {
      this.setIndex(this.index + delta)
    },
    changePage() {
      const page = parseInt(this.page)
      this.setIndex(page - 1)
    },
    handleKeyPressed(e) {
      if (e.code === 'ArrowLeft') return this.changeIndex(-1)
      if (e.code === 'ArrowRight') return this.changeIndex(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-deck {
  border-radius: 4px;
  border: 1px solid $border-color;

  &-preview {
    position: relative;
    border-bottom: 1px solid $border-color;

    &-image {
      display: block;
      border-radius: 4px 4px 0 0;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &-skeleton {
    aspect-ratio: 16 / 9;
  }

  &-buttons {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &-controls {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: $white;

    &-arrow {
      &.right-arrow {
        transform: rotate(180deg);
      }
    }

    &-counter {
      width: 10rem;
      min-width: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      &-input {
        width: 4rem;
      }
    }
  }
}
</style>
