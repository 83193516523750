import {
  getClient,
  getResponseData,
  Paginator,
  getPaginatedData
} from '@/core/axios'
import { useIntegrationStore } from '@/stores/integrations'

const workspaceClient = getClient('/workspaces')
const userClient = getClient('/users')

// WORKSPACE INTEGRATIONS

export async function getWorkspaceIntegrations({ workspace_id }) {
  const res = await workspaceClient.get(`/${workspace_id}/integrations/`)
  const data = getResponseData(res)
  const store = useIntegrationStore()
  store.setWorkspaceIntegrations(data)
  store.setWorkspaceIntegrationsWorkspace(workspace_id)
  return data
}

export async function deleteIntegration({
  workspace_id,
  integration_id,
  delete_resources
}) {
  const res = await workspaceClient.delete(
    `/${workspace_id}/integrations/${integration_id}/`,
    {
      params: { delete_resources }
    }
  )
  const store = useIntegrationStore()
  store.setWorkspaceIntegrations(
    store.workspaceIntegrations.filter((int) => int.id !== integration_id)
  )
  return getResponseData(res)
}

export async function getIntegrationUsers({ workspace_id, integration_id }) {
  return await getPaginatedData(
    workspaceClient,
    `${workspace_id}/integrations/${integration_id}/users/`
  )
}

export async function getIntegrationUsersPaginator({
  workspace_id,
  integration_id
}) {
  return new Paginator(
    workspaceClient,
    workspaceClient.get(
      `/${workspace_id}/integrations/${integration_id}/users/`
    ),
    () => {}
  )
}

export async function authenticateIntegration({
  workspace_id,
  integration,
  oauth,
  api_key
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/integrations/authenticate/`,
    {
      integration,
      ...(oauth ? { oauth } : {}),
      ...(api_key ? { api_key } : {})
    }
  )
  const int = getResponseData(res)
  const store = useIntegrationStore()
  store.setWorkspaceIntegrations([...store.workspaceIntegrations, int])
  return int
}

export async function getIntegrationOAuthState({ workspace_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/integrations/oauth_state/`
  )
  return getResponseData(res)
}

export async function getIntegrationFiles({
  workspace_id,
  integration_id,
  path
}) {
  return getPaginatedData(
    workspaceClient,
    `/${workspace_id}/integrations/${integration_id}/files/`,
    {
      params: { path }
    }
  )
}

export async function postIntegrationFiles({
  workspace_id,
  integration_id,
  payload
}) {
  const res = workspaceClient.post(
    `/${workspace_id}/integrations/${integration_id}/sync/`,
    payload
  )
  return getResponseData(res)
}

export async function getIntegrationSyncStats({
  workspace_id,
  integration_id,
  params
}) {
  return await getPaginatedData(
    workspaceClient,
    `/${workspace_id}/integrations/${integration_id}/stats/`,
    { params: { ...params } },
    20
  )
}

export async function getProfiles({ workspace_id, integration_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/integrations/${integration_id}/profiles/`
  )
  return getResponseData(res)
}

export async function createProfile({
  workspace_id,
  integration_id,
  identifier
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/integrations/${integration_id}/profiles/`,
    {
      identifier
    }
  )
  return getResponseData(res)
}

export async function deleteProfile({
  workspace_id,
  integration_id,
  profile_id
}) {
  await workspaceClient.delete(
    `/${workspace_id}/integrations/${integration_id}/profiles/${profile_id}/`
  )
}

// USER INTEGRATIONS

export async function getUserIntegrations() {
  const res = await userClient.get('/me/integrations/')
  const data = getResponseData(res)
  const store = useIntegrationStore()
  store.setUserIntegrations(data)
  return data
}

export async function authenticateUserIntegration({ integration, code }) {
  const res = await userClient.post('/me/integrations/authenticate/', {
    integration,
    oauth: { code }
  })
  return getResponseData(res)
}

export async function getUserIntegrationFiles({
  integration_id,
  search,
  path
}) {
  return getPaginatedData(
    userClient,
    `/me/integrations/${integration_id}/files/`,
    {
      params: {
        ...(search ? { search } : {}),
        ...(path ? { path } : {})
      }
    }
  )
}

export async function getUserIntegrationFilesPaginator({
  integration_id,
  search,
  path,
  page_size = 20
}) {
  const body = {
    search,
    ...(path ? { path } : {}),
    ...(page_size ? { page_size } : {})
  }

  const res = await userClient.get(
    `/me/integrations/${integration_id}/files/`,
    {
      params: body
    }
  )

  return new Paginator(
    userClient,
    res,
    () => {},
    '',
    (url) => client.get(url, { params: body })
  )
}

export async function uploadToIntegration({
  user_id,
  integration_id,
  integrationfile_id,
  signed_url,
  name
}) {
  const { data } = await userClient.post(
    `/${user_id}/integrations/${integration_id}/files/`,
    {
      parent_integrationfile_id: integrationfile_id,
      signed_url,
      name
    }
  )
  return data
}

// WORKSPACE MEMBER INTEGRATIONS

export async function getWorkspaceMemberIntegrations({
  workspace_id,
  member_id
}) {
  const client = getClient(`/workspaces/${workspace_id}/members/${member_id}`)
  const res = await client.get('/integrations/')
  const data = getResponseData(res)
  const store = useIntegrationStore()
  store.setWorkspaceMemberIntegrations(data)
  store.setWorkspaceMemberIntegrationsWorkspace(workspace_id)
  return data
}

export async function authenticateWorkspaceMemberIntegration({
  workspace_id,
  member_id,
  integration,
  code
}) {
  const res = await getClient(
    `/workspaces/${workspace_id}/members/${member_id}`
  ).post('/integrations/authenticate/', {
    integration,
    oauth: { code }
  })
  return getResponseData(res)
}

export async function getWorkspaceMemberIntegrationFiles({
  workspace_id,
  member_id,
  integration_id,
  query,
  path,
  object_name = 'files'
}) {
  const client = getClient(`/workspaces/${workspace_id}/members/${member_id}`)
  return getPaginatedData(
    client,
    `/integrations/${integration_id}/${object_name}/`,
    {
      params: {
        ...(query ? { query } : {}),
        ...(path ? { path } : {})
      }
    }
  )
}

export async function getWorkspaceMemberIntegrationFilesPaginator({
  workspace_id,
  member_id,
  integration_id,
  search,
  path,
  object_name = 'files',
  page_size = 20
}) {
  const client = getClient(`/workspaces/${workspace_id}/members/${member_id}`)
  const body = {
    search,
    ...(path ? { path } : {}),
    ...(page_size ? { page_size } : {})
  }

  const res = await client.get(
    `/integrations/${integration_id}/${object_name}/`,
    { params: { ...body } }
  )

  return new Paginator(
    client,
    res,
    () => {},
    '',
    (url) => client.get(url, body)
  )
}

export async function setCalendarSync({
  workspace_id,
  member_id,
  integration_id,
  calendar_id,
  sync,
  tools
}) {
  const client = getClient(`/workspaces/${workspace_id}/members/${member_id}`)
  const res = await client.patch(
    `/integrations/${integration_id}/calendars/${calendar_id}/`,
    {
      sync,
      tools
    }
  )
  return getResponseData(res)
}
