import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useLabelStore = defineStore('labels', () => {
  const qualityLabels = ref([])
  const attributes = ref([])
  const attributesLoaded = ref(false)
  const attributesLoading = ref(false)

  const informativeQualityLabels = computed(() =>
    qualityLabels.value.filter((label) => label.type === 'informative')
  )
  const negativeQualityLabels = computed(() =>
    qualityLabels.value.filter((label) => label.type === 'negative')
  )
  const positiveQualityLabels = computed(() =>
    qualityLabels.value.filter((label) => label.type === 'positive')
  )
  const warningQualityLabels = computed(() =>
    qualityLabels.value.filter((label) => label.type === 'warning')
  )

  function setQualityLabels(labels) {
    qualityLabels.value = labels
  }

  function setAttributes(attrs) {
    attributes.value = attrs
  }

  function setAttributesLoaded(loaded) {
    attributesLoaded.value = loaded
  }

  function setAttributesLoading(loading) {
    attributesLoading.value = loading
  }

  function addAttribute(attribute) {
    attributes.value.push(attribute)
  }

  function updateAttribute(attribute) {
    attributes.value = attributes.value.map((attr) =>
      attr.uuid === attribute.uuid ? attribute : attr
    )
  }

  function removeAttribute(attribute) {
    attributes.value = attributes.value.filter((attr) => attr.uuid !== attribute.uuid)
  }

  function addAttributeValue({ attribute_id, value }) {
    const attribute = attributes.value.find((attr) => attr.uuid === attribute_id)
    attribute.values.push(value)
  }

  function updateAttributeValue({ attribute_id, value }) {
    const attribute = attributes.value.find((attr) => attr.uuid === attribute_id)
    attribute.values = attribute.values.map((val) =>
      val.uuid === value.uuid ? value : val
    )
  }

  function removeAttributeValue({ attribute_id, value }) {
    const attribute = attributes.value.find((attr) => attr.uuid === attribute_id)
    attribute.values = attribute.values.filter((val) => val.uuid !== value.uuid)
  }

  return {
    qualityLabels,
    informativeQualityLabels,
    negativeQualityLabels,
    positiveQualityLabels,
    warningQualityLabels,
    attributes,
    attributesLoaded,
    attributesLoading,
    setQualityLabels,
    setAttributes,
    setAttributesLoaded,
    setAttributesLoading,
    addAttribute,
    updateAttribute,
    removeAttribute,
    addAttributeValue,
    updateAttributeValue,
    removeAttributeValue
  }
})
