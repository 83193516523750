<template>
  <div class="tips">
    <Icon
      name="help-bubble"
      class="tips-img"
      @mouseenter.native="() => (showTips = true)"
      @mouseleave.native="() => (showTips = false)"
    />
    <div v-if="showTips" class="tips-container">
      <p class="tips-container-header">Search by</p>
      <div class="tips-container-cols">
        <div class="tips-container-cols-col">
          <div
            v-for="tip in tips"
            :key="tip.instruction"
            class="tips-container-cols-col-entry body"
          >
            {{ tip.instruction }}:
          </div>
        </div>
        <div class="tips-container-cols-col">
          <div
            v-for="tip in tips"
            :key="tip.instruction"
            class="tips-container-cols-col-entry"
          >
            {{ tip.example }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchTips',
  data: () => ({
    tips: [
      { instruction: 'Search for a keyword', example: 'governance' },
      { instruction: 'Search for two keywords', example: 'governance roadmap' },
      { instruction: 'Search for an exact phrase', example: '"data security"' },
      {
        instruction: 'Exclude certain words',
        example: 'data security -framework'
      }
    ],
    showTips: false
  })
}
</script>

<style scoped lang="scss">
.tips {
  position: relative;

  &-img {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }

  &-container {
    position: absolute;
    top: 45px;
    right: 0;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid $border-color;
    z-index: 99;
    background: $white;

    &-header {
      font-size: 1.2rem;
      font-weight: 600;
      white-space: nowrap;
      padding-bottom: 0.5rem;
    }

    &-cols {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      white-space: nowrap;
      gap: 2rem;
      &-col {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.25rem;
      }
    }
  }
}
</style>
