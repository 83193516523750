<template>
  <div ref="impersonationmenu" class="impersonate">
    <Tooltip
      v-if="impersonatedMember"
      :active="!visible"
      :text="`Impersonating ${full_name(impersonatedMember)}`"
      position="right"
      type="is-dark"
    >
      <div class="impersonate-trigger" @click="toggleMenu">
        <Avatar
          :user="impersonatedMember"
          fallback-icon="user"
          :filter-fallback="{}"
          size="xs"
        />
      </div>
    </Tooltip>
    <div v-if="visible" class="impersonate-content">
      <div class="impersonate-content-header">
        <p class="impersonate-content-header-title">Impersonating</p>
        <div class="impersonate-content-header-avatar">
          <Avatar
            :user="impersonatedMember"
            fallback-icon="user"
            :filter-fallback="{}"
            size="s"
          />
          {{ full_name(impersonatedMember) }}
          <span class="impersonate-content-header-avatar-email">{{
            email(impersonatedMember)
          }}</span>
        </div>
      </div>
      <div class="impersonate-content-actions">
        <Button text="Change user" type="white" @click="openModal" />
        <Button
          text="Stop impersonation"
          @click="() => impersonate(undefined)"
        />
      </div>
    </div>
    <Modal
      :visible="showModal"
      title="Change impersonated user"
      :show-footer="false"
      @close="closeModal"
    >
      <TextInput
        v-model="query"
        left-icon="search"
        placeholder="Find member to impersonate"
        @submit="search"
      />
      <div class="impersonate-modal-content-list">
        <div
          v-for="option in options"
          :key="option.uuid"
          class="impersonate-modal-content-list-option"
        >
          <Avatar :user="option" size="xs" />
          {{ full_name(option) }}
          <Button
            text="Impersonate"
            class="impersonate-modal-content-list-option-button"
            :disabled="
              (!!impersonatedMember &&
                option.uuid === impersonatedMember.uuid) ||
              (!!currentWorkspaceMember &&
                option.uuid === currentWorkspaceMember.uuid)
            "
            @click="() => impersonate(option)"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { full_name, email } from '@/core/user'
import {
  getWorkspaceMember,
  getWorkspaceMembersPage
} from '@/services/workspace'

export default {
  name: 'SidebarImpersonationMenu',
  data: () => ({
    visible: false,
    impersonatedMember: undefined,
    showModal: false,
    query: '',
    options: []
  }),
  inject: ['$console', '$toast', 'trycatch'],
  computed: {
    ...mapState(useUserStore, ['currentWorkspaceMember']),
    impersonationFromRoute() {
      return this.$route.query.act_as
    }
  },
  watch: {
    impersonationFromRoute: {
      immediate: true,
      handler() {
        this.loadImpersonatedMember()
      }
    }
  },
  methods: {
    full_name,
    email,
    async loadImpersonatedMember() {
      if (
        this.impersonationFromRoute &&
        this.impersonationFromRoute !== this.impersonatedMember?.uuid
      ) {
        this.impersonatedMember = await this.trycatch(
          getWorkspaceMember({
            workspace_id: this.$route.params.workspace_id,
            member_id: this.impersonationFromRoute
          }),
          'loading impersonated member'
        )
      } else if (!this.impersonationFromRoute) {
        this.impersonatedMember = undefined
      }
    },
    toggleMenu() {
      if (this.visible) this.closeMenu()
      else this.openMenu()
    },
    openMenu() {
      this.visible = !this.visible
      document.addEventListener('click', this.checkClickOutside)
    },
    checkClickOutside(e) {
      if (
        this.visible &&
        this.$refs.impersonationmenu &&
        !this.$refs.impersonationmenu.contains(e.target)
      ) {
        this.closeMenu()
      }
    },
    closeMenu() {
      this.visible = false
      document.removeEventListener('click', this.checkClickOutside)
    },
    impersonate(member) {
      this.$router.push({
        query: {
          ...this.$route.query,
          act_as: member?.uuid || member || ''
        }
      })
      if (member) this.closeModal()
      else this.closeMenu()
    },
    openModal() {
      this.closeMenu()
      this.showModal = true
      this.search()
    },
    closeModal() {
      this.showModal = false
      this.query = ''
      this.options = []
    },
    async search() {
      this.options = await this.trycatch(
        getWorkspaceMembersPage({
          workspace_id: this.$route.params.workspace_id,
          params: this.query ? { search: this.query } : {}
        }),
        'searching for this user'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.impersonate {
  position: relative;

  &-trigger {
    padding: 0.25rem;
    border-radius: 999rem;
    cursor: pointer;
    border: 1px dashed $primary;

    &:hover {
      background: hover(white);
    }
  }

  &-content {
    position: absolute;
    left: calc(100% + 1.5rem);
    bottom: 0;
    width: 15rem;
    width: min(35rem, 90vw);
    background: $white;
    border-radius: 4px;
    border: 1px solid $border-color;
    box-shadow: 0 0 8px $border-color;
    z-index: 1;

    &-header {
      padding: 1rem;
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;

      &-title {
        font-weight: 600;
        font-size: 1.25rem;
      }

      &-avatar {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem;
        border-radius: 4px;
        background: rgba($primary, 0.08);

        &-email {
          color: $grey;
          margin-left: auto;
        }
      }
    }

    &-actions {
      padding: 0.75rem 1rem;
      border-top: 1px solid $border-color;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  &-modal {
    &-content {
      &-list {
        padding: 0.5rem 0;

        &-option {
          padding: 0.5rem 1rem;
          display: flex;
          flex-flow: row nowrap;
          gap: 0.5rem;
          align-items: center;
          border-radius: 4px;

          &:hover {
            background: $border-color;
          }

          &-button {
            margin-left: auto;
          }
        }
      }
    }
  }
}
</style>
