import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useWorkspaceStore = defineStore('workspace', () => {
  const currentWorkspace = ref(null)
  const workspaces = ref([])

  function setCurrentWorkspace(workspace) {
    const ws = workspaces.value.find((ws) => ws.uuid === workspace)
    currentWorkspace.value = ws
  }

  function editCurrentWorkspace(data) {
    const ws = { ...currentWorkspace.value, ...data }
    currentWorkspace.value = ws
    workspaces.value = workspaces.value.map((w) =>
      w.uuid === ws.uuid ? ws : w
    )
  }

  function setWorkspaces(ws) {
    workspaces.value = ws
  }

  const showCollectSlides = computed(
    () =>
      currentWorkspace.value?.settings?.find(
        (setting) => setting.name === 'collect_slides'
      )?.enabled || false
  )
  const downloadFilesEnabled = computed(
    () =>
      currentWorkspace.value?.settings?.find(
        (setting) => setting.name === 'download_files'
      )?.enabled || false
  )
  const highlightsEnabled = computed(
    () =>
      currentWorkspace.value?.settings?.find(
        (setting) => setting.name === 'highlight_resource'
      )?.enabled || false
  )
  const deduplicationEnabled = computed(
    () =>
      currentWorkspace.value?.settings?.find(
        (setting) => setting.name === 'deduplication'
      )?.enabled || false
  )

  const draftOriContentEnabled = computed(
    () =>
      currentWorkspace.value?.settings?.find(
        (setting) => setting.name === 'draft_ori_content'
      )?.enabled || false
  )

  return {
    currentWorkspace,
    setCurrentWorkspace,
    editCurrentWorkspace,
    workspaces,
    setWorkspaces,
    showCollectSlides,
    downloadFilesEnabled,
    highlightsEnabled,
    deduplicationEnabled,
    draftOriContentEnabled
  }
})
