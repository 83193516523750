<template>
  <div ref="uiconContainer">
    <div
      v-if="svgContent"
      v-html="svgContent"
      class="uicon"
    ></div>
  </div>
</template>

<script setup>
import { mapColor } from '@/core/colors'
import { ref, nextTick, computed, watch, onMounted, watchEffect } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  fill: {
    type: String,
    default: 'dark'
  },
  path: {
    type: String,
    default: '/icons/'
  },
  setFill: {
    type: Boolean,
    default: true
  }
})
const svgContent = ref(null)
const uiconContainer = ref(null)

const iconFill = computed(() => mapColor(props.fill))

// Don't try doing this dynamically for all possible paths
// glob() only takes string literals, no variables
const svgModules = {
  ['/icons/']: import.meta.glob('/src/assets/icons/*.svg', {
    query: '?raw',
    import: 'default'
  }),
  ['/logos/']: import.meta.glob('/src/assets/logos/*.svg', {
    query: '?raw',
    import: 'default'
  })
}

async function initIcon() {
  try {
    const svgPath = `/src/assets${props.path}${props.name}.svg`
    const svgModule = await svgModules[props.path][svgPath]()
    svgContent.value = svgModule
    await nextTick()
    findSVG()
  } catch (error) {
    svgContent.value = null
  }
}

const path = computed(() => props.path)
const name = computed(() => props.name)

const isMounted = ref(false)

const canMount = computed(
  () =>
    !!path.value && !!name.value && isMounted.value && !!uiconContainer.value
)

watchEffect(() => {
  if (canMount.value) initIcon()
})

watch(path, () => {
  if (canMount.value) initIcon()
})

watch(name, () => {
  if (canMount.value) initIcon()
})

onMounted(() => {
  isMounted.value = true
  if (canMount.value) initIcon()
})

const svg = ref(null)
const emit = defineEmits(['load', 'error'])

function applyFill(elements) {
  elements.forEach((el) => {
    if (el.hasAttribute('stroke')) {
      el.setAttribute('stroke', iconFill.value)
    }

    if (el.hasAttribute('fill')) {
      el.setAttribute('fill', iconFill.value)
    }
    applyFill(Array.from(el.children))
  })
}

function svgLoaded() {
  emit('load')
  if (!props.setFill) return
  applyFill(Array.from(svg.value.children))
}

const findSVG = () => {
  if (svg.value) svgLoaded()
  const el = uiconContainer.value.querySelector('svg')
  if (el) svg.value = el
  else return
  svg.value.addEventListener('error', () => emit('error'))
  svg.value.addEventListener('load', () => svgLoaded())
  if (svg.value?.children?.length) svgLoaded()
}

watch(iconFill, () => {
  if (props.setFill) findSVG()
})
</script>

<style scoped lang="scss">
.uicon {
  display: contents;
}

:deep(svg) {
  height: 100%;
  width: 100%;
  display: block;
}
</style>
