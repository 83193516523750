<template>
  <div class="language-select" :class="{ short }">
    <span v-if="title" class="language-select-title">{{ title }}</span>
    <Dropdown
      :items="options"
      :full-width="true"
      :position-fixed="positionFixed"
      :content-style="contentStyle"
    >
      <template #trigger>
        <div class="language-select-trigger">
          {{ short ? modelValue : valueName }}
          <Icon
            name="chevron-down-medium"
            class="language-select-trigger-icon"
          />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useLanguageStore } from '@/stores/language'

export default {
  name: 'LanguageSelect',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    positionFixed: {
      type: Boolean,
      default: false
    },
    short: {
      type: Boolean,
      default: false
    },
    contentStyle: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapState(useLanguageStore, ['defaultLanguage', 'supportedLanguages']),
    options() {
      return this.supportedLanguages.map((l) => ({
        text: l.name,
        value: l.code,
        callback: () => this.handleLanguageSelect(l)
      }))
    },
    valueName() {
      return this.options.find((o) => o.value === this.modelValue)?.text || ''
    }
  },
  created() {
    if (!this.modelValue) {
      this.$emit('update:modelValue', this.defaultLanguage)
    }
  },
  methods: {
    handleLanguageSelect(language) {
      this.$emit('update:modelValue', language.code)
    }
  }
}
</script>

<style lang="scss" scoped>
.language-select {
  &:not(.short) {
    width: 100%;
  }

  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    border: 1px solid $border-color;
    cursor: pointer;
    background: $white;

    &:hover {
      border: 1px solid rgba($black, 0.16);
    }

    &:active,
    &:focus,
    &:focus-within {
      background: rgba($black, 0.02);
      border: 1px solid $primary;
    }

    &-icon {
      height: 1.2rem;
    }
  }

  &-title {
    font-weight: 600;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
