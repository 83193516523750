<template>
  <UploadDownload :props-call="propsCall" :callback="callback">
    <template #default="{ upload, download, provider }">
      <Dropdown
        v-if="upload"
        :items="[
          {
            text: 'Download',
            icon: 'download',
            callback: download
          },
          {
            text: `Upload to ${
              provider === 'google_drive' ? 'Google Drive' : 'OneDrive'
            }`,
            icon: 'upload',
            callback: upload
          }
        ]"
        :align-top="alignment === 'top'"
        @mouseenter.native="checkAlignment"
      >
        <template #trigger>
          <Tooltip
            text="Export"
            :position="alignment"
            :active="trigger !== 'button' && !disabled"
          >
            <Button
              :text="trigger === 'button' ? 'Export' : ''"
              :icon="trigger === 'button' ? 'chevron-down-medium' : 'download'"
              :type="buttonType || (trigger === 'button' ? 'primary' : 'white')"
              :disabled="disabled"
              :loading="loading"
              :size="buttonSize"
              @mouseenter.native="checkAlignment"
            />
          </Tooltip>
        </template>
      </Dropdown>
      <Tooltip
        v-else
        text="Download"
        :position="alignment"
        :active="trigger !== 'button' && !disabled"
      >
        <div @mouseenter="checkAlignment">
          <Button
            :text="trigger === 'button' ? 'Download' : ''"
            :icon="trigger === 'button' ? '' : 'download'"
            :type="buttonType || (trigger === 'button' ? 'primary' : 'white')"
            :icon-left="true"
            :disabled="disabled"
            :loading="loading"
            :size="buttonSize"
            @click="download"
          />
        </div>
      </Tooltip>
    </template>
  </UploadDownload>
</template>

<script>
export default {
  name: 'UploadDownloadDropdown',
  props: {
    propsCall: {
      type: Function,
      required: true
    },
    callback: {
      type: Function,
      default: () => {}
    },
    trigger: {
      type: String,
      default: 'icon',
      validator: (value) => ['icon', 'button'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    alignmentThreshold: {
      type: Number,
      default: 0.5
    },
    buttonType: {
      type: String,
      default: ''
    },
    buttonSize: {
      type: String,
      default: 's'
    }
  },
  data: () => ({
    alignment: 'top'
  }),
  methods: {
    checkAlignment(e) {
      this.alignment = e.clientY < window.innerHeight * this.alignmentThreshold
        ? 'bottom'
        : 'top'
    }
  }
}
</script>

<style scoped lang="scss"></style>
