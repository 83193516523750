<template>
  <div class="app-uman" :class="{ 'has-sidebar': showSidebar }">
    <GlobalSidebar v-if="showSidebar" />
    <div class="app-uman-content">
      <GlobalHeader />
      <router-view v-if="showAppContent" />
    </div>
    <Toast ref="umantoast" />
    <Snackbar ref="umansnackbar" />
  </div>
</template>

<script setup>
import GlobalHeader from '@c/core/GlobalHeader.vue'
import GlobalSidebar from '@c/core/GlobalSidebar.vue'

import moment from 'moment'
import Console from '@/core/console'

import { useRoute } from 'vue-router'
import { useIntercom } from './core/intercom'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'
import { ref, provide, computed } from 'vue'
import { useTryCatch } from './core/trycatch'

const auth = useAuthStore()

const umantoast = ref(null)
const toast = {
  danger: (title, description, time) =>
    umantoast.value.danger(title, description, time),
  success: (title, description, time) =>
    umantoast.value.success(title, description, time),
  info: (title, description, time) =>
    umantoast.value.info(title, description, time),
  error(e, context) {
    if ((e.status === 400 || e.status === 404) && !!e.data.detail)
      umantoast.value.danger(`Error ${context}`, e.data.detail)
    else
      umantoast.value.danger(
        `Error ${context}`,
        `Something went wrong while ${context}. Please try again later or contact support.`
      )
  }
}
provide('$toast', toast)

const umansnackbar = ref(null)
const snackbar = {
  show: (args) => umansnackbar.value.show(args)
}

provide('$snackbar', snackbar)

provide('$moment', moment)

provide('$console', Console)

const { trycatch } = useTryCatch(console, toast)

provide('trycatch', trycatch)

const route = useRoute()

const user = useUserStore()
const workspace = useWorkspaceStore()

const showAppContent = computed(() => {
  return (
    !!route.name &&
    (!route.meta.authentication ||
      (route.name === 'integrationOauth'
        ? auth.authenticated
        : auth.authenticated &&
          !!workspace.currentWorkspace &&
          workspace.currentWorkspace.uuid === route.params.workspace_id &&
          !!user.currentWorkspaceMember))
  )
})

const showSidebar = computed(() => {
  return (
    showAppContent.value &&
    route.meta.authentication &&
    !['integrationOauth', 'onboarding'].includes(route.name)
  )
})

if (import.meta.env.PROD) useIntercom()
</script>

<style lang="scss">
@use './index.scss';

html,
body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  position: relative;
  overflow: hidden;
}

* {
  font-family: 'Nunito Sans', sans-serif;
}

a {
  color: $dark;
  text-decoration: none;
}

.app-uman {
  display: grid;
  grid-template-columns: 1fr;
  background: $white;

  &.has-sidebar {
    grid-template-columns: auto 1fr;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
