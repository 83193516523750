const logoURL = (logo, extension = 'svg') => {
  return new URL(`/src/assets/logos/${logo}.${extension}`, import.meta.url).href
}

const defaultInstructionNoAPIKey = (folderName, fileName) => {
  return `You’ll need to authenticate your account and give us permissions before you can start importing ${fileName}. The only thing we need to be able to access is your ${folderName}s and ${fileName}s.`
}

const FOLDER_NAME = 'folder'
const FILE_NAME = 'file'

export const INTEGRATION_PROVIDER = {
  gdrive: 'google_drive',
  sharepoint: 'sharepoint',
  onedrive: 'onedrive',
  hubspot: 'hubspot',
  linkedin: 'linkedin',
  gcalendar: 'google_calendar',
  outlookcalendar: 'microsoft_outlook_calendar'
}

export const filestorageIntegrations = {
  [INTEGRATION_PROVIDER.gdrive]: {
    image: logoURL('google_drive', 'png'),
    grayImage: logoURL('google_drive_gray'),
    title: 'Google Drive',
    description:
      'You’ll need to authenticate your Google Drive account give us permissions before you can start importing files. The only thing we need to be able to access is your folders and files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL: 'https://accounts.google.com/o/oauth2/v2/auth',
    oauthQueryParams: {
      response_type: 'code',
      redirect_uri: `${import.meta.env.VITE_WEBAPP_URL}/integrations/authenticate/google_drive`,
      client_id:
        '387164199820-pblevae6f5u2nr71jks6bp1ffbo7cpn7.apps.googleusercontent.com',
      scope: 'openid https://www.googleapis.com/auth/drive.readonly',
      access_type: 'offline',
      prompt: 'consent'
    },
    active: true,
    key: INTEGRATION_PROVIDER.gdrive,
    setup: {
      type: 'oauth',
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [INTEGRATION_PROVIDER.sharepoint]: {
    image: logoURL('sharepoint'),
    title: 'Sharepoint',
    description:
      'You’ll need to authenticate your Sharepoint account give us permissions before you can start importing files. The only thing we need to be able to access is your folders and files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL:
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
    oauthQueryParams: {
      client_id: 'c4ea554d-5f70-4a84-9f82-46d76173bd20',
      scope:
        'offline_access openid https://graph.microsoft.com/User.Read.All https://graph.microsoft.com/Sites.Read.All https://graph.microsoft.com/Files.Read.All https://graph.microsoft.com/Group.Read.All',
      redirect_uri: `${import.meta.env.VITE_WEBAPP_URL}/integrations/authenticate/sharepoint`,
      response_type: 'code'
    },
    active: true,
    key: INTEGRATION_PROVIDER.sharepoint,
    setup: {
      type: 'oauth',
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#1A9BA1'
  }
}

export const crmIntegrations = {
  [INTEGRATION_PROVIDER.hubspot]: {
    image: logoURL('hubspot', 'png'),
    title: 'Hubspot',
    description:
      'You’ll need to authenticate your Hubspot account give us permissions before you can start synchronizing. The only thing we need to be able to access is your companies.',
    syncObjectName: 'folder',
    folderName: 'industry',
    fileName: 'company',
    oauthBaseURL: 'https://app.hubspot.com/oauth/authorize',
    oauthQueryParams: {
      client_id: '41a8db24-28d4-4cd3-ba90-9876b5457f74',
      redirect_uri: `${import.meta.env.VITE_WEBAPP_URL}/integrations/authenticate/hubspot`,
      scope: 'crm.objects.companies.read%20crm.objects.deals.read'
    },
    active: true,
    key: INTEGRATION_PROVIDER.hubspot,
    setup: {
      type: 'oauth',
      instructions: defaultInstructionNoAPIKey('industries', 'companies')
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [INTEGRATION_PROVIDER.linkedin]: {
    image: logoURL('linkedin'),
    title: 'LinkedIn',
    description:
      'You’ll need to authenticate your LinkedIn account before you can start getting warm introduction recommendations.',
    syncObjectName: 'people',
    folderName: 'people',
    fileName: 'person',
    active: true,
    key: INTEGRATION_PROVIDER.linkedin,
    setup: {
      type: 'cookie',
      instructions: [
        'Open your browser and go to LinkedIn.',
        "Sign in if you're not already logged in.",
        "Right-click anywhere on the LinkedIn page and choose 'Inspect' or press Ctrl+Shift+I (Windows) or Cmd+Option+I (Mac).",
        "In the developer tools, click on the 'Application' tab.",
        "Under 'Storage' on the left, select 'Cookies' and then the LinkedIn URL.",
        "Look for the 'li_at' cookie in the list.",
        "Double-click the 'Value' field next to 'li_at' and copy it entirely."
      ]
    },
    multilayered: false,
    defaultColor: '#1382BD'
  }
}

export const userCalendarIntegrations = {
  [INTEGRATION_PROVIDER.gcalendar]: {
    image: logoURL('google_calendar'),
    title: 'Google Calendar',
    description:
      'You’ll need to authenticate your Google Calendar account give us permissions before you can start importing calendars.',
    syncObjectName: 'calendar',
    folderName: 'calendar',
    fileName: 'calendar',
    oauthBaseURL: 'https://accounts.google.com/o/oauth2/v2/auth',
    oauthQueryParams: {
      response_type: 'code',
      redirect_uri: `${import.meta.env.VITE_WEBAPP_URL}/integrations/authenticate/google_calendar`,
      client_id:
        '387164199820-3f1i5cq5avhvcev844n9cc3s78q6h0ti.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/calendar.readonly openid',
      access_type: 'offline',
      prompt: 'consent'
    },
    active: true,
    key: INTEGRATION_PROVIDER.gcalendar,
    setup: {
      type: 'oauth',
      instructions: `You'll need to authenticate your account and give us permissions before you can start importing calendars.`
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [INTEGRATION_PROVIDER.outlookcalendar]: {
    image: logoURL('outlook_calendar'),
    title: 'Outlook Calendar',
    description:
      'You’ll need to authenticate your Outlook Calendar account to give us permissions before you can start importing calendars.',
    syncObjectName: 'calendar',
    folderName: 'calendar',
    fileName: 'calendar',
    oauthBaseURL:
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
    oauthQueryParams: {
      client_id: '8c4643f2-7d60-4732-ae92-3497c6fcbbf6',
      scope:
        'offline_access openid https://graph.microsoft.com/Calendars.ReadBasic',
      redirect_uri: `${import.meta.env.VITE_WEBAPP_URL}/integrations/authenticate/microsoft_outlook_calendar`,
      response_type: 'code'
    },
    active: true,
    key: INTEGRATION_PROVIDER.outlookcalendar,
    setup: {
      type: 'oauth',
      instructions: `You'll need to authenticate your account and give us permissions before you can start importing calendars.`
    },
    multilayered: true,
    defaultColor: '#0459A7'
  }
}

export const userFileStorageIntegrations = {
  [INTEGRATION_PROVIDER.gdrive]: {
    image: logoURL('google_drive', 'png'),
    grayImage: logoURL('google_drive_gray'),
    title: 'Google Drive',
    description:
      'You’ll need to authenticate your Google Drive account give us permissions before you can start importing files. The only thing we need to be able to access is your folders and files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL: 'https://accounts.google.com/o/oauth2/v2/auth',
    oauthQueryParams: {
      response_type: 'code',
      redirect_uri: `${import.meta.env.VITE_WEBAPP_URL}/integrations/authenticate/google_drive`,
      client_id:
        '387164199820-pblevae6f5u2nr71jks6bp1ffbo7cpn7.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/drive openid',
      access_type: 'offline',
      prompt: 'consent'
    },
    active: true,
    key: INTEGRATION_PROVIDER.gdrive,
    setup: {
      type: 'oauth',
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [INTEGRATION_PROVIDER.onedrive]: {
    image: logoURL('onedrive'),
    title: 'OneDrive',
    description:
      'You’ll need to authenticate your Microsoft account to give us permissions before you can start uploading files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL:
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
    oauthQueryParams: {
      client_id: '2098289a-65c9-4696-848b-725b08f16452',
      scope:
        'offline_access openid profile email https://graph.microsoft.com/Files.ReadWrite https://graph.microsoft.com/Files.Read.All',
      redirect_uri: `${import.meta.env.VITE_WEBAPP_URL}/integrations/authenticate/onedrive`,
      response_type: 'code'
    },
    active: true,
    key: INTEGRATION_PROVIDER.onedrive,
    setup: {
      type: 'oauth',
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#1A9BA1'
  }
}

export const allIntegrations = {
  ...filestorageIntegrations,
  ...crmIntegrations
}

export function buildOauthUrl({ base_url, query }) {
  let url = new URL(base_url)
  Object.keys(query).forEach((key) => {
    url.searchParams.append(key, query[key])
  })
  return url.toString()
}

export function getNonce({ length = 20 }) {
  const x = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let y = ''
  for (let idx = 0; idx < length; idx++) {
    y += x[Math.floor(Math.random() * x.length)]
  }
  return y
}
