import { useAuthStore } from '@/stores/auth'
import Console from '@/core/console'
import Axios from 'axios'
import * as Sentry from '@sentry/browser'

export function getClient(
  base_url,
  multipart = false,
  can_refresh = true,
  api_url = undefined
) {
  const options = {
    baseURL: (api_url || import.meta.env.VITE_BACKEND_URL) + base_url,
    headers: {
      Accept: 'application/json; version=1;'
    },
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 304
  }

  let client = Axios.create(options)

  client.interceptors.request.use(
    (config) => {
      config.headers['uman-Client'] = 'webapp'
      if (multipart) {
        config.headers = {
          'Content-Type': 'multipart/form-data'
        }
        config.transformRequest = [
          function (data) {
            // Do whatever you want to transform the data
            const form_data = new FormData()
            for (const key in data) {
              form_data.append(key, data[key])
            }
            return form_data
          }
        ]
      }

      const auth = useAuthStore()

      if (auth.accessToken != null && config.url !== '/token/refresh/') {
        config.headers['Authorization'] = `Bearer ${auth.accessToken}`
      }
      return config
    },
    async (error) => {
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.code && error.code === 'ECONNABORTED') {
        throw new Error('Axios_timeout')
      }
      const response = error.response
      const auth = useAuthStore()
      if (response && response.data) {
        // Refresh tokens and try again if status code was 401
        if (response.status === 401 && can_refresh) {
          try {
            Console.debug('got 401 error for', response, 'refreshing again')
            if (!response.config.url.includes('/refresh')) {
              Console.debug('url did not include "/refresh"')
              Sentry.captureException(error)
              // await store.dispatch('logout')
              Console.debug('refreshing before retry')
              await auth.refresh()
              Console.debug('retrying')
              return await getClient(
                base_url,
                multipart,
                false,
                api_url
              ).request(response.config)
            } else {
              auth.logout()
            }
          } catch (err) {
            Console.debug('401 error captured', err)
            // Log out if the second status code was 401
            if (err.response && err.response.status === 401) {
              auth.logout()
            }
          }
        }
        return Promise.reject(response)
      }
    }
  )

  return client
}

export function getResponseData(response) {
  if (response.data instanceof Object && 'results' in response.data) {
    return response.data.results
  }

  return response.data
}

export function getResponseCount(response) {
  if (response.data instanceof Object && 'count' in response.data) {
    return response.data.count
  }

  return NaN
}

export class Paginator {
  /**
   * Object for handling pagination via its pages() method.
   * Can also return all pages (up to maxPage) via its all() method.
   * Paginated key is when there is more information returned than the amount that is paginated.
   * This key will be used to index data that has the paginated behaviour.
   * The nextPageFetch is a function that takes in an url and fetches this with the required data.
   * This can be used if the pagination is over a post endpoint and the POST data needs to be sent as well.
   */
  constructor(
    client,
    promise,
    callback,
    paginatedKey = '',
    nextPageFetch = undefined
  ) {
    this.client = client
    this.promise = promise
    this.callback = callback
    this.paginatedKey = paginatedKey
    this._count = NaN
    this.nextPageFetch = nextPageFetch

    this.cachedPages = []
  }

  transformData(data) {
    return this.paginatedKey ? data[this.paginatedKey] : data
  }

  async count() {
    if (!isNaN(this._count)) {
      return this._count
    }
    const pages = await this.pages()
    await pages.next()
    return this._count
  }

  async *pages() {
    /**
     * Generator giving one page of results per call to next().
     * When done is true, no data will be included
     */

    let page = 1
    let res = null
    const hasNext = (res) => 'next' in res.data && res.data.next != null

    // check if initial request has already been cached
    if (this.cachedPages.length > 0) {
      res = this.cachedPages[0]
    } else {
      res = await this.promise
      this.cachedPages[0] = res
    }
    this._count = getResponseCount(res)
    const actualResult = this.transformData(getResponseData(res))
    const rawData = getResponseData(res)
    if (this.callback) {
      this.callback(actualResult, rawData)
    }
    yield {
      data: actualResult,
      rawData: res.data.results,
      next: hasNext(res)
    }
    page++

    while (hasNext(res)) {
      // check if current page has been cached, else execute
      if (this.cachedPages.length >= page) {
        res = this.cachedPages[page - 1]
      } else {
        const url = res.data['next']
        if (this.nextPageFetch) {
          res = await this.nextPageFetch(url)
        } else {
          res = await this.client.get(url)
        }
        this.cachedPages[page - 1] = res
      }

      const actualResult = this.transformData(getResponseData(res))
      const rawData = getResponseData(res)

      if (this.callback) {
        this.callback(actualResult, rawData)
      }
      yield {
        data: actualResult,
        rawData,
        next: hasNext(res)
      }
      page++
    }
  }

  async all(maxPage = 999) {
    const pages = await this.pages()
    let data = null

    for (let i = 0; i < maxPage; i++) {
      const { value, done } = await pages.next()

      if (done) {
        break
      }

      const resData = value.data
      // data in Array form
      if (resData instanceof Array) {
        if (data == null) {
          data = []
        }
        data.push(...resData)
        // data in Object form
      } else if (resData instanceof Object) {
        if (data == null) {
          data = {}
        }
        data = { ...data, ...resData }
      }
    }

    return data
  }
}

export async function* generatePaginatedResponse(
  client,
  promise,
  callback = null
) {
  /**
   * Generator giving one page of results per call to next().
   * When done is true, no data will be included
   */

  const hasNext = (res) => 'next' in res.data && res.data.next != null

  let res = await promise

  if (callback) {
    callback(getResponseData(res))
  }

  yield {
    data: getResponseData(res),
    next: hasNext(res)
  }

  const newConfig = { ...res.config }
  if (!('params' in newConfig) || newConfig['params'] == undefined) {
    newConfig['params'] = {}
  }

  while (hasNext(res)) {
    const url = res.data['next']
    res = await client.get(url)

    if (callback) {
      callback(getResponseData(res))
    }

    yield {
      data: getResponseData(res),
      next: hasNext(res)
    }
  }
}

export async function getPaginatedResponse(client, promise, max_page = 999) {
  /**
   * Wrapper around generatePaginatedResponse.
   * Gets up to <max_page> pages of response data and returns it as an array or dictionary
   */

  const pages = generatePaginatedResponse(client, promise)
  let data = null

  for (let i = 0; i < max_page; i++) {
    const { value, done } = await pages.next()

    if (done) {
      break
    }

    const resData = value.data
    if (resData instanceof Array) {
      if (data == null) {
        data = []
      }
      data.push(...resData)
    } else if (resData instanceof Object) {
      if (data == null) {
        data = {}
      }
      data = { ...data, ...resData }
    }
  }

  return data
}

export async function getPaginatedData(
  client,
  url,
  config = {},
  max_page = 999
) {
  const promise = client.get(url, config)
  return await getPaginatedResponse(client, promise, max_page)
}
