<template>
  <div v-if="!!t" class="snackbar">
    <HTMLRenderer :value="t" class="snackbar-content" />
    <Button v-if="cText" :text="cText" type="white" @click="onC" />
    <Button :text="aText" @click="onA" />
  </div>
</template>

<script setup>
import { nextTick, ref } from 'vue'

const t = ref('')
const onA = ref(null)
const aText = ref('')
const onC = ref(null)
const cText = ref('')
const timeout = ref(5000)
const timer = ref(null)

async function show({
  text,
  actionText = 'Confirm',
  onAction = () => {},
  cancelText = '',
  onCancel = () => {},
  time = 0
}) {
  hide()
  await nextTick()
  t.value = text
  aText.value = actionText
  onA.value = () => {
    onAction()
    hide()
  }
  cText.value = cancelText
  onC.value = () => {
    onCancel()
    hide()
  }
  if (time) {
    timeout.value = time
    timer.value = setTimeout(hide, time)
  }
}

function hide() {
  t.value = ''
  aText.value = ''
  onA.value = null
  cText.value = ''
  onC.value = null
  clearTimeout(timer.value)
  timer.value = null
}

defineExpose({
  show
})
</script>

<style lang="scss" scoped>
.snackbar {
  position: fixed;
  bottom: 50px;
  right: 50%;
  transform: translateX(50%);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  background: $white;
  padding: 0.75rem 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;

  &-content {
    white-space: nowrap;
    padding-right: 0.75rem;
    font-weight: 600;
  }
}

@keyframes slide {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
