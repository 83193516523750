// Feedback events

export const eventPage = {
  home: 'home',
  search_results: 'search_results'
}

export const eventTab = {
  all: 'all',
  document: 'document',
  presentation: 'presentation',
  people: 'people',
  slides: 'slides',
  video: 'video',
  sheet: 'sheet'
}

export const eventType = {
  file_click_through: 'file_click_through',
  folder_click_through: 'folder_click_through',
  insert: 'insert',
  collect: 'collect',
  inspect: 'inspect',
  view: 'view',
  list: 'list'
}

export const eventContext = {
  subresource: 'subresource',
  resource: 'resource'
}

// Search enrichment

export const searchSourcePage = {
  home: 'home',
  search_results: 'search_results'
}

export const searchTrigger = {
  search: 'search',
  insight: 'insight',
  knowledgepanel_insight: 'knowledgepanel_insight',
  filter_suggestion: 'filter_suggestion',
  filter: 'filter',
  filter_disable: 'filter_disable',
  similar_query: 'similar_query',
  tab_change: 'tab_change',
  slideblock: 'slideblock',
  slide_change: 'slide_change',
  clear: 'clear',
  scroll: 'scroll'
}

export const searchDetail = {
  search: {
    input: 'input',
    suggestion_history: 'suggestion_history',
    suggestion_autocomplete: 'suggestion_autocomplete',
    url: 'url'
  },
  insight: {
    for_you: 'for you',
    trending: 'trending',
    all_time: 'all time'
  },
  knowledgepanel_insight: {
    deeper: 'deeper',
    broader: 'broader',
    similar: 'similar'
  },
  filter_suggestion: {
    latest: 'latest',
    popular: 'popular',
    my_content: 'my_content',
    bookmarks: 'bookmarks',
    topic: 'topic',
    industry: 'industry',
    organisation: 'organisation',
    content_class: 'content_class',
    label: 'label'
  }
}
