<template>
  <div ref="workspacelist" class="select-workspaces">
    <div class="select-workspaces-search">
      <TextInput
        v-model="query"
        left-icon="search"
        placeholder="Find workspaces"
      />
    </div>
    <div class="select-workspaces-list">
      <router-link
        v-for="ws in filteredWorkspaces"
        :key="ws.uuid"
        :to="workspaceRoute(ws)"
        class="select-workspaces-list-workspace-link"
      >
        <p class="select-workspaces-list-workspace" @click="hideWorkspaceList">
          <img
            :src="ws.image || globeIcon"
            alt=""
            class="select-workspaces-list-workspace-icon"
            :class="{ 'has-image': !!ws.image }"
          />
          {{ ws.name }}
        </p>
      </router-link>
    </div>
    <div class="select-workspaces-overview" @click="createWorkspace">
      <Icon name="plus-medium" class="select-workspaces-overview-icon" />
      Create new workspace
    </div>
  </div>
</template>

<script>
import { useWorkspaceStore } from '@/stores/workspace'
import { iconURL } from '@/core/util'

export default {
  name: 'WorkspaceSelect',
  data: () => ({
    query: ''
  }),
  emits: ['hide', 'create'],
  computed: {
    globeIcon() {
      return iconURL('globe')
    },
    filteredWorkspaces() {
      const workspaceStore = useWorkspaceStore()
      return workspaceStore.workspaces.filter((ws) =>
        ws.name.toLowerCase().includes(this.query.toLowerCase())
      )
    }
  },
  mounted() {
    document.addEventListener('click', this.checkClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.checkClickOutside)
  },
  methods: {
    hideWorkspaceList() {
      this.$emit('hide')
    },
    workspaceRoute(ws) {
      return {
        name: 'home',
        params: { workspace_id: ws.uuid },
        query: {}
      }
    },
    checkClickOutside(e) {
      if (!this.$refs.workspacelist.contains(e.target)) this.$emit('hide')
    },
    createWorkspace() {
      this.$emit('create')
    }
  }
}
</script>

<style lang="scss" scoped>
.select-workspaces {
  position: absolute;
  top: 1rem;
  left: calc(100% + 0.5rem);
  width: min(25rem, 90vw);
  background: $white;
  border-radius: 4px;
  border: 1px solid $border-color;
  box-shadow: 0 0 8px rgba($black, 0.1);
  z-index: 10;

  &-list {
    display: flex;
    flex-flow: column nowrap;
    padding: 0.5rem 0;
    max-height: 50vh;
    overflow-y: auto;

    &-workspace {
      cursor: pointer;
      padding: 0.5rem 1rem;
      white-space: nowrap;
      transition: background 0.2s;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &:hover {
        background: $border-color;
      }

      &-link {
        width: 100%;
      }

      &-icon {
        height: 1.2rem;
        border-radius: 2px;

        &:not(.has-image) {
          opacity: 0.5;
        }
      }
    }
  }

  &-search {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid $border-color;
  }

  &-overview {
    cursor: pointer;
    padding: 0.75rem 1rem;
    white-space: nowrap;
    transition: background 0.2s;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    border-top: 1px solid $border-color;
    font-weight: 700;

    &:hover {
      background: $border-color;
    }

    &-icon {
      height: 1.2rem;
    }
  }
}
</style>
