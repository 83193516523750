import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useFeedbackStore = defineStore('feedback', () => {
  const lastSearchId = ref(undefined)
  const searchEnrichmentContext = ref({})

  function setLastSearchId(search_id) {
    lastSearchId.value = search_id
  }

  function setSearchEnrichmentContext(context) {
    searchEnrichmentContext.value = context
  }

  return {
    lastSearchId,
    searchEnrichmentContext,
    setLastSearchId,
    setSearchEnrichmentContext
  }
})
