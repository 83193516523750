<template>
  <p
    class="tag"
    :class="{
      [type]: true,
      [size]: true,
      rounded,
      overflowing,
      'icon-only': !text
    }"
  >
    <Icon
      v-if="icon && iconLeft"
      :name="icon"
      :fill="iconFill"
      :set-fill="setFill"
      class="tag-icon"
    />
    <slot name="left"></slot>
    <span v-if="text" class="tag-text">{{ text }}</span>
    <slot name="right"></slot>
    <Icon
      v-if="icon && !iconLeft"
      :name="icon"
      :fill="iconFill"
      class="tag-icon"
    />
  </p>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validator: (s) =>
        [
          'primary',
          'light',
          'light-solid',
          'white',
          'grey',
          'grey-solid',
          'black'
        ].includes(s)
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xxs', 'xs', 's', 'm', 'l'].includes(s)
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    filterIcon: {
      type: Boolean,
      default: true
    },
    overflowing: {
      type: Boolean,
      default: false
    },
    setFill: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    iconFill() {
      if (!this.icon) return ''
      if (!this.filterIcon) return 'dark'
      return {
        primary: 'white',
        light: 'primary',
        'light-solid': 'primary',
        white: 'grey',
        grey: 'dark',
        'grey-solid': 'grey-500',
        black: 'white'
      }[this.type]
    }
  }
}
</script>

<style scoped lang="scss">
.tag {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  outline: inherit;
  position: relative;
  height: fit-content;
  width: fit-content;

  &.rounded {
    border-radius: 999rem;
  }

  &:not(.rounded) {
    border-radius: 0.25rem;
  }

  &.overflowing {
    &,
    & > .tag-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-text {
    transition: all 0.2s ease;
    user-select: none;
    white-space: nowrap;
  }

  &.primary {
    background: $primary;
    color: $white;
    border: 1px solid transparent;

    & > .tag-text {
      color: $white;
    }
  }

  &.light {
    background: $primary-light;
    border: 1px solid $primary;

    & > .tag-text {
      color: $primary;
    }
  }

  &.light-solid {
    background: $primary-light;
    border: 1px solid transparent;

    & > .tag-text {
      color: $primary;
    }
  }

  &.white {
    background: $white;
    border: 1px solid $grey-400;

    & > .tag-text {
      color: $grey;
    }
  }

  &.grey {
    border: none;
    background: rgba($black, 6%);

    & > .tag-text {
      color: $dark;
    }
  }

  &.grey-solid {
    background: $grey-100;
    border: 1px solid transparent;

    & > .tag-text {
      color: $grey-500;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  &.black {
    background: rgba($black, 64%);
    border: 1px solid transparent;

    & > .tag-text {
      color: $white;
    }
  }

  &.icon-only {
    justify-content: center;
    padding: unset !important;

    &.xxs {
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
    }

    &.xs {
      width: 1.85rem;
      height: 1.85rem;
      min-width: 1.85rem;
      min-height: 1.85rem;
    }

    &.s {
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
    }

    &.m {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
    }

    &.l {
      width: 3.45rem;
      height: 3.45rem;
      min-width: 3.45rem;
      min-height: 3.45rem;
    }
  }

  &.xxs {
    padding: 0.15rem 0.5rem;

    & > .tag-text {
      font-size: 0.85rem;
    }

    & > .tag-icon {
      height: 0.9rem;
      width: 0.9rem;
    }
  }

  &.xs {
    padding: 0.35rem 0.85rem;

    & > .tag-text {
      font-size: 0.85rem;
    }

    & > .tag-icon {
      height: 1.1rem;
      width: 1.1rem;
    }
  }

  &.s {
    padding: 0.35rem 1.15rem;

    & > .tag-text {
      font-size: 1rem;
    }

    & > .tag-icon {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  &.m {
    padding: 0.8rem 1.45rem;

    & > .tag-text {
      font-size: 1rem;
    }

    & > .tag-icon {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  &.l {
    padding: 0.95rem 1.7rem;

    & > .tag-text {
      font-size: 1.15rem;
    }

    & > .tag-icon {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}
</style>
