export const mimetypes = {
  pdf: 'pdf',
  pptx: 'pptx',
  ppt: 'ppt',
  google_slides: 'google_slides',
  google_sheets: 'google_sheets',
  google_docs: 'google_docs',
  doc: 'doc',
  xls: 'xls',
  xlsx: 'xlsx',
  ods: 'ods',
  png: 'png',
  jpeg: 'jpeg',
  svg: 'svg',
  video: 'video'
}

export const typeToMimetype = {
  pdf: 'application/pdf',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ppt: 'application/vnd.ms-powerpoint',
  google_slides: 'application/vnd.google-apps.presentation',
  google_sheets: 'application/vnd.google-apps.spreadsheet',
  google_docs: 'application/vnd.google-apps.document',
  doc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  png: 'image/png',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  video: 'video/mp4'
}

export const mimetypeToType = {
  'application/pdf': mimetypes.pdf,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    mimetypes.pptx,
  'application/vnd.ms-powerpoint': mimetypes.ppt,
  'application/vnd.google-apps.presentation': mimetypes.google_slides,
  'application/vnd.google-apps.document': mimetypes.google_docs,
  'application/vnd.google-apps.spreadsheet': mimetypes.google_sheets,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    mimetypes.doc,
  'application/vnd.ms-excel': mimetypes.xls,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    mimetypes.xlsx,
  'application/vnd.oasis.opendocument.spreadsheet': mimetypes.ods,
  'image/png': mimetypes.png,
  'image/jpeg': mimetypes.jpeg,
  'image/svg+xml': mimetypes.svg,
  'video/mp4': mimetypes.video,
  'video/quicktime': mimetypes.video
}

export const mimetypeToTypeName = {
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint',
  'application/vnd.ms-powerpoint': 'PowerPoint',
  'application/vnd.google-apps.presentation': 'Google Slides',
  'application/vnd.google-apps.document': 'Google Docs',
  'application/vnd.google-apps.spreadsheet': 'Google Sheets',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word',
  'application/vnd.ms-excel': 'Excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'Excel',
  'application/pdf': 'PDF',
  'application/msword': 'Word',
  'image/png': 'Image',
  'image/svg+xml': 'Image',
  'image/jpeg': 'Image',
  'video/mp4': 'Video',
  'video/quicktime': 'Video'
}

export const downloadableMimetypes = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.google-apps.presentation'
]

export const subresourceUnits = {
  page: 'page',
  sheet: 'sheet',
  slide: 'slide'
}

export const typeToUnit = {
  pdf: subresourceUnits.page,
  pptx: subresourceUnits.slide,
  ppt: subresourceUnits.slide,
  google_slides: subresourceUnits.slide,
  google_sheets: subresourceUnits.sheet,
  google_docs: subresourceUnits.page,
  doc: subresourceUnits.page,
  xls: subresourceUnits.sheet,
  xlsx: subresourceUnits.sheet,
  ods: subresourceUnits.sheet
}
