import moment from 'moment'

export function formatDate(date) {
  const m = moment(date)
  return m.format('MMM D, YYYY')
}

export function formatDateWTime(date) {
  const m = moment(date)
  return m.format('D MMM YYYY, H:mm')
}

export function relativeTime(date) {
  const m = moment(date)
  return m.fromNow()
}
