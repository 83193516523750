import { getClient, getResponseData, Paginator } from '@/core/axios'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'

const client = getClient('/workspaces')

// WORKSPACE

export async function getWorkspaces() {
  const res = await client.get('/')
  const ws = getResponseData(res)
  const store = useWorkspaceStore()
  store.setWorkspaces(ws)
  return getResponseData(res)
}

export async function createWorkspace({ name, description }) {
  const res = await client.post('/', {
    name,
    description
  })
  const ws = getResponseData(res)
  const store = useWorkspaceStore()
  store.setWorkspaces([...store.workspaces, ws])
  return ws
}

export async function editWorkspace({ workspace_id, data }) {
  const res = await client.patch(`/${workspace_id}/`, data)
  const ws = getResponseData(res)
  const store = useWorkspaceStore()
  if (store.currentWorkspace?.uuid === workspace_id)
    store.editCurrentWorkspace(ws)
  return ws
}

export async function editWorkspaceImage({ workspace_id, image }) {
  let formData = null
  if (image instanceof File) {
    formData = new FormData()
    formData.append('image', image)
  }
  const res = await client.put(`/${workspace_id}/image/`, formData)
  const ws = getResponseData(res)
  const store = useWorkspaceStore()
  if (store.currentWorkspace?.uuid === workspace_id)
    store.editCurrentWorkspace(ws)
  return ws
}

// WORKSPACE MEMBER

export async function getCurrentWorkspaceMember({ workspace_id }) {
  const res = await client.get(`/${workspace_id}/members/me/`)
  return getResponseData(res)
}

export async function deleteWorkspaceMember({ workspace_id, member_id }) {
  const res = await client.delete(`/${workspace_id}/members/${member_id}/`)
  return getResponseData(res)
}

export async function patchWorkspaceMember({ workspace_id, member_id, data }) {
  const res = await client.patch(`/${workspace_id}/members/${member_id}/`, data)
  const store = useUserStore()
  const member = getResponseData(res)
  if (store.currentWorkspaceMember.uuid === member.uuid) {
    store.setCurrentWorkspaceMember(member)
  }
  return member
}

export async function getWorkspaceMember({ workspace_id, member_id, params }) {
  const res = await client.get(`/${workspace_id}/members/${member_id}/`, {
    params
  })
  const data = getResponseData(res)
  return data
}

export async function getWorkspaceMemberInsights({
  workspace_id,
  member_id,
  params
}) {
  const res = await client.get(
    `/${workspace_id}/members/${member_id}/insights/`,
    {
      params
    }
  )
  return getResponseData(res)
}

export async function getWorkspaceMembersPaginator({
  workspace_id,
  params,
  extended
}) {
  return new Paginator(
    client,
    client.get(`/${workspace_id}/members/`, {
      params: {
        page_size: 25,
        extended,
        ...params
      }
    })
  )
}

export async function getWorkspaceMembersPage({
  workspace_id,
  params,
  page_size,
  extended
}) {
  const res = await client.get(`/${workspace_id}/members/`, {
    params: {
      page_size: page_size || 15,
      extended,
      ...params
    }
  })
  const data = getResponseData(res)
  return data
}

export async function getInvitedWorkspaceMembers({ workspace_id }) {
  const res = await client.get(`/${workspace_id}/invites/`)
  return getResponseData(res)
}

export async function inviteWorkspaceMember({ workspace_id, email, role_ids }) {
  const res = await client.post(`/${workspace_id}/invites/`, {
    email,
    role_ids
  })
  return getResponseData(res)
}

export async function deleteWorkspaceInvite({ workspace_id, invite_id }) {
  const res = await client.delete(`/${workspace_id}/invites/${invite_id}/`)
  return getResponseData(res)
}

// PERMISSIONS

export async function getRoles({ workspace_id }) {
  const res = await client.get(`/${workspace_id}/roles/`)
  return getResponseData(res)
}

export async function setMemberRoles({ workspace_id, member_id, role_ids }) {
  const res = await client.patch(`/${workspace_id}/members/${member_id}/`, {
    role_ids
  })
  return getResponseData(res)
}

// TEAMS

export async function setMemberTeam({ workspace_id, member_id, team_id }) {
  const res = await client.patch(`/${workspace_id}/members/${member_id}/`, {
    team_id
  })
  return getResponseData(res)
}

// ANALYTICS

export async function getWorkspaceAnalytics({ workspace_id, export_filetype }) {
  const res = await client.get(`/${workspace_id}/analytics/`, {
    ...(export_filetype ? { params: { export: export_filetype } } : {})
  })
  return getResponseData(res)
}

export async function getWorkspaceInsights({ workspace_id, act_as }) {
  const res = await client.get(`/${workspace_id}/insights/`, {
    ...(act_as ? { params: { act_as } } : {})
  })
  return getResponseData(res)
}
