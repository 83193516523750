import { getClient, getResponseData, Paginator } from '@/core/axios'

const client = getClient('/workspaces')

export async function getOfferingCategories({ workspace_id, extended }) {
  const res = await client.get(`/${workspace_id}/offerings/categories/`, {
    ...(extended ? { params: { extended } } : {})
  })
  return getResponseData(res)
}

export async function searchOfferings({
  workspace_id,
  search,
  attribute_value_ids,
  ordering,
  owner_id,
  page_size,
  callback = () => {}
}) {
  const res = await client.get(`/${workspace_id}/offerings/`, {
    params: {
      search,
      attribute_value_ids: attribute_value_ids?.length
        ? attribute_value_ids.join(',')
        : null,
      ordering: ordering || '-date_updated',
      page_size: page_size || 100,
      owner_id
    }
  })
  callback(res)
  return getResponseData(res)
}

export async function searchOfferingsExtended({
  workspace_id,
  query,
  statuses,
  parent_ids,
  owner_ids,
  has_references,
  has_inspirations,
  page_size,
  export_filetype,
  ...attributes
}) {
  const res = await client.post(
    `/${workspace_id}/offerings/search/`,
    {
      ...(query ? { query } : {}),
      ...(statuses?.length ? { statuses } : {}),
      ...(parent_ids?.length ? { parent_ids } : {}),
      ...(owner_ids?.length ? { owner_ids } : {}),
      ...(has_references ? { has_references } : {}),
      ...(has_inspirations ? { has_inspirations } : {}),
      ...(Object.keys(attributes || {}).length
        ? {
            attribute_value_ids: Object.keys(attributes).reduce(
              (acc, curr) => [...acc, ...attributes[curr]],
              []
            )
          }
        : {})
    },
    {
      params: {
        page_size: page_size || 10,
        ...(export_filetype ? { export: export_filetype } : {})
      }
    }
  )
  return getResponseData(res)
}

export async function getSearchOfferingsExtendedPaginator({
  workspace_id,
  query,
  sort_by,
  statuses,
  parent_ids,
  owner_ids,
  has_references,
  has_inspirations,
  page_size,
  callback = () => {},
  ...attributes
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(sort_by ? { sort_by } : {}),
    ...(statuses?.length ? { statuses } : {}),
    ...(parent_ids?.length ? { parent_ids } : {}),
    ...(owner_ids?.length ? { owner_ids } : {}),
    ...(has_references ? { has_references } : {}),
    ...(has_inspirations ? { has_inspirations } : {}),
    ...(Object.keys(attributes || {}).length
      ? {
          attribute_value_ids: Object.keys(attributes).reduce(
            (acc, curr) => [...acc, ...attributes[curr]],
            []
          )
        }
      : {})
  }

  const res = await client.post(`/${workspace_id}/offerings/search/`, body, {
    params: {
      page_size: page_size || 10
    }
  })
  callback(res)
  return new Paginator(
    client,
    res,
    () => {},
    '',
    (url) => client.post(url, body)
  )
}

export async function createOfferingCategory({
  workspace_id,
  name,
  description
}) {
  const res = await client.post(`/${workspace_id}/offerings/categories/`, {
    name,
    description
  })
  return getResponseData(res)
}

export async function editOfferingCategory({
  workspace_id,
  category_id,
  name,
  description
}) {
  const res = await client.patch(
    `/${workspace_id}/offerings/categories/${category_id}/`,
    {
      name,
      description
    }
  )
  return getResponseData(res)
}

export async function deleteOfferingCategory({ workspace_id, category_id }) {
  const res = await client.delete(
    `/${workspace_id}/offerings/categories/${category_id}/`
  )
  return getResponseData(res)
}

export async function setOfferingCategoryPosition({
  workspace_id,
  category_id,
  position
}) {
  const res = await client.patch(
    `/${workspace_id}/offerings/categories/${category_id}/`,
    { position }
  )
  return getResponseData(res)
}

export async function getOfferingInfo({ workspace_id, ori_id, act_as }) {
  const res = await client.get(`/${workspace_id}/offerings/${ori_id}/`, {
    params: { act_as }
  })
  return getResponseData(res)
}

export async function getOfferings({
  workspace_id,
  act_as,
  parent_id = null,
  category_id = null,
  extended = false
}) {
  const res = await client.get(`/${workspace_id}/offerings/`, {
    params: {
      ...(act_as ? { act_as } : {}),
      ...(parent_id || parent_id === 'null' ? { parent_id } : {}),
      ...(category_id ? { category_id } : {}),
      ...(extended ? { extended } : {})
    }
  })
  return getResponseData(res)
}

export async function createOffering({
  workspace_id,
  name,
  category_id,
  parent_id,
  owner_ids,
  reference_ids,
  inspiration_ids,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/offerings/`,
    {
      name,
      category_id,
      owner_ids,
      reference_ids,
      inspiration_ids,
      ...(parent_id ? { parent_id } : {})
    },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function finishOfferingDraft({
  workspace_id,
  ori_id,
  draft,
  act_as
}) {
  const res = await client.patch(
    `/${workspace_id}/offerings/${ori_id}/`,
    { draft },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function editOffering({
  workspace_id,
  ori_id,
  name,
  owner_ids,
  category_id,
  reference_ids,
  inspiration_ids,
  webpage_urls,
  parent_id
}) {
  const res = await client.patch(`/${workspace_id}/offerings/${ori_id}/`, {
    name,
    owner_ids,
    category_id,
    reference_ids,
    inspiration_ids,
    webpage_urls,
    parent_id
  })
  return getResponseData(res)
}

export async function generateOfferingImage({ workspace_id, ori_id, amount }) {
  const res = await client.post(
    `/${workspace_id}/offerings/${ori_id}/image/generate/`,
    {},
    { params: { amount } }
  )
  return getResponseData(res)
}

export async function setOfferingImage({ workspace_id, ori_id, image }) {
  let formData = null
  if (image instanceof File) {
    formData = new FormData()
    formData.append('image', image)
  }
  const res = await client.put(
    `/${workspace_id}/offerings/${ori_id}/image/`,
    formData
  )
  return getResponseData(res)
}

export async function setOfferingStatus({ workspace_id, ori_id, status }) {
  const res = await client.patch(`/${workspace_id}/offerings/${ori_id}/`, {
    status
  })
  return getResponseData(res)
}

export async function deleteOffering({ workspace_id, ori_id }) {
  const res = await client.delete(`/${workspace_id}/offerings/${ori_id}/`)
  return getResponseData(res)
}

export async function setOfferingPosition({ workspace_id, ori_id, position }) {
  const res = await client.patch(`/${workspace_id}/offerings/${ori_id}/`, {
    position
  })
  return getResponseData(res)
}

export async function editOfferingResourceLink({
  workspace_id,
  ori_id,
  resource_id,
  attribute_value_ids,
  position,
  act_as
}) {
  const res = await client.put(
    `/${workspace_id}/offerings/${ori_id}/resources/${resource_id}/`,
    {
      ...(attribute_value_ids ? { attribute_value_ids } : {}),
      ...(position ? { position } : {})
    },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function regenerateOfferingResource({
  workspace_id,
  ori_id,
  template_id,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/offerings/${ori_id}/templates/${template_id}/regenerate/`,
    {},
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function unlinkResourceFromOffering({
  workspace_id,
  ori_id,
  resource_id
}) {
  const res = await client.delete(
    `/${workspace_id}/offerings/${ori_id}/resources/${resource_id}/`
  )
  return getResponseData(res)
}

export async function getOfferingResourceUploadProps({
  workspace_id,
  ori_id,
  name,
  mimetype,
  content_length,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/offerings/${ori_id}/assets/upload/`,
    { name, mimetype, content_length },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function processOfferingResourceUpload({
  workspace_id,
  ori_id,
  state,
  integration_file_id
}) {
  const res = await client.post(
    `/${workspace_id}/offerings/${ori_id}/assets/process/`,
    { state, integration_file_id }
  )
  return getResponseData(res)
}

export async function editOfferingAttributes({
  workspace_id,
  ori_id,
  attribute_value_ids
}) {
  const res = await client.patch(`/${workspace_id}/offerings/${ori_id}/`, {
    attribute_value_ids
  })
  return getResponseData(res)
}
