import { getClient, getResponseData } from '@/core/axios'
import { useTeamStore } from '@/stores/teams'

const client = getClient('/workspaces')

export async function getTeams({ workspace_id }) {
  let res = await client.get(`/${workspace_id}/teams/`)
  res = getResponseData(res)
  const store = useTeamStore()
  store.setTeams(res)
  return res
}

export async function createTeam({ workspace_id, name }) {
  const res = await client.post(`/${workspace_id}/teams/`, {
    name
  })
  const team = getResponseData(res)
  const store = useTeamStore()
  store.addTeam(team)
  return team
}

export async function editTeam({ workspace_id, team_id, name }) {
  const res = await client.patch(`/${workspace_id}/teams/${team_id}/`, {
    name
  })
  const team = getResponseData(res)
  const store = useTeamStore()
  store.editTeam(team)
  return team
}

export async function removeTeam({ workspace_id, team_id }) {
  const res = await client.delete(`/${workspace_id}/teams/${team_id}/`)
  const team = getResponseData(res)
  const store = useTeamStore()
  store.removeTeam({ uuid: team_id })
  return team
}

export async function setORITeam({
  workspace_id,
  ori_type,
  ori_id,
  team_id,
  is_exclusion
}) {
  const res = await client.put(
    `/${workspace_id}/${ori_type}/${ori_id}/teams/${team_id}/`,
    {
      is_exclusion
    }
  )
  return getResponseData(res)
}

export async function removeORITeam({
  workspace_id,
  ori_type,
  ori_id,
  team_id
}) {
  const res = await client.delete(
    `/${workspace_id}/${ori_type}/${ori_id}/teams/${team_id}/`
  )
  return getResponseData(res)
}
