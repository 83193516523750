<template>
  <div class="search" :class="{ expanded: query && !blurred }">
    <div ref="searchinputcontainer">
      <TextInput
        v-model="query"
        :key="inputKey"
        ref="searchinput"
        :placeholder="placeholder"
        left-icon="search"
        class="search-input"
        :button-icon="query ? 'close' : ''"
        :submit-with-button="false"
        :title="title"
        :focused="!!query"
        :disabled="disabled"
        @button-click="query = ''"
        @focus="focusSearchInput"
        @blur="() => (searchInputFocused = false)"
      />
    </div>
    <div
      v-if="query && !blurred"
      class="search-suggest"
      :style="calculatedPosition"
    >
      <div
        v-if="!searchResultsLoading && searchResults.length"
        class="search-suggest-items"
      >
        <div
          v-for="(item, idx) in searchResults"
          :key="item.name"
          class="search-suggest-item"
          :class="{
            focused: idx === searchSelectionFocused
          }"
          @click="(e) => handleResultSelect(item, e)"
        >
          <slot
            name="item"
            :item="item"
            :index="idx"
            :select="handleResultSelect"
          >
            <img
              v-if="image(item)"
              :src="image(item)"
              alt=""
              class="search-suggest-item-image"
            />
            <div class="search-suggest-item-info">
              <div class="search-suggest-item-name">
                {{ name(item) }}
              </div>
              <p class="search-suggest-item-summary">
                {{ summary(item) }}
              </p>
            </div>
            <div v-if="showCollect" class="search-suggest-item-collect">
              <div @click.prevent>
                <Button
                  icon="plus-medium"
                  type="white"
                  @click="() => handleResultSelect(item)"
                />
              </div>
            </div>
          </slot>
        </div>
      </div>
      <div v-else-if="searchResultsLoading" class="search-suggest-status">
        <Loader size="s" color="grey" />
        {{ loadingText }}
      </div>
      <div
        v-else-if="!searchResults || !searchResults.length"
        class="search-suggest-status"
      >
        <Icon
          name="search_frame"
          fill="grey"
          class="search-suggest-status-icon"
        />
        No results found
      </div>
      <div
        v-if="showLast"
        class="search-suggest-item"
        @click="$emit('lastClicked')"
      >
        <slot name="last" :query="query" />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'InlineSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Search...'
    },
    searchFunction: {
      type: Function,
      required: true
    },
    queryValidator: {
      type: Function,
      default: () => true
    },
    propMapping: {
      type: Object,
      default: () => ({
        image: 'image',
        name: 'name',
        summary: 'summary'
      })
    },
    showCollect: {
      type: Boolean,
      default: false
    },
    showLast: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    debounceMs: {
      type: Number,
      default: 500
    },
    loadingText: {
      type: String,
      default: 'Loading...'
    },
    refreshInputs: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['query', 'submit', 'lastClicked'],
  inject: ['$console', '$toast', 'trycatch'],
  data: () => ({
    query: '',
    searchResults: [],
    searchResultsLoading: false,
    searchInputFocused: false,
    searchSelectionFocused: -1,
    calculatedPosition: {},
    blurred: false,
    inputKey: 0
  }),
  watch: {
    query(newVal) {
      if (newVal && this.queryValidator(newVal)) {
        this.searchResultsLoading = true
        this.search()
      } else {
        this.searchResults = []
      }
      if (this.emitQuery) {
        this.$emit('query', newVal)
      }
    },
    searchInputFocused(newVal) {
      if (newVal) {
        document.addEventListener('keydown', this.handleKeydown)
      } else {
        document.removeEventListener('keydown', this.handleKeydown)
      }
    },
    debounceMs(newVal) {
      this.search = debounce(this.performSearch, newVal)
    },
    refreshInputs: {
      handler(val) {
        if (val) this.inputKey++
      },
      immediate: true
    }
  },
  created() {
    this.search = debounce(this.performSearch, this.debounceMs)
  },
  methods: {
    close() {
      this.query = ''
      this.searchResults = []
    },
    image(item) {
      return (
        (this.propMapping?.image ? item[this.propMapping.image] : '') ||
        item.image ||
        ''
      )
    },
    name(item) {
      return (
        (this.propMapping?.name ? item[this.propMapping.name] : '') ||
        item.name ||
        ''
      )
    },
    summary(item) {
      return (
        (this.propMapping?.summary ? item[this.propMapping.summary] : '') ||
        item.summary ||
        ''
      )
    },
    focusSearchInput() {
      this.searchInputFocused = true
      this.blurred = false
      this.calculatePosition()
    },
    calculatePosition() {
      const { top, left, width, height } =
        this.$refs.searchinputcontainer.getBoundingClientRect()
      this.calculatedPosition = {
        top: `${top + height}px`,
        left: `${left}px`,
        width: `${width}px`
      }
    },
    handleResultSelect(res, e) {
      if (e && e.defaultPrevented) return
      this.query = ''
      this.searchResults = []
      this.$emit('submit', res)
    },
    search: debounce(() => this.performSearch(), 0),
    async performSearch() {
      this.searchResultsLoading = true
      this.searchResults = await this.trycatch(
        this.searchFunction(this.query),
        'loading search results',
        {
          onCatch: () => {
            this.query = ''
          },
          onFinally: () => {
            this.searchResultsLoading = false
          }
        }
      )
    },
    handleKeydown(e) {
      if (!['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) {
        return
      }
      e.preventDefault()
      if (e.key === 'ArrowDown') {
        if (this.searchSelectionFocused < this.searchResults.length - 1) {
          this.searchSelectionFocused++
        }
      } else if (e.key === 'ArrowUp') {
        if (this.searchSelectionFocused > 0) {
          this.searchSelectionFocused--
        }
      } else if (e.key === 'Enter') {
        if (this.searchSelectionFocused > -1) {
          this.handleResultSelect(
            this.searchResults[this.searchSelectionFocused]
          )
        }
      }
    },
    blur() {
      this.$refs.searchinput.blur()
      this.blurred = true
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  position: relative;

  &.expanded {
    // closes the gap between the border of the input and results
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.5rem;
      background: $white;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      box-shadow: 0px 8px 16px 0px rgba($black, 0.12);
    }

    :deep(.text-input-input) {
      border-radius: 0.5rem 0.5rem 0 0 !important;
      border-bottom: 1px solid $border-color !important;
    }
  }

  &-input {
    z-index: 10;
  }

  &-suggest {
    display: flex;
    flex-flow: column nowrap;
    background: $white;
    border: 1px solid $primary;
    border-top: none;
    border-radius: 0 0 16px 16px;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba($black, 0.12);
    position: fixed;
    z-index: 10;

    & > * {
      &:not(:last-child) {
        border-bottom: 1px solid $grey-400;
      }
    }

    &-header {
      padding: 0.85rem;
      font-weight: 700;
      border-bottom: 1px solid $grey-400;
    }

    &-items {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      max-height: 35vh;
      overflow-y: auto;

      & > * {
        &:not(:last-child) {
          border-bottom: 1px solid $grey-400;
        }
      }
    }

    &-item {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      padding: 0.85rem;
      cursor: pointer;

      &:hover {
        background: $border-color;

        & > .search-suggest-item-collect {
          opacity: 1;
          pointer-events: all;
        }
      }

      &-image {
        min-width: 4rem;
        max-width: 6rem;
        height: 4rem;
        object-fit: contain;
        border-radius: 4px;
      }

      &-info {
        display: flex;
        flex-flow: column nowrap;
      }

      &-name {
        font-weight: 600;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
        align-items: center;
      }

      &-summary {
        color: $grey;
      }

      &-collect {
        opacity: 0;
        transition: opacity 0.2s ease;
        pointer-events: none;
        margin-left: auto;
      }

      &.focused {
        background: $border-color;
      }
    }

    &-status {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 0.75rem;
      padding: 2.5rem;
      width: 100%;
      color: $grey;

      &-icon {
        height: 1.75rem;
      }
    }
  }
}
</style>
