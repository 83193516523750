<template>
  <div
    class="utabs-wrapper"
    :class="{ scrollable: !!maxHeight }"
    :style="{
      ...(maxHeight ? { maxHeight } : {}),
      ...(padding ? { '--utab-padding': padding } : {})
    }"
  >
    <div
      v-if="!hideTabs"
      class="utabs"
      :class="{ [type]: true, 'full-width': fullWidth }"
    >
      <div
        v-for="(tab, idx) in tabs"
        :key="`tab-${idx}`"
        class="utabs-tab"
        :class="{
          selected: selected(tab),
          disabled: disabled(tab),
          'full-width': fullWidth
        }"
        @click="$emit('select', tab)"
      >
        <slot name="trigger" :tab="tab" :idx="idx">
          <Icon
            v-if="tab.icon"
            :name="tab.icon"
            :fill="selected(tab) ? 'primary' : 'dark'"
            class="utabs-tab-icon"
          />
          {{ tab.name }}
        </slot>
      </div>
      <div class="utabs-btns">
        <slot name="buttons" />
      </div>
    </div>
    <div class="utabs-content">
      <div
        v-for="(tab, idx) in tabs"
        :key="`tabcontent-${idx}`"
        class="utabs-content-tab"
        :class="{ selected: selected(tab) }"
      >
        <slot name="tab" :tab="tab" :idx="idx" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Function,
      default: () => false
    },
    disabled: {
      type: Function,
      default: () => false
    },
    maxHeight: {
      type: String,
      default: ''
    },
    hideTabs: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'classic',
      validator: (value) => ['classic', 'modern'].includes(value)
    }
  },
  emits: ['select']
}
</script>

<style lang="scss" scoped>
.utabs {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;

  &-wrapper {
    display: grid;
    grid-template-rows: auto 1fr;

    &.scrollable {
      overflow-y: auto;
      position: relative;

      & > .utabs {
        position: sticky;
        top: 0;
        z-index: 1;
        background: $white;
      }
    }
  }

  &-btns {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
  }

  &-tab {
    font-weight: 600;
    font-size: 1.125rem;
    cursor: pointer;
    white-space: nowrap;

    &-icon {
      height: 1.2rem;
    }
  }

  &-content {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;

    &-tab {
      display: none;
      width: 100%;
      height: 100%;

      &.selected {
        display: block;
      }
    }
  }

  &.classic {
    gap: 1rem;
    padding: 0 var(--utab-padding, 0);
    border-bottom: 1px solid $border-color;

    & > .utabs-tab {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0 0.5rem 0.5rem;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid $border-color;
      }

      &.selected {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }

    &.full-width {
      & > .utabs-tab {
        padding: 0;
        justify-content: center;
      }
    }
  }

  &.modern {
    gap: 0.5rem;
    padding: 2px var(--utab-padding, 0);
    border-radius: 6px;

    & > .utabs-tab {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      transition: background 0.4s ease;

      &:hover {
        background: rgba($primary-light, 0.5);
      }

      &.selected {
        background: $primary-light;
        color: $primary;
      }
    }
  }

  &.full-width {
    & > .utabs-tab {
      flex: 1;
    }
  }
}
</style>
