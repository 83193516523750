<template>
  <div class="avatar-upload" @click.stop>
    <div
      class="avatar-upload-image"
      :class="{ disabled, hasbg: !imageUrl }"
      @click="uploadAvatar"
    >
      <input
        ref="avatarupload"
        type="file"
        class="avatar-upload-image-upload"
        accept="image/png, image/jpeg, image/gif, image/webp"
        @change="onAvatarUpload"
      />
      <Icon
        v-if="!imageUrl"
        name="globe"
        fill="white"
        class="avatar-upload-image-icon"
      />
      <img v-else :src="imageUrl" alt="" class="avatar-upload-image-logo" />
      <div v-if="imageUrl" class="avatar-upload-image-remove" @click.prevent>
        <Button
          icon="close"
          :disabled="disabled"
          type="white"
          size="xxs"
          @click="removeAvatar"
        />
      </div>
    </div>
    <div class="avatar-upload-image-action">
      <div class="avatar-upload-image-action-btns">
        <Button
          text="Upload image"
          type="white"
          :disabled="disabled"
          @click="() => uploadAvatar()"
        />
        <GenerateAvatar
          v-if="$route.params.ori_type"
          :disabled="disabled"
          @input="selectGenerated"
        />
      </div>
      <p class="avatar-upload-image-action-info">
        {{ width }} x {{ height || width }} JPG, GIF, PNG or WEBP. Max size of
        {{ maxSize }}MB
      </p>
    </div>
  </div>
</template>

<script>
import { imageToUrl } from '@/core/util'

export default {
  name: 'AvatarUpload',
  emits: ['input'],
  props: {
    image: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 512
    },
    height: {
      type: Number,
      default: 0
    },
    maxSize: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  inject: ['$toast'],
  data: () => ({
    img: undefined,
    imageUrl: ''
  }),
  watch: {
    image(val) {
      this.imageUrl = val
    }
  },
  mounted() {
    this.imageUrl = this.image
  },
  methods: {
    uploadAvatar(e) {
      if ((e && e.defaultPrevented) || this.disabled) return
      this.$refs.avatarupload.click()
    },
    selectGenerated(url, img) {
      if (!url || !img) return
      this.img = img
      this.imageUrl = url
      this.$emit('input', url, img)
    },
    onAvatarUpload(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files?.length) return
      if (!files[0]?.size) {
        this.$toast.danger(
          'Empty image file',
          'Please upload an image with content, or contact us if you think this is a mistake.'
        )
        return
      }
      if (files[0].size > this.maxSize * 1024 * 1024) {
        this.$toast.danger(
          'Image too large',
          `Please upload an image smaller than ${this.maxSize}MB`
        )
        return
      }
      this.img = files[0]
      imageToUrl(this.img, (url) => {
        this.imageUrl = url || ''
        this.$emit('input', this.imageUrl, this.img)
      })
    },
    removeAvatar() {
      this.imageUrl = ''
      this.img = null
      this.$refs.avatarupload.value = ''
      this.$emit('input', this.imageUrl, this.img)
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-upload {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: center;

  &-image {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 12px;
    border: 2px dotted transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.2s;
    position: relative;

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        border: 2px dotted $grey-400;
      }

      &.hasbg {
        &:hover {
          background: darken($primary-mid, 5%);
        }
      }
    }

    &.hasbg {
      background: $primary-mid;
    }

    &-icon {
      height: 4rem;
      opacity: 0.5;
    }

    &-logo {
      height: 5rem;
      width: 5rem;
      border-radius: 12px;
      object-fit: cover;
    }

    &-action {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;

      &-info {
        font-size: 0.8rem;
        color: $grey;
      }

      &-btns {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
      }
    }

    &-upload {
      opacity: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    &-remove {
      position: absolute !important;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}
</style>
