<template>
  <div
    v-if="!!title"
    class="toast"
    :style="`--bar-color: ${color}; --bar-timeout: ${timeout}ms;`"
  >
    <div class="toast-bar">
      <div class="toast-bar-inner"></div>
    </div>
    <div class="toast-content">
      <span class="toast-content-title">{{ title }}</span>
      <HTMLRenderer
        v-if="description"
        :value="description"
        class="toast-content-description"
      />
    </div>
  </div>
</template>

<script setup>
import { colorCodes } from '@/core/colors';
import { nextTick, ref } from 'vue'

const title = ref('')
const description = ref('')
const color = ref('')
const timeout = ref(5000)

const timer = ref(null)

async function show(t, d, c, time = 5000) {
  hide()
  await nextTick()
  title.value = t
  description.value = d
  color.value = colorCodes[c] || c
  timeout.value = time
  if (!time) return
  timer.value = setTimeout(hide, time)
}

function hide() {
  title.value = ''
  description.value = ''
  color.value = ''
  clearTimeout(timer.value)
  timer.value = null
}

function danger(t, d = '', time = 5000) {
  show(t, d, 'red', time)
  return { hide }
}

function success(t, d = '', time = 5000) {
  show(t, d, 'green', time)
  return { hide }
}

function info(t, d = '', time = 5000) {
  show(t, d, 'primary', time)
  return { hide }
}

defineExpose({
  danger,
  success,
  info
})
</script>

<style lang="scss" scoped>
.toast {
  width: 280px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 4px;
  overflow: hidden;
  z-index: 9999;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);

  &-bar {
    height: 7px;
    background: color-mix(in srgb, var(--bar-color), $black 75%);

    &-inner {
      height: 100%;
      background: var(--bar-color);
      animation: slide var(--bar-timeout) linear;
    }
  }

  &-content {
    padding: 1.5rem 1rem;
    background: $white;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-title {
      font-weight: 600;
      font-size: 1.2rem;
    }

    &-description {
      color: $grey;
    }
  }
}

@keyframes slide {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
