<template>
  <svg
    v-if="progress < 100 || !finishedIcon"
    :width="`${height}`"
    :height="`${height}`"
    :viewBox="`0 0 ${height} ${height}`"
    :style="`--uprogress-value: ${progressValue};`"
  >
    <circle
      :cx="`${height / 2}`"
      :cy="`${height / 2}`"
      :r="`${height / 2 - strokeWidth}`"
      fill="none"
      :stroke="backgroundColor"
      :stroke-width="`${2 * strokeWidth}`"
    />
    <circle
      class="percent"
      :cx="`${height / 2}`"
      :cy="`${height / 2}`"
      :r="`${height / 2 - strokeWidth}`"
      fill="none"
      :stroke="barColor"
      :stroke-width="`${2 * strokeWidth}`"
      pathLength="100"
      :stroke-linecap="rounded ? 'round' : 'butt'"
    />
  </svg>
  <Icon
    v-else
    name="check-circle-filled"
    fill="green"
    class="uprogress-finished"
    :style="{
      height: `${height}px`,
      width: `${width}px`
    }"
  />
</template>

<script setup>
import { mapColor } from '@/core/colors'
import { computed } from 'vue'

const props = defineProps({
  progress: {
    type: Number,
    required: true,
    validator: (value) => value >= 0 && value <= 100
  },
  size: {
    type: String,
    default: 's',
    validator: (value) => ['xs', 's', 'm', 'l', 'xl'].includes(value)
  },
  color: {
    type: String,
    default: 'primary'
  },
  background: {
    type: String,
    default: 'grey-300'
  },
  rounded: {
    type: Boolean,
    default: true
  },
  finishedIcon: {
    type: [String, Boolean],
    default: 'check-circle-filled'
  }
})

const progressValue = computed(() => {
  let p = 100 - props.progress
  return p
})

const height = computed(() => {
  return {
    xs: 14,
    s: 21,
    m: 35,
    l: 70,
    xl: 140
  }[props.size]
})

const strokeWidth = computed(() => {
  return {
    xs: 2,
    s: 2,
    m: 3,
    l: 5,
    xl: 7.5
  }[props.size]
})

const barColor = computed(() => mapColor(props.color))

const backgroundColor = computed(() => mapColor(props.background))
</script>

<style lang="scss" scoped>
svg {
  transform: rotate(-90deg);
}

.percent {
  stroke-dasharray: 100;
  stroke-dashoffset: var(--uprogress-value);
  transition: stroke-dashoffset 0.5s;
}
</style>
