import { computed, ref } from 'vue'
import Console from '@/core/console'
import { useInfiniteScroll } from './infiniteScroll'

export function usePaginator(getPaginator = async () => {}, props = undefined) {
  const defaultProps = {
    delaySetup: false,
    getScrollElement: undefined,
    beforePageLoad: async () => {},
    onDataReceive: async () => {},
    loadAll: false
  }
  const {
    delaySetup,
    getScrollElement,
    beforePageLoad,
    onDataReceive,
    loadAll
  } = {
    ...defaultProps,
    ...props
  }
  const consumptionLoading = ref(false)
  const settingUpPaginator = ref(false)
  const paginatorError = ref(undefined)
  const paginatorData = ref([])
  const paginator = ref(undefined)
  const pages = ref(undefined)
  const paginatorDataCount = ref(0)
  const isDone = ref(false)
  const pagesLoaded = ref(0)

  const getPages = async () => {
    if (paginator.value) {
      return paginator.value.pages()
    }
    Console.debug('Cannot get pages from paginator: ', paginator.value)
  }

  const getCount = async () => {
    return await paginator.value.count()
  }

  const setupPaginator = async (initial = false) => {
    if (initial) settingUpPaginator.value = true
    try {
      consumptionLoading.value = true
      paginator.value = await getPaginator()
      pages.value = await getPages()
      await loadPage(true)
      paginatorDataCount.value = await getCount()
      consumptionLoading.value = false
    } catch (e) {
      Console.warning('Failed to setup paginator consumer', e)
      consumptionLoading.value = false
      paginatorError.value = e
    } finally {
      settingUpPaginator.value = false
    }
  }

  const clearPaginator = () => {
    isDone.value = false
    paginatorData.value = []
    pagesLoaded.value = 0
    paginatorError.value = undefined
  }

  const resetPaginator = async () => {
    clearPaginator()
    await setupPaginator()
  }

  const loadPage = async (force = false) => {
    try {
      Console.debug('Loading pages from paginator')
      if (isDone.value) {
        Console.debug(
          'We already got all data from the paginator, skipping event.'
        )
        return
      }

      if (!pages.value) {
        Console.debug('Pages was undefined, retrying to get pages')
        pages.value = await getPages()
      }

      if (consumptionLoading.value && !force) {
        Console.debug('Already loading a new page')
        return
      }

      await beforePageLoad(pagesLoaded.value)
      consumptionLoading.value = true
      const data = await pages.value.next()
      if (!(data && data.value && data.value.data)) {
        Console.debug('Received no data')
        consumptionLoading.value = false
        isDone.value = true
        return
      }
      pagesLoaded.value += 1
      isDone.value = data.done || !data.value.next
      if (isDone.value) Console.debug("Received all data, we're done")
      else Console.debug('Received data from pages: ', data)
      onDataReceive(data)
      paginatorData.value = [...(paginatorData.value || []), ...data.value.data]
      if (loadAll && !isDone.value) {
        await loadPage(true)
      }
    } catch (e) {
      Console.warning('Failed to load page', e)
      paginatorError.value = e
    } finally {
      consumptionLoading.value = false
    }
  }

  if (!delaySetup) setupPaginator(true)

  const loadingOrDone = computed(() => consumptionLoading.value || isDone.value)

  if (!!getScrollElement && typeof getScrollElement === 'function') {
    useInfiniteScroll(
      getScrollElement,
      loadingOrDone,
      loadPage,
      consumptionLoading
    )
  }

  return {
    consumptionLoading,
    settingUpPaginator,
    paginatorData,
    paginatorDataCount,
    isDone,
    pagesLoaded,
    loadPage,
    resetPaginator,
    clearPaginator,
    setupPaginator,
    paginatorError
  }
}
