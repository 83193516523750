export function parseJWT(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function durationToString(ms) {
  if (ms > 3600000) {
    return Math.round(ms / 3600000) + ' hours'
  }
  if (ms > 60000) {
    return Math.round(ms / 60000) + ' minutes'
  }
  if (ms > 1000) {
    return Math.round(ms / 1000) + ' seconds'
  }
  return Math.round(ms) + ' milliseconds'
}

export function imageURL(image) {
  return new URL(`/src/assets/${image}`, import.meta.url).href
}

export function iconURL(icon) {
  return new URL(`/src/assets/icons/${icon}.svg`, import.meta.url).href
}

export function animationURL(animation) {
  return new URL(`/src/assets/animations/${animation}.json`, import.meta.url).href
}

export function imageToUrl(image, callback) {
  if (!image) return callback(undefined)
  const reader = new FileReader()
  reader.onload = (e) => callback(e?.target?.result)
  reader.readAsDataURL(image)
}

export async function asyncImageToUrl(image) {
  return new Promise((res, rej) =>
    imageToUrl(image, (url) => (url ? res : rej)(url))
  )
}

/**
 * Calculates the factorial of n using memoization.
 *
 * @param {number} n Integer for which to calculate the factorial
 * @returns {number} Factorial of `n`
 */
function factorial(n) {
  if (typeof factorial.mem === 'undefined') factorial.mem = { 0: 1 }

  let y = factorial.mem[n]

  if (y !== undefined) return y

  y = n * factorial(n - 1)
  factorial.mem[n] = y
  return y
}

/**
 * Smoothens a list of points by using Bézier curves.
 *
 * @param {{x: number, y: number}[]} P
 * The list of points to smoothen using the Bézier curve
 * @param {{x: number, y: number}[]} bends
 * A list of points, of which the axes represent percentages.
 * Each bend point generates a new point between 2 existing points in the data.
 * Their coordinates being the percentage value of the bend for the 2 given points.
 * @param {number} granularity
 * The granularity or step size of the resulting set of numbers.
 * This defines the resolution of the curve, lower values result in higher resolutions.
 * @returns {{x: number, y: number}[]} The Bézier curve
 */
export function bezierCurve(
  P,
  bends = [
    { x: 0.5, y: 0 },
    { x: 0.5, y: 1 }
  ],
  granularity = 0.1
) {
  if (typeof bezierCurve.c === 'undefined') bezierCurve.c = {}

  const xy = []
  const n = bends.length

  // Polynomial Coeffecient
  const C = (j, P) => {
    let coef = 1
    for (let m = 0; m < j; ++m) coef *= n - m + 1

    let s = { x: 0, y: 0 },
      c = 0,
      p
    for (let i = 0; i <= j; ++i) {
      c = (-1) ** (i + j) / (factorial(i) * factorial(j - i))

      p = P[i]
      s.x += c * p.x
      s.y += c * p.y
    }

    return { x: coef * s.x, y: coef * s.y }
  }

  // Bézier Polynomial
  const B = (t, P) => {
    let xy = { x: 0, y: 0 }
    for (let j = 0; j < P.length; ++j) {
      const c = C(j, P)
      xy.x += t ** j * c.x
      xy.y += t ** j * c.y
    }
    return xy
  }

  // Add bending points
  const Q = [...P]
  const step = bends.length + 1
  const m = (P.length - 1) * step
  for (let i = 1; i < m; i += step) {
    Q.splice(
      i,
      0,
      ...bends
        .map((b) => ({
          x: Q[i].x + b.x * (Q[i - 1].x - Q[i].x),
          y: Q[i].y + b.y * (Q[i - 1].y - Q[i].y)
        }))
        .reverse()
    )
  }

  // Apply Bézier Curve
  for (let i = 0; i <= Q.length - n - 2; i += n + 1)
    for (let t = 0; t < 1; t += granularity) {
      const b = B(t, Q.slice(i, i + n + 2))
      xy.push({ x: b.x, y: b.y })
    }

  return xy
}