import { computed } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'

export const useSettingsStore = defineStore('settings', () => {
  const userStore = useUserStore()

  const settingsTabs = computed(() => [
    ...(userStore.isUman ? ['general'] : []),
    ...(userStore.canWriteMembers ? ['members'] : []),
    ...(userStore.canWriteTeams ? ['teams'] : []),
    ...(userStore.canWriteIntegrations ? ['apps'] : []),
    ...(userStore.canWriteIntegrations && userStore.canUseProspecting
      ? ['prospecting']
      : []),
    ...(userStore.canWriteOriTemplates ? ['portfolio_templates'] : []),
    ...(userStore.canWriteTools ? ['meeting_templates', 'deal_stages'] : [])
  ])

  return {
    settingsTabs
  }
})
