import { additionalColors } from './colors'

export function first_name(user) {
  return (
    user.member?.user?.first_name ||
    user.user?.first_name ||
    user.first_name ||
    (user.integrationusers || []).find((i) => !!i.first_name)?.first_name ||
    ''
  )
}

export function last_name(user) {
  return (
    user.member?.user?.last_name ||
    user.user?.last_name ||
    user.last_name ||
    (user.integrationusers || []).find((i) => !!i.last_name)?.last_name ||
    ''
  )
}

export function email(user) {
  return (
    user.member?.user?.username ||
    user.user?.username ||
    user.username ||
    user.email ||
    (user.integrationusers || []).find((i) => !!i.email)?.email ||
    ''
  )
}

export function avatar(user) {
  return (
    user.member?.user?.avatar || user.user?.avatar || user.avatar || user.image
  )
}

export function full_name(user) {
  return first_name(user)
    ? `${first_name(user)} ${last_name(user)}`
    : email(user)
}

export function role(user) {
  return user.member?.role || user.role || ''
}

export function initials(user) {
  const name = typeof user === 'string' ? user : full_name(user)
  return (
    name
      .split(' ')
      .map((str) => str.charAt(0))
      .join('')
      .toUpperCase()
      .replace(/[^A-Z]/g, '')
      .slice(0, 2) || name[0]
  )
}

export function user_color(user) {
  const i =
    (hashCode(user) % (additionalColors.length >> 1)) +
    (additionalColors.length >> 1)
  return additionalColors[i]
}

/*
Adapted from StackOverflow:
https://stackoverflow.com/a/7616484
*/
function hashCode(x) {
  var hash = 0,
    i,
    h
  const addHash = (hash, h) => ((hash << 5) - hash + h) | 0

  // hash object / array
  if ((typeof x === 'object' || x instanceof Object) && x !== null) {
    for (let k in x) {
      h = hashCode(x[k])
      hash = addHash(hash, h)
    }
  }
  // hash string
  else if (typeof x === 'string' || x instanceof String) {
    if (x.length === 0) return hash
    for (i = 0; i < x.length; i++) {
      h = x.charCodeAt(i)
      hash = addHash(hash, h)
    }
  } else return x

  return hash
}
