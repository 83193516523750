import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { getWorkspaces } from '@/services/workspace'
import { getCurrentUser } from '@/services/user'
import { addRouterGuards } from '@/router/guards'
import { useWorkspaceStore } from '@/stores/workspace'
import { getUserIntegrations } from '@/services/integrations'
import { useLibraryComponents } from '@/core/library'

import App from '@/App.vue'
import router from '@/router'
import Vue3Lottie from 'vue3-lottie'
import '@/core/injection.js'

const app = createApp(App)
app.use(createPinia())
app.use(Vue3Lottie)

const auth = useAuthStore()
const userStore = useUserStore()
const workspaceStore = useWorkspaceStore()

function logout() {
  auth.logout()
  router.push({ name: 'login' })
}

const refreshToken = localStorage.getItem('refreshToken')

async function initApp() {
  if (refreshToken) {
    auth.setRefreshToken(refreshToken)
    try {
      await auth.startRefreshTimer()
      const user = await getCurrentUser()
      userStore.setCurrentUser(user)
      const workspaces = await getWorkspaces()
      workspaceStore.setWorkspaces(workspaces)
      await getUserIntegrations()
    } catch (err) {
      logout()
    }
  } else logout()
}

initApp().then(() => {
  addRouterGuards(router)
  app.use(router)

  useLibraryComponents(app)

  app.mount('#app')
})
