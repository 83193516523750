<template>
  <svg
    :width="`${height}`"
    :height="`${height}`"
    :viewBox="`0 0 ${height} ${height}`"
    class="uloading"
  >
    <circle
      class="uloading-spinner"
      :cx="`${height / 2}`"
      :cy="`${height / 2}`"
      :r="`${height / 2 - strokeWidth}`"
      fill="none"
      :stroke="barColor"
      :stroke-width="`${2 * strokeWidth}`"
      pathLength="100"
      stroke-linecap="round"
    />
  </svg>
</template>

<script setup>
import { computed } from 'vue'
import { mapColor } from '@/core/colors'

const props = defineProps({
  size: {
    type: String,
    default: 's',
    validator: (value) => ['xs', 's', 'm', 'l', 'xl'].includes(value)
  },
  color: {
    type: String,
    default: 'grey'
  }
})

const height = computed(() => {
  return {
    xs: 14,
    s: 21,
    m: 35,
    l: 70,
    xl: 140
  }[props.size]
})

const strokeWidth = computed(() => {
  return {
    xs: 1,
    s: 1,
    m: 2,
    l: 4,
    xl: 6
  }[props.size]
})

const barColor = computed(() => mapColor(props.color))
</script>

<style lang="scss" scoped>
.uloading {
  animation: rotate 1s ease infinite;
  display: block;

  &-spinner {
    stroke-dasharray: 100;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 15;
  }

  50% {
    stroke-dashoffset: 80;
  }

  100% {
    stroke-dashoffset: 15;
  }
}
</style>
