import { useLabelStore } from '@/stores/labels'
import { useWorkspaceStore } from '@/stores/workspace'
import { getClient, getResponseData } from '@/core/axios'

const client = getClient('/workspaces')

export async function getAttributes({
  workspace_id,
  linked_to,
  force = false
}) {
  const labels = useLabelStore()
  if (!force && labels.attributes?.length && !linked_to)
    return labels.attributes
  const res = await client.get(`/${workspace_id}/attributes/`, {
    params: {
      linked_to
    }
  })
  const resData = getResponseData(res) || {}
  if (!linked_to) {
    labels.setAttributes(resData)
    labels.setAttributesLoaded(true)
  }
  return resData
}

export async function createAttribute({ workspace_id, name }) {
  const res = await client.post(`/${workspace_id}/attributes/`, {
    name
  })
  const attr = getResponseData(res)
  const labels = useLabelStore()
  labels.addAttribute(attr)
  return attr
}

export async function editAttribute({
  workspace_id,
  attribute_id,
  name,
  position
}) {
  const res = await client.patch(
    `/${workspace_id}/attributes/${attribute_id}/`,
    {
      ...(name ? { name } : {}),
      ...(position ? { position } : {})
    }
  )
  const attribute = getResponseData(res)
  if (position) getAttributes({ workspace_id, force: true })
  const labels = useLabelStore()
  labels.updateAttribute(attribute)
  return attribute
}

export async function deleteAttribute({ workspace_id, attribute_id }) {
  const res = await client.delete(
    `/${workspace_id}/attributes/${attribute_id}/`
  )
  const labels = useLabelStore()
  labels.removeAttribute(attribute_id)
  return getResponseData(res)
}

export async function createAttributeValue({
  workspace_id,
  attribute_id,
  value
}) {
  const res = await client.post(
    `/${workspace_id}/attributes/${attribute_id}/values/`,
    {
      value
    }
  )
  const resData = getResponseData(res)
  const labels = useLabelStore()
  labels.addAttributeValue({ attribute_id, value: resData })
  return res
}

export async function editAttributeValue({
  workspace_id,
  attribute_id,
  value_id,
  value,
  position
}) {
  const res = await client.patch(
    `/${workspace_id}/attributes/${attribute_id}/values/${value_id}/`,
    {
      ...(value ? { value } : {}),
      ...(position ? { position } : {})
    }
  )
  const newVal = getResponseData(res)
  if (position) getAttributes({ workspace_id, force: true })
  else {
    const labels = useLabelStore()
    labels.updateAttributeValue({ attribute_id, value: newVal })
  }
  return newVal
}

export async function deleteAttributeValue({
  workspace_id,
  attribute_id,
  value_id
}) {
  const res = await client.delete(
    `/${workspace_id}/attributes/${attribute_id}/values/${value_id}/`
  )
  const labels = useLabelStore()
  labels.removeAttributeValue({ attribute_id, value_id })
  return getResponseData(res)
}

// QUALITY LABELS

export async function getQualityLabels({ workspace_id, act_as }) {
  const labelStore = useLabelStore()
  const workspaceStore = useWorkspaceStore()
  if (
    workspaceStore.currentWorkspace?.uuid === workspace_id &&
    (labelStore.qualityLabels || []).length
  )
    return labelStore.qualityLabels
  const res = await client.get(`/${workspace_id}/labels/`, {
    params: {
      ...(act_as ? { act_as } : {})
    }
  })
  const toReturn = getResponseData(res)
  labelStore.setQualityLabels(toReturn)
  return getResponseData(toReturn)
}

export async function updateResourceQualityLabel({
  workspace_id,
  resource_id,
  label_id = null,
  comment = '',
  act_as
}) {
  const res = await client.put(
    `/${workspace_id}/resources/${resource_id}/label/`,
    {
      uuid: label_id,
      comment
    },
    {
      ...(act_as ? { params: { act_as } } : {})
    }
  )

  return getResponseData(res)
}

// CURATION

export async function getCurationTasks({
  workspace_id,
  query = '',
  filters = {},
  included_facets = '',
  act_as
}) {
  const result = await client.post(
    `/${workspace_id}/curation/tasks/search/`,
    {
      ...filters,
      ...(query ? { query } : {})
    },
    {
      params: {
        page_size: 999,
        included_facets,
        ...(act_as ? { act_as } : {})
      }
    }
  )

  return getResponseData(result)
}
