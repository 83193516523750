import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useIntegrationStore = defineStore('integrations', () => {
  const workspaceIntegrations = ref([])
  const workspaceIntegrationsWorkspace = ref('')
  const workspaceMemberIntegrations = ref([])
  const workspaceMemberIntegrationsWorkspace = ref('')
  const userIntegrations = ref([])

  function setWorkspaceIntegrations(integrations) {
    workspaceIntegrations.value = integrations
  }

  function setWorkspaceIntegrationsWorkspace(workspace) {
    workspaceIntegrationsWorkspace.value = workspace
  }

  function setUserIntegrations(integrations) {
    userIntegrations.value = integrations
  }

  function setWorkspaceMemberIntegrations(integrations) {
    workspaceMemberIntegrations.value = integrations
  }

  function setWorkspaceMemberIntegrationsWorkspace(workspace) {
    workspaceMemberIntegrationsWorkspace.value = workspace
  }

  return {
    workspaceIntegrations,
    workspaceIntegrationsWorkspace,
    workspaceMemberIntegrations,
    workspaceMemberIntegrationsWorkspace,
    userIntegrations,
    setWorkspaceIntegrations,
    setWorkspaceIntegrationsWorkspace,
    setUserIntegrations,
    setWorkspaceMemberIntegrations,
    setWorkspaceMemberIntegrationsWorkspace
  }
})
