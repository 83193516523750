<template>
  <div class="uchart-wrapper">
    <canvas ref="chartcanvas" class="uchart"></canvas>
  </div>
</template>

<script setup>
import { additionalColors } from '@/core/colors';
import Chart from 'chart.js/auto'
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'doughnut',
    validator: (s) => ['doughnut', 'pie', 'bar', 'line'].includes(s)
  },
  labels: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  settings: {
    type: Object,
    default: () => ({})
  },
  legendPosition: {
    type: String,
    default: 'bottom',
    validator: (s) => ['top', 'bottom', 'left', 'right', 'chartarea'].includes(s)
  }
})

const propLabels = computed(() => props.labels)
const propData = computed(() => props.data)

const data = computed(() => ({
  labels: propLabels.value,
  datasets: [
    {
      data: propData.value,
      backgroundColor: additionalColors.slice(0, propData.value.length),
      hoverOffset: 4,
      ...props.settings
    }
  ]
}))

const options = computed(() => ({
  plugins: {
    legend: {
      position: props.legendPosition
    }
  }
}))

const config = computed(() => ({
  type: props.type,
  data: data.value,
  options: options.value
}))

let chart = null
const chartcanvas = ref(null)

function initChart() {
  chart = new Chart(chartcanvas.value, config.value)
}

const canMount = computed(
  () => propLabels.value.length && propData.value.length
)

onMounted(() => {
  if (canMount.value) initChart()
})

onUnmounted(() => {
  if (chart) chart.destroy()
})

watch(canMount, (can) => {
  if (can) initChart()
})

watch(
  () => propLabels,
  () => {
    if (chart && canMount.value) {
      chart.data.labels = props.labels
      chart.update()
    }
  },
  { deep: true }
)

watch(
  () => propData,
  () => {
    if (chart && canMount.value) {
      chart.data.datasets[0].data = props.data
      chart.update()
    }
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.uchart-wrapper {
  position: relative;
}
</style>
