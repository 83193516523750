<template>
  <div class="generate-avatar" ref="generateavatar">
    <Button
      text="Generate image"
      type="white"
      :loading="loading"
      :disabled="disabled"
      @click="generateAvatar"
    />
    <div
      v-if="options.length"
      class="generate-avatar-options"
      :style="positionStyle"
    >
      <p class="generate-avatar-options-header">
        Generated images
        <Button size="xs" icon="close" type="grey" @click="clear" />
      </p>
      <div class="generate-avatar-options-list">
        <img
          v-for="option in options"
          :key="option.url"
          :src="option.url"
          alt=""
          class="generate-avatar-options-list-item"
          @click="() => selectAvatar(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { generateOfferingImage } from '@/services/offerings'
import { generateReferenceImage } from '@/services/references'
import { generateInspirationImage } from '@/services/inspirations'

export default {
  name: 'GenerateAvatar',
  inject: ['$toast', '$console', 'trycatch'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    options: [],
    positionStyle: {}
  }),
  emits: ['input'],
  methods: {
    setPositionStyle() {
      let el = this.$refs.generateavatar
      if (!el) return
      el = el.getBoundingClientRect()
      this.positionStyle = {
        top: `${el.bottom + 7}px`,
        right: `${window.innerWidth - el.right}px`
      }
    },
    async generateAvatar() {
      this.setPositionStyle()
      const generateFunction = {
        offerings: generateOfferingImage,
        references: generateReferenceImage,
        inspirations: generateInspirationImage
      }[this.$route.params.ori_type]

      this.loading = true
      await this.trycatch(
        (async () => {
          const { urls } = await generateFunction({
            workspace_id: this.$route.params.workspace_id,
            ori_id: this.$route.params.ori_id,
            amount: 4
          })
          return await Promise.all(
            urls.map((url) =>
              fetch(url)
                .then((i) => i.blob())
                .then(
                  (blobFile) =>
                    new File(
                      [blobFile],
                      `avatar.${blobFile.type.split('/').slice(-1)[0]}`,
                      { type: blobFile.type }
                    )
                )
            )
          )
        })(),
        'generating avatar',
        {
          onResult: (images) => {
            this.options = images.map((image, index) => ({
              url: URL.createObjectURL(image),
              image
            }))
          },
          onFinally: () => {
            this.loading = false
          }
        }
      )
    },
    selectAvatar(avatar) {
      this.$emit('input', avatar.url, avatar.image)
      this.clear()
    },
    clear() {
      this.options = []
    }
  }
}
</script>

<style lang="scss" scoped>
.generate-avatar {
  position: relative;

  &-options {
    position: fixed;
    width: min(25rem, 90vw);
    background: $white;
    border-radius: 4px;
    border: 1px solid $border-color;
    z-index: 100;

    &-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      font-weight: 600;
      font-size: 1.15rem;
    }

    &-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      padding: 1rem;

      &-item {
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 4px;
        border: 1px solid $border-color;
        cursor: pointer;

        &:hover {
          border: 1px solid rgba($black, 0.16);
        }
      }
    }
  }
}
</style>
