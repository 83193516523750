const debug = !import.meta.env.PROD

const Console = {
  debug(...msg) {
    if (debug) {
      console.debug('[uman.ai webapp][DEBUG]', ...msg) // eslint-disable-line no-console
    }
  },
  log(...msg) {
    if (debug) {
      console.log('[uman.ai webapp]', ...msg) // eslint-disable-line no-console
    }
  },
  info(...msg) {
    console.info('[uman.ai webapp]', ...msg) // eslint-disable-line no-console
  },
  warning(...msg) {
    console.warn('[uman.ai webapp]', ...msg) // eslint-disable-line no-console
  },
  error(...msg) {
    console.error('[uman.ai webapp]', ...msg) // eslint-disable-line no-console
  }
}

export default Console
