<template>
  <div
    class="seg-button-wrapper"
    :class="{
      disabled,
      loading,
      'full-width': fullWidth
    }"
  >
    <button
      class="seg-button left"
      :class="{ [typeLeft]: true, [size]: true, 'icon-only': !textLeft }"
      @click="$emit('click-left')"
      @mouseenter="leftHovered = true"
      @mouseleave="leftHovered = false"
    >
      <span v-if="textLeft" class="seg-button-text">{{ textLeft }}</span>
      <Icon
        v-if="iconLeft"
        :name="iconLeft"
        :fill="iconFillLeft"
        class="seg-button-icon"
      />
    </button>
    <div class="seg-button-divider"></div>
    <button
      class="seg-button right"
      :class="{
        [typeRight || typeLeft]: true,
        [size]: true,
        'icon-only': !textRight
      }"
      @click="$emit('click-right')"
      @mouseenter="rightHovered = true"
      @mouseleave="rightHovered = false"
    >
      <Icon
        v-if="iconRight"
        :name="iconRight"
        :fill="iconFillRight"
        class="seg-button-icon"
      />
      <span v-if="textRight" class="seg-button-text">{{ textRight }}</span>
    </button>
    <Loader v-if="loading" :overlay="true" size="xs" />
  </div>
</template>

<script>
export default {
  name: 'SegmentedButton',
  props: {
    textLeft: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    textRight: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    },
    typeLeft: {
      type: String,
      default: 'primary',
      validator: (s) =>
        ['primary', 'light', 'light-solid', 'white', 'grey', 'black'].includes(
          s
        )
    },
    typeRight: {
      type: String,
      default: 'primary',
      validator: (s) =>
        ['primary', 'light', 'light-solid', 'white', 'grey', 'black'].includes(
          s
        )
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xs', 's', 'm', 'l'].includes(s)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    filterIcon: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click-left', 'click-right'],
  data: () => ({
    leftHovered: false,
    rightHovered: false
  }),
  computed: {
    iconFillLeft() {
      if (!this.iconLeft) return ''
      return this.iconFill(this.typeLeft, this.leftHovered)
    },
    iconFillRight() {
      if (!this.iconRight) return ''
      return this.iconFill(this.typeRight, this.rightHovered)
    }
  },
  methods: {
    iconFill(type, hovered) {
      if (!this.filterIcon) return 'dark'
      return {
        primary: 'white',
        light: 'primary',
        'light-solid': 'primary',
        white: hovered ? 'dark' : 'grey',
        grey: hovered ? 'dark' : 'grey',
        black: 'white'
      }[type]
    }
  }
}
</script>

<style scoped lang="scss">
.seg-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  flex: 20;
  background: none;
  border: none;
  outline: none;

  &.left {
    border-top-left-radius: 999rem;
    border-bottom-left-radius: 999rem;
  }

  &.right {
    border-top-right-radius: 999rem;
    border-bottom-right-radius: 999rem;
  }

  &-icon {
    transition: all 0.2s ease;
  }

  &-text {
    transition: all 0.2s ease;
    font-weight: 600;
    user-select: none;
    white-space: nowrap;
  }

  &-divider {
    width: 1px;
    background: $white;
  }

  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    border-radius: 999rem;
    outline: inherit;
    transition: all 0.2s ease;
    position: relative;
    height: fit-content;
    width: fit-content;
    overflow: hidden;

    &.disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }

    &.loading {
      cursor: default;
      filter: grayscale(100%);
      pointer-events: none;
    }

    &.full-width {
      width: 100%;
    }
  }

  &.primary {
    background: $primary;
    color: $white;
    border: none;
    & .seg-button-text {
      color: $white;
    }

    &:hover {
      background: darken($primary, 15%);
    }
  }

  &.light {
    background: rgba($primary-light, 0%);
    border: none;

    & .seg-button-text {
      color: $primary;
    }

    & .seg-button-divider {
      background: $primary;
    }

    &:hover {
      background: $primary-light;
    }
  }
  &.light-solid {
    background: $primary-light;
    border: none;

    & .seg-button-text {
      color: $primary;
    }

    & .seg-button-divider {
      background: $primary;
    }

    &:hover {
      background: darken($primary-light, 5%);
    }
  }

  &.white {
    background: $white;
    border: 1px solid $grey-400;

    & .seg-button-text {
      color: $grey;
    }

    & .seg-button-divider {
      background: $grey;
    }

    &:hover {
      background: darken(white, 6%);
      & .seg-button-text {
        color: $dark;
      }
    }
  }

  &.grey {
    background: transparent;
    border: none;

    & .seg-button-text {
      color: $grey;
    }

    & .seg-button-divider {
      background: $grey;
    }

    &:hover {
      background: rgba($black, 6%);
      & .seg-button-text {
        color: $dark;
      }
    }
  }

  &.black {
    background: rgba($black, 64%);
    border: none;

    & .seg-button-text {
      color: $white;
    }

    &:hover {
      background: black;
    }
  }

  &.light-solid {
    background: $primary-light;
    border: none;

    & .seg-button-text {
      color: $primary;
    }

    & .seg-button-divider {
      background: $primary;
    }

    &:hover {
      background: darken($primary-light, 5%);
    }
  }

  &.xxs {
    padding: 0.15rem 0.5rem;

    & .seg-button-text {
      font-size: 0.85rem;
    }

    & .seg-button-icon {
      height: 0.9rem;
      width: 0.9rem;
      min-width: 0.9rem;
      min-height: 0.9rem;
      object-fit: contain;
    }
  }

  &.xs {
    height: 1.85rem;
    padding: 0.35rem 0.85rem;

    & .seg-button-text {
      font-size: 0.85rem;
    }

    & .seg-button-icon {
      height: 1.1rem;
      width: 1.1rem;
      min-width: 1.1rem;
      min-height: 1.1rem;
      object-fit: contain;
    }
  }

  &.s {
    height: 2.25rem;
    padding: 0.35rem 1.15rem;

    & .seg-button-text {
      font-size: 1rem;
    }

    & .seg-button-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.m {
    height: 2.85rem;
    padding: 0.8rem 1.45rem;

    & .seg-button-text {
      font-size: 1rem;
    }

    & .seg-button-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.l {
    height: 3.45rem;
    padding: 0.95rem 1.7rem;

    & .seg-button-text {
      font-size: 1.15rem;
    }

    & .seg-button-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.icon-only {
    flex: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
