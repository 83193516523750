import { getClient, getResponseData, Paginator } from '@/core/axios'

const client = getClient('/workspaces')

export async function searchReferences({
  workspace_id,
  search,
  attribute_value_ids,
  ordering,
  page_size,
  owner_id,
  callback = () => {}
}) {
  const res = await client.get(`/${workspace_id}/references/`, {
    params: {
      search,
      ordering: ordering || '-date_updated',
      attribute_value_ids: attribute_value_ids?.length
        ? attribute_value_ids.join(',')
        : null,
      page_size: page_size || 100,
      owner_id
    }
  })
  callback(res)
  return getResponseData(res)
}

export async function getSearchReferencesPaginator({
  workspace_id,
  search,
  attribute_value_ids,
  ordering,
  page_size,
  owner_id
}) {
  return new Paginator(
    client,
    await client.get(`/${workspace_id}/references/`, {
      params: {
        search,
        ordering: ordering || '-date_updated',
        attribute_value_ids: attribute_value_ids?.length
          ? attribute_value_ids.join(',')
          : null,
        page_size: page_size || 10,
        owner_id
      }
    })
  )
}

export async function searchReferencesExtended({
  workspace_id,
  query,
  statuses,
  owner_ids,
  has_offerings,
  confidentiality,
  page_size,
  export_filetype,
  ...attributes
}) {
  const res = await client.post(
    `/${workspace_id}/references/search/`,
    {
      ...(query ? { query } : {}),
      ...(statuses?.length ? { statuses } : {}),
      ...(owner_ids?.length ? { owner_ids } : {}),
      ...(has_offerings ? { has_offerings } : {}),
      ...(confidentiality ? { confidentiality } : {}),
      ...(Object.keys(attributes || {}).length
        ? {
            attribute_value_ids: Object.keys(attributes).reduce(
              (acc, curr) => [...acc, ...attributes[curr]],
              []
            )
          }
        : {})
    },
    {
      params: {
        page_size: page_size || 10,
        ...(export_filetype ? { export: export_filetype } : {})
      }
    }
  )
  return getResponseData(res)
}

export async function getSearchReferencesExtendedPaginator({
  workspace_id,
  query,
  sort_by,
  statuses,
  owner_ids,
  has_offerings,
  confidentiality,
  page_size,
  callback = () => {},
  ...attributes
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(sort_by ? { sort_by } : {}),
    ...(statuses?.length ? { statuses } : {}),
    ...(owner_ids?.length ? { owner_ids } : {}),
    ...(has_offerings ? { has_offerings } : {}),
    ...(confidentiality ? { confidentiality } : {}),
    ...(Object.keys(attributes || {}).length
      ? {
          attribute_value_ids: Object.keys(attributes).reduce(
            (acc, curr) => [...acc, ...attributes[curr]],
            []
          )
        }
      : {})
  }

  const res = await client.post(`/${workspace_id}/references/search/`, body, {
    params: {
      page_size: page_size || 10
    }
  })
  callback(res)
  return new Paginator(
    client,
    res,
    () => {},
    '',
    (url) => client.post(url, body)
  )
}

export async function createReference({
  workspace_id,
  name,
  contact,
  account_id,
  owner_ids,
  offering_ids,
  confidentiality,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/references/`,
    {
      name,
      contact,
      account_id,
      owner_ids,
      offering_ids,
      confidentiality
    },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function finishReferenceDraft({
  workspace_id,
  ori_id,
  draft,
  act_as
}) {
  const res = await client.patch(
    `/${workspace_id}/references/${ori_id}/`,
    { draft },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function editReference({
  workspace_id,
  ori_id,
  name,
  contact,
  confidentiality,
  owner_ids,
  account_id,
  offering_ids,
  webpage_urls
}) {
  const res = await client.patch(`/${workspace_id}/references/${ori_id}/`, {
    name,
    contact,
    confidentiality,
    owner_ids,
    account_id,
    offering_ids,
    webpage_urls
  })
  return getResponseData(res)
}

export async function getReferenceInfo({ workspace_id, ori_id, act_as }) {
  const res = await client.get(`/${workspace_id}/references/${ori_id}/`, {
    params: { act_as }
  })
  return getResponseData(res)
}

export async function getReferences({
  workspace_id,
  act_as,
  extended = false
}) {
  const res = await client.get(`/${workspace_id}/references/`, {
    params: {
      ...(act_as ? { act_as } : {}),
      ...(extended ? { extended } : {})
    }
  })
  return getResponseData(res)
}

export async function generateReferenceImage({ workspace_id, ori_id, amount }) {
  const res = await client.post(
    `/${workspace_id}/references/${ori_id}/image/generate/`,
    {},
    { params: { amount } }
  )
  return getResponseData(res)
}

export async function setReferenceImage({ workspace_id, ori_id, image }) {
  let formData = null
  if (image instanceof File) {
    formData = new FormData()
    formData.append('image', image)
  }
  const res = await client.put(
    `/${workspace_id}/references/${ori_id}/image/`,
    formData
  )
  return getResponseData(res)
}

export async function setReferenceStatus({ workspace_id, ori_id, status }) {
  const res = await client.patch(`/${workspace_id}/references/${ori_id}/`, {
    status
  })
  return getResponseData(res)
}

export async function deleteReference({ workspace_id, ori_id }) {
  const res = await client.delete(`/${workspace_id}/references/${ori_id}/`)
  return getResponseData(res)
}

export async function editReferenceResourceLink({
  workspace_id,
  ori_id,
  resource_id,
  attribute_value_ids,
  position,
  act_as
}) {
  const res = await client.put(
    `/${workspace_id}/references/${ori_id}/resources/${resource_id}/`,
    {
      ...(attribute_value_ids ? { attribute_value_ids } : {}),
      ...(position ? { position } : {})
    },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function regenerateReferenceResource({
  workspace_id,
  ori_id,
  template_id,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/references/${ori_id}/templates/${template_id}/regenerate/`,
    {},
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function unlinkResourceFromReference({
  workspace_id,
  ori_id,
  resource_id
}) {
  const res = await client.delete(
    `/${workspace_id}/references/${ori_id}/resources/${resource_id}/`
  )
  return getResponseData(res)
}

export async function getReferenceResourceUploadProps({
  workspace_id,
  ori_id,
  name,
  mimetype,
  content_length,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/references/${ori_id}/assets/upload/`,
    { name, mimetype, content_length },
    { params: { act_as } }
  )
  return getResponseData(res)
}

export async function processReferenceResourceUpload({
  workspace_id,
  ori_id,
  state,
  integration_file_id
}) {
  const res = await client.post(
    `/${workspace_id}/references/${ori_id}/assets/process/`,
    { state, integration_file_id }
  )
  return getResponseData(res)
}

export async function editReferenceAttributes({
  workspace_id,
  ori_id,
  attribute_value_ids
}) {
  const res = await client.patch(`/${workspace_id}/references/${ori_id}/`, {
    attribute_value_ids
  })
  return getResponseData(res)
}
