<template>
  <div
    v-if="visible || hideWithStyling"
    class="umodal-container"
    ref="umodalcontainer"
    :class="{ hidden: hideWithStyling && !visible }"
    @click="handleClickOutside"
  >
    <div @click.prevent>
      <slot name="modal">
        <div
          class="umodal"
          :style="`width: min(${width}, calc(100vw - 3rem));`"
        >
          <div
            v-if="showHeader"
            class="umodal-header"
            :class="{ 'has-subtitle': !!subtitle }"
          >
            <slot name="header" :close="close">
              <div class="umodal-header-text">
                <p class="umodal-header-title">{{ title }}</p>
                <p v-if="subtitle" class="umodal-header-subtitle">
                  {{ subtitle }}
                </p>
              </div>
              <div class="umodal-header-btns">
                <slot name="buttons">
                  <Button
                    v-if="canClose"
                    icon="close"
                    type="grey"
                    size="xs"
                    @click="close"
                  />
                </slot>
              </div>
            </slot>
          </div>
          <div class="umodal-content" :id="contentId || ''" :class="{ padded }">
            <slot></slot>
          </div>
          <div v-if="showFooter" class="umodal-footer">
            <div class="umodal-footer-left">
              <slot name="footerleft"> </slot>
            </div>
            <div class="umodal-footer-buttons">
              <slot name="footerbuttons">
                <Button
                  v-if="canClose && showCancel"
                  :text="cancelText"
                  type="white"
                  :disabled="loading"
                  @click="() => (onCancel || close)()"
                />
                <Button
                  v-if="showSubmit"
                  :text="submitText"
                  :disabled="disabled"
                  :loading="loading"
                  @click="submit"
                />
              </slot>
            </div>
          </div>
        </div>
      </slot>
      <div class="umodal-padding" @click="handleClickOutside"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '45rem'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  canClose: {
    type: Boolean,
    default: true
  },
  showCancel: {
    type: Boolean,
    default: true
  },
  showSubmit: {
    type: Boolean,
    default: true
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  submitText: {
    type: String,
    default: 'Submit'
  },
  cancelText: {
    type: String,
    default: 'Cancel'
  },
  destroyOnHide: {
    type: Boolean,
    default: true
  },
  onCancel: {
    type: Function,
    default: undefined
  },
  padded: {
    type: Boolean,
    default: true
  },
  contentId: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['close', 'submit'])

const hideWithStyling = ref(false)

function close() {
  if (props.canClose) {
    if (!props.destroyOnHide) hideWithStyling.value = true
    emit('close')
  }
}

function submit() {
  emit('submit')
}

function handleKeyPress(e) {
  if (e.key === 'Escape') {
    close()
  }
}

watchEffect(() => {
  if (props.visible) {
    document.addEventListener('keydown', handleKeyPress)
  } else {
    document.removeEventListener('keydown', handleKeyPress)
  }
})

const umodalcontainer = ref(null)

function getAnchorHref(el) {
  if (!umodalcontainer.value || !el) return undefined
  if (umodalcontainer.value && umodalcontainer.value.isSameNode(el))
    return undefined
  return el.nodeName === 'A'
    ? { href: el.href, target: el.target }
    : getAnchorHref(el.parentElement)
}

function handleClickOutside(e) {
  if (e.defaultPrevented) {
    const link = getAnchorHref(e.target)
    if (link) window.open(link.href, link.target)
    return
  }
  if (props.canClose) close()
}
</script>

<style lang="scss" scoped>
.umodal {
  &-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &.hidden {
      display: none;
    }
  }

  background: $white;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
  max-height: calc(100vh - 7rem);
  display: flex;
  flex-flow: column nowrap;

  &-padding {
    height: 4rem;
    width: 100%;
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid $border-color;

    &.has-subtitle {
      padding: 1rem 1.5rem;
    }

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
    }

    &-subtitle {
      color: $grey;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-content {
    flex: 1;
    overflow: auto;
    position: relative;

    &.padded {
      padding: 1.5rem;
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-top: 1px solid $border-color;
    gap: 0.5rem;

    &-left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }

    &-buttons {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
</style>
