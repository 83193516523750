import { ref, onMounted, isRef, watch, nextTick, onBeforeUnmount } from 'vue'

export function useInfiniteScroll(
  getElement = () => {},
  disabled = false,
  callback = () => {},
  loading = false
) {
  const element = ref(null)
  const scrollType = ref('')

  onMounted(() => {
    const el = getElement()
    if (el) {
      element.value = el
      element.value.addEventListener('scroll', () => handleScroll('scroll'), {
        passive: true
      })
      element.value.addEventListener('wheel', () => handleScroll('wheel'), {
        passive: true
      })
    }
  })

  const handleScroll = (type = '') => {
    if (!scrollType.value) scrollType.value = type
    if (scrollType.value !== type) return
    if (
      element.value &&
      !(isRef(disabled) ? disabled.value : disabled) &&
      element.value.scrollHeight -
        element.value.offsetHeight -
        element.value.scrollTop <
        window.innerHeight / 6.66
    )
      callback()
  }

  if (isRef(loading)) {
    const unwatch = watch(loading, (val) => {
      if (!val) {
        setTimeout(() => {
          handleScroll(scrollType.value)
        }, 100)
      }
    })

    onBeforeUnmount(() => unwatch())
  }

  return { handleScroll }
}
