import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useSlideCollect = defineStore('slideCollect', () => {
  const downloadSlideProgress = ref(0)
  const isDownloadingSlides = ref(false)
  const downloadSlideCount = ref(0)
  const slideCollectorOpen = ref(false)
  const selectedSlides = ref([])
  const currentCollection = ref(null)

  function setDownloadSlideProgress(progress) {
    downloadSlideProgress.value = progress
  }

  function setIsDownloadingSlides(downloading) {
    isDownloadingSlides.value = downloading
  }

  function setDownloadSlideCount(count) {
    downloadSlideCount.value = count
  }

  function increaseDownloadSlideProgress() {
    const inc = downloadSlideProgress.value + 20 / downloadSlideCount.value
    downloadSlideProgress.value = inc > 95 ? 95 : inc
  }

  function setSlideCollectorOpen(open) {
    slideCollectorOpen.value = open
  }

  function startDownloadSlides(slideCount) {
    downloadSlideCount.value = slideCount
    isDownloadingSlides.value = true
  }

  function stopDownloadSlides({ success }) {
    downloadSlideProgress.value = 100
    downloadSlideCount.value = 0
    setTimeout(() => {
      isDownloadingSlides.value = false
      downloadSlideProgress.value = 0
      if (success) {
        slideCollectorOpen.value = false
        resetSlides()
      }
    }, 1000)
  }

  function setSelectedSlides(slides) {
    selectedSlides.value = slides
  }

  function setCurrentCollection(collection) {
    currentCollection.value = collection
    setSelectedSlides([...(collection?.pages || [])])
  }

  function addPage(slide) {
    if (!currentCollection.value) return
    currentCollection.value.pages.push(slide.uuid)
  }

  function deletePage(idx) {
    if (!currentCollection.value) return
    currentCollection.value.pages.splice(idx, 1)
  }

  function movePage({ oldIndex, newIndex }) {
    if (!currentCollection.value) return
    const element = currentCollection.value.pages.splice(oldIndex, 1)[0]
    currentCollection.value.pages.splice(newIndex, 0, element)
  }

  function clearPages() {
    if (!currentCollection.value) return
    currentCollection.value.pages = []
  }

  function addSlide(slideData) {
    selectedSlides.value.push(slideData)
    addPage(slideData)
  }

  function deleteSlide(uuid) {
    const idx = selectedSlides.value.findIndex((el) => el.uuid === uuid)
    selectedSlides.value.splice(idx, 1)
    deletePage(idx)
  }

  function resetSlides() {
    selectedSlides.value = []
    clearPages()
  }

  function moveSlide({ uuid, newIndex }) {
    const oldIndex = selectedSlides.value.findIndex((el) => el.uuid === uuid)
    if (oldIndex !== -1 && newIndex !== oldIndex) {
      const element = selectedSlides.value.splice(oldIndex, 1)[0]
      selectedSlides.value.splice(newIndex, 0, element)
    }
    movePage({ oldIndex, newIndex })
  }

  return {
    downloadSlideProgress,
    isDownloadingSlides,
    downloadSlideCount,
    slideCollectorOpen,
    selectedSlides,
    currentCollection,
    setDownloadSlideProgress,
    setIsDownloadingSlides,
    setDownloadSlideCount,
    increaseDownloadSlideProgress,
    setSlideCollectorOpen,
    startDownloadSlides,
    stopDownloadSlides,
    setSelectedSlides,
    setCurrentCollection,
    addPage,
    deletePage,
    movePage,
    clearPages,
    addSlide,
    deleteSlide,
    resetSlides,
    moveSlide
  }
})
