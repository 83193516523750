<template>
  <Modal
    title="Create a new workspace"
    :visible="visible"
    submit-text="Create workspace"
    :disabled="!name"
    :loading="loading"
    @close="close"
    @submit="submit"
  >
    <div class="workspace-create">
      <AvatarUpload
        :image="imageUrl"
        :width="512"
        :max-size="5"
        @input="
          (url, img) => {
            imageUrl = url
            image = img
          }
        "
      />
      <TextInput
        v-model="name"
        placeholder="Workspace name"
        title="Workspace name"
      />
      <TextArea
        v-model="description"
        placeholder="Workspace description"
        title="Workspace description"
      />
    </div>
  </Modal>
</template>

<script setup>
import { useTryCatch } from '@/core/trycatch'
import { createWorkspace, editWorkspaceImage } from '@/services/workspace'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const imageUrl = ref('')
const image = ref(undefined)
const name = ref('')
const description = ref('')
const loading = ref(false)

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close', 'submit'])

function close() {
  emit('close')
}

const router = useRouter()
const { trycatch } = useTryCatch()

async function submit() {
  await trycatch(
    (async () => {
      const ws = await createWorkspace({
        name: name.value,
        description: description.value
      })
      if (imageUrl.value) {
        await editWorkspaceImage({
          workspace_id: ws.uuid,
          image: image.value
        })
      }
    })(),
    'creating workspace',
    {
      loading,
      success: 'Workspace created successfully',
      onResult: (res) => {
        router.push({
          name: 'home',
          params: { workspace_id: ws.uuid }
        })
        close()
      }
    }
  )
}
</script>

<style lang="scss" scoped>
.workspace-create {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}
</style>
