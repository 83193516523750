import { useWorkspaceStore } from '@/stores/workspace'
import { useUserStore } from '@/stores/user'
import { getCurrentWorkspaceMember } from '@/services/workspace'
import { getAttributes } from '@/services/labels'
import { getQualityLabels } from '@/services/labels'
import { useSlideCollect } from '@/stores/slideCollect'
import { getWorkspaceMemberIntegrations } from '@/services/integrations'
import { getTeams } from '@/services/teams'

// WORKSPACE

export async function setCurrentWorkspace(workspace) {
  const workspaceStore = useWorkspaceStore()
  const userStore = useUserStore()
  const slideCollect = useSlideCollect()

  workspaceStore.setCurrentWorkspace(workspace)
  if (!!workspace) {
    const member = await getCurrentWorkspaceMember({ workspace_id: workspace })
    userStore.setCurrentWorkspaceMember(member)
    getWorkspaceMemberIntegrations({
      workspace_id: workspace,
      member_id: member.uuid
    })
    if (userStore.canWriteOri || userStore.canReadOri)
      getAttributes({ workspace_id: workspace, force: true })
    if (userStore.canWriteLabels) getQualityLabels({ workspace_id: workspace })
    if (userStore.canWriteTeams) getTeams({ workspace_id: workspace })
    slideCollect.setCurrentCollection(null)
    localStorage.setItem('uman_last_workspace', workspace)
  } else {
    userStore.setCurrentWorkspaceMember(null)
  }
}
