<template>
  <div class="status-orb-wrapper">
    <Tooltip
      :active="!showText && !!text"
      :text="text"
      :position="alignTooltip"
      :append-to-body="true"
    >
      <Icon
        :name="`status_${type}`"
        class="status-orb"
        :class="{ [size]: true }"
        :set-fill="false"
      />
    </Tooltip>
    <p v-if="showText && text" class="status-orb-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'StatusOrb',
  props: {
    type: {
      type: String,
      default: 'green',
      validator: (value) => ['green', 'grey', 'orange', 'red'].includes(value)
    },
    text: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xxs', 'xs', 's', 'm', 'l'].includes(s)
    },
    showText: {
      type: Boolean,
      default: false
    },
    alignTooltip: {
      type: String,
      default: 'top'
    }
  }
}
</script>

<style lang="scss" scoped>
.status-orb {
  display: block;

  &.xxs {
    height: 0.85rem;
    min-height: 0.85rem;
    width: 0.85rem;
    min-width: 0.85rem;
  }

  &.xs {
    height: 1rem;
    min-height: 1rem;
    width: 1rem;
    min-width: 1rem;
  }

  &.s {
    height: 1.5rem;
    min-height: 1.5rem;
    width: 1.5rem;
    min-width: 1.5rem;
  }

  &.m {
    height: 1.75rem;
    min-height: 1.75rem;
    width: 1.75rem;
    min-width: 1.75rem;
  }

  &.l {
    height: 1.5rem;
    min-height: 1.5rem;
    width: 1.5rem;
    min-width: 1.5rem;
  }

  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
  }

  &-text {
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
  }
}
</style>
