import { ref, onMounted, onUnmounted } from 'vue'

export function useClickaway(
  getElement = () => {},
  disabled = false,
  callback = () => {}
) {
  const element = ref(null)

  onMounted(() => {
    const el = getElement()
    if (el) {
      element.value = el
      document.addEventListener('click', handleClick)
    }
  })

  onUnmounted(() => {
    document.removeEventListener('click', handleClick)
  })

  const handleClick = (e) => {
    if (!element.value || !element.value.contains(e.target)) {
      callback()
    }
  }

  return {}
}
