<template>
  <div class="updown">
    <slot
      :upload="uploadProvider ? handleUpload : undefined"
      :connect="uploadProvider ? connectIntegration : undefined"
      :download="handleDownload"
      :provider="uploadProvider"
    ></slot>
  </div>
</template>

<script>
import { downloadSignedUrl } from '@/services/download'
import {
  getUserIntegrations,
  getUserIntegrationFiles,
  uploadToIntegration
} from '@/services/integrations'
import {
  buildOauthUrl,
  getNonce,
  userFileStorageIntegrations
} from '@/core/integrations'
import { useIntegrationStore } from '@/stores/integrations'
import { useWorkspaceStore } from '@/stores/workspace'
import { useUserStore } from '@/stores/user'
import { mapState } from 'pinia'
import { email } from '@/core/user'

export default {
  name: 'UploadDownload',
  props: {
    propsCall: {
      type: Function,
      default: async () => {}
    },
    callback: {
      type: Function,
      default: () => {}
    },
    downloadCallback: {
      type: Function,
      default: null
    },
    uploadCallback: {
      type: Function,
      default: null
    },
    showResult: {
      type: Boolean,
      default: true
    }
  },
  inject: ['$console', '$toast', '$snackbar', 'trycatch'],
  emits: ['loading', 'done', 'connected'],
  computed: {
    ...mapState(useIntegrationStore, ['userIntegrations']),
    ...mapState(useUserStore, ['currentUser']),
    ...mapState(useWorkspaceStore, ['currentWorkspace']),
    currentUserDomain() {
      return email(this.currentUser).split('@')[1]
    },
    uploadProvider() {
      const providers = {
        google_drive: 'google_drive',
        sharepoint: 'onedrive'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (this.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    }
  },
  methods: {
    async checkAvailableIntegrations() {
      await getUserIntegrations()
    },
    async handleDownload() {
      let success = true
      let error = undefined
      this.$emit('loading')
      const toast = this.$toast.info('Downloading file...', '', 0)
      await this.trycatch(
        (async () => {
          const { signed_url, name } = await this.propsCall('download')
          downloadSignedUrl({
            url: signed_url,
            title: name,
            callback: this.downloadCallback
          })
        })(),
        'downloading this file',
        {
          onCatch: (e) => {
            success = false
            error = e
          },
          onFinally: () => {
            toast.hide()
            this.$emit('done')
            this.callback(success, error)
          }
        }
      )
    },
    handleUpload() {
      this.$emit('loading')
      const integration = this.userIntegrations.find(
        (ui) => ui.type === this.uploadProvider
      )
      if (integration) {
        this.exportToIntegration(integration.type)
        return
      }
      this.connectIntegration()
    },
    connectIntegration(connectOnly = false) {
      const integration = this.userIntegrations.find(
        (ui) => ui.type === this.uploadProvider
      )
      if (integration) {
        this.$emit('connected')
        if (!connectOnly) this.exportToIntegration(integration)
        return
      }
      const int = userFileStorageIntegrations[this.uploadProvider]
      const nonce = getNonce(25)
      localStorage.setItem('integrationNonce', nonce)
      const url = buildOauthUrl({
        base_url: int.oauthBaseURL,
        query: {
          ...int.oauthQueryParams,
          state: `${nonce}%user`,
          hd: this.currentUserDomain
        }
      })
      window.open(`${url}`, '_blank')
      let bc = new BroadcastChannel('uman_integration_auth')
      bc.onmessage = (status) =>
        this.validateIntegrationAuth(this.uploadProvider, status, connectOnly)
    },
    async exportToIntegration(integration) {
      const idx = this.userIntegrations.findIndex((i) => i.type === integration)
      if (idx === -1) {
        this.$toast.danger(
          'An error occurred',
          'Something went wrong while exporting your file, please try again or contact us.'
        )
        this.callback(false, 'No integration found')
        this.$emit('done')
        return
      }

      let toast = { hide: () => {} }
      if (this.showResult) toast = this.$toast.info('Uploading file...', '', 0)

      const integration_id = this.userIntegrations[idx].id
      let success = true
      let error = undefined
      let res = undefined

      try {
        const files = await getUserIntegrationFiles({ integration_id })
        const file = files.find((f) => {
          let path = atob(f.path || '')
          path = path ? JSON.parse(path) : {}
          const pathKey = {
            google_drive: 'file',
            onedrive: 'item'
          }[this.uploadProvider]
          return path[pathKey] === 'root'
        })
        const { signed_url, name } = await this.propsCall('upload')
        res = await uploadToIntegration({
          user_id: this.currentUser.uuid,
          integration_id,
          integrationfile_id: file.uuid,
          signed_url,
          name
        })
        if (this.showResult) {
          this.showUploadResult(res, integration)
        }
      } catch (e) {
        success = false
        error = e
      } finally {
        if (this.showResult) toast.hide()
        this.$emit('done')
        if (this.uploadCallback) this.uploadCallback(res, integration)
        this.callback(success, error)
      }
    },
    async validateIntegrationAuth(integration, status, connectOnly = false) {
      const integrationObject = userFileStorageIntegrations[integration]
      if (status && status.data === 'done') {
        this.$toast.success(
          `${integrationObject.title} authenticated`,
          `We successfully connected your account to uman. You can now upload new documents straight to ${integrationObject.title}.`
        )
        await this.checkAvailableIntegrations()
        this.$emit('connected')
        if (!connectOnly) this.exportToIntegration(integration)
      } else if (status) {
        this.$toast.danger(
          'An error occurred',
          status.data === 'error'
            ? 'We could not connect to the external app. Please try again.'
            : status.data
        )
        this.$emit('done')
        this.callback(false, status.data, true)
      }
    },
    showUploadResult(res, integration) {
      const { url, name } = res
      const integrationObject = userFileStorageIntegrations[integration]
      this.$snackbar.show({
        text: `Successfully uploaded <b>${name}</b> to ${integrationObject.title}`,
        actionText: `View on ${integrationObject.title}`,
        cancelText: 'Close',
        onAction: () => {
          window.open(url, '_blank')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
